import Api from "../utils/axios";

export const ApproveUnapproveApi = {
  updateCategoryApprovalStatus: (id, status) =>
    Api.patch(`product/category-approval/${id}`, status),

  updateSubCategoryApprovalStatus: (id, status) =>
    Api.patch(`product/subcategory-approval/${id}`, status),

  updateItemApprovalStatus: (id, status) =>
    Api.patch(`product/product-approval/${id}`, status),

  getAllAdvertise: (payload, search = "") =>
    Api.post(`advertise/get-all-advertisements?search=${search}`, payload),
  updateAdvertiseApproval: (id, status) =>
    Api.patch(`admin/update-advertisement-status/${id}`, status),

  getAllPromocodes: (payload, search) =>
    Api.post(`vendor/get-all-promocodes?search=${search}`, payload),

  updatePromocodes: (id, payload) =>
    Api.patch(`admin/update-promo-code-status/${id}`, payload),
};
