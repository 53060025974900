import { formattedAmount } from "../../utils/helpers/Functions.helper";

const TaxData = ({ key, item }) => {
    if (item?.sales_price) {
      return (
        <div key={`t-${key}`}>
          <table class="min-w-full bg-white border border-gray-200">
            <thead>
              <tr class="bg-gray-100 border-b">
                <th class="px-4 py-2 text-left text-gray-600 font-semibold">
                  Tax Name
                </th>
                <th class="px-4 py-2 text-left text-gray-600 font-semibold">
                  Tax
                </th>
                <th class="px-4 py-2 text-left text-gray-600 font-semibold text-right">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="border-b">
                <td class="px-4 py-2 text-left text-gray-700">
                  Product Base Price
                </td>
                <td></td>
                <td class="px-4 py-2 text-gray-700 text-right">
                  {formattedAmount(
                    parseFloat(
                      item?.sales_price?.$numberDecimal
                        ? item?.sales_price?.$numberDecimal
                        : item?.sales_price
                    ).toFixed(2)
                  )}
                </td>
              </tr>
              {item?.taxData?.standardTax?.map((t, i) => (
                <tr class="border-b" key={i}>
                  <td class="px-4 py-2 text-gray-700">{t.name}</td>
                  <td class="px-4 py-2 text-gray-700">
                    {t?.rate.$numberDecimal || t?.rate}%
                  </td>
                  <td class="px-4 py-2 text-gray-700 text-right">
                    {formattedAmount(
                      t?.taxAmount?.$numberDecimal || t?.taxAmount
                    )}
                  </td>
                </tr>
              ))}
              <tr class="border-b">
                <td class="px-4 py-2 text-left text-gray-600">Line Rebase</td>
                <td></td>
                <td class="px-4 py-2 text-gray-700 text-right">
                  {formattedAmount(
                    item?.taxData?.rebase?.$numberDecimal || item?.taxData?.rebase
                  )}
                </td>
              </tr>
              <tr class="border-b">
                <td class="px-4 py-2 text-left text-gray-600">Referral Fee</td>
                <td className="px-4 py-2 text-gray-700">
                  {item?.taxData?.referralFee?.percentage?.$numberDecimal ||
                    item?.taxData?.referralFee?.percentage}
                  %
                </td>
                <td class="px-4 py-2 text-gray-700 text-right">
                  {formattedAmount(
                    item?.taxData?.referralFee?.amount?.$numberDecimal ||
                      item?.taxData?.referralFee?.amount
                  )}
                </td>
              </tr>
              <tr class="border-b">
                <td class="px-4 py-2 text-left text-gray-600">Closing Fee</td>
                <td className="px-4 py-2 text-gray-700">
                  {item?.taxData?.closingFeeRate?.$numberDecimal ||
                    item?.taxData?.closingFeeRate}
                </td>
                <td class="px-4 py-2 text-gray-700 text-right">
                  {formattedAmount(
                    item?.taxData?.closingFee?.$numberDecimal ||
                      item?.taxData?.closingFee
                  )}
                </td>
              </tr>
              <tr class="border-b">
                <td class="px-4 py-2 text-left text-gray-600">Additional Fee</td>
                <td className="px-4 py-2 text-gray-700">
                  {item?.taxData?.additionalFee?.$numberDecimal ||
                    item?.taxData?.additionalFee}
                </td>
                <td class="px-4 py-2 text-gray-700 text-right">
                  {formattedAmount(
                    item?.taxData?.additionalFee?.$numberDecimal ||
                      item?.taxData?.additionalFee
                  )}
                </td>
              </tr>
              <tr class="border-b">
                <td class="px-4 py-2 text-left text-gray-600">Taxable Amount</td>
                <td></td>
                <td class="px-4 py-2 text-gray-700 text-right">
                  {formattedAmount(
                    item?.taxData?.taxableAmount?.$numberDecimal ||
                      item?.taxData?.taxableAmount
                  )}
                </td>
              </tr>
              <tr class="border-b">
                <td class="px-4 py-2 text-left text-gray-600">VAT Included</td>
                <td className="px-4 py-2 text-gray-700">
                  {item?.taxData?.vat[0]?.rate?.$numberDecimal ||
                    item?.taxData?.vat[0]?.rate}
                  %
                </td>
                <td class="px-4 py-2 text-gray-700 text-right">
                  {formattedAmount(
                    item?.taxData?.vat[0]?.taxAmount?.$numberDecimal ||
                      item?.taxData?.vat[0]?.taxAmount
                  )}
                </td>
              </tr>
              <tr class="border-b">
                <td class="px-4 py-2 text-left text-gray-600">Grand Total</td>
                <td className="px-4 py-2 text-gray-700"></td>
                <td class="px-4 py-2 text-gray-700 text-right">
                  {formattedAmount(
                    item?.taxData?.grandTotal?.$numberDecimal ||
                      item?.taxData?.grandTotal
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
  };

  export default TaxData