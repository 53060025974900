import moment from "moment";
import { Badge } from "primereact/badge";
// import TextWrap from "../custom/TextWrap.js";
import { IoMdCheckmark } from "react-icons/io";
import { HiMiniXMark } from "react-icons/hi2";
import DropdownMenubar from "../components/Menubar/DropdownMenubar.jsx";

const DD = (MenuItems) => {
  return (
    <div className="p-3">
      <DropdownMenubar
        menuItem={[
          {
            label: "Action",
            items: MenuItems,
          },
        ]}
      />
    </div>
  );
};
const getVendorInfo = (row, handleCellClick) => {
  return DD([
    {
      label: "APPROVE",
      icon: (
        <div className="  text-white  rounded-full bg-green-600">
          {" "}
          <IoMdCheckmark className="" />
        </div>
      ),

      command: () => {
        handleCellClick(row, "Approved");
      },
    },
    {
      label: "UNAPPROVE",
      icon: (
        <div className="  text-white  rounded-full bg-red-600">
          {" "}
          <HiMiniXMark className="" />
        </div>
      ),
      command: () => {
        handleCellClick(row, "Rejected");
      },
    },
  ]);
};
export const columns = [
  {
    field: "order_id",
    header: "#",
    accessor: (row, handleCellClick) => (
      <>
        <div onClick={() => handleCellClick(row, "order_id")} className="">
          <p>{row?.order_id}</p>
          <p className="w-[6rem]">
            {moment(row?.order_date).utc().format("DD MMM YYYY hh:mma")}
          </p>
        </div>
      </>
    ),
  },
  {
    field: "promo_code",
    header: "Promocode",
    accessor: (row) => (
      <>
        <div className="w-40">
          <p>{row?.promo_code ? row?.promo_code : row?.sale_name}</p>
        </div>
      </>
    ),
  },
  {
    field: "description",
    header: "Description",
    accessor: (row) => (
      <>
        <div className="w-80">{row?.description.substring(0, 200)}...</div>
      </>
    ),
  },

  {
    field: "promo_for",
    header: "Promo code for",
    accessor: (row) => (
      <div className="w-28">
        <p className="w-full text-nowrap text-center">
          {row?.promo_for ? row?.promo_for : row?.sale_for}
        </p>
      </div>
    ),
  },
  {
    field: "start_date",
    header: "Start Date",
    accessor: (row) => (
      <div className="w-fit">
        <p className="w-full text-nowrap text-center">
          {moment(row?.start_date).format("YYYY-MM-DD")}
        </p>
      </div>
    ),
  },

  {
    field: "end_date",
    header: "End date",
    accessor: (row) => (
      <div className="w-fit">
        <p className="w-full text-nowrap text-center">
          {moment(row?.end_date).format("YYYY-MM-DD")}
        </p>
      </div>
    ),
  },
  {
    field: "repeat",
    header: "Repeat",
    accessor: (row) => <p>{row?.repeat}</p>,
  },
  {
    field: "required_uses",
    header: "Usage",
    accessor: (row) => <p>{row?.required_uses}</p>,
  },
  // {
  //   field: "apply_offer_after_orders",
  //   header: "Post Order",
  //   accessor: (row) => <p>{row?.apply_offer_after_orders}</p>,
  // },
  {
    field: "min_order_amount",
    header: "Min order",
    accessor: (row) => <p>{row?.required_uses}</p>,
  },
  {
    field: "min_item_on_cart",
    header: "Min item ",
    accessor: (row) => <p>{row?.required_uses}</p>,
  },
  {
    field: "max_discount_amount",
    header: "Max Disc",
    accessor: (row) => <p>{row?.max_discount_amount?.$numberDecimal}</p>,
  },
  // {
  //   field: "created_by",
  //   header: "Created by",
  //   accessor: (row) => <p>{row?.created_by}</p>,
  // },
  {
    field: "status",
    header: "Status",
    accessor: (row) => (
      <>
        {row?.status && (
          <Badge
            className="w-[6rem]"
            severity={
              row?.status === "Rejected"
                ? "danger"
                : row?.status === "Pending"
                ? "warning"
                : "success"
            }
            value={row?.status}
          />
        )}
      </>
    ),
  },
  // {
  //   field: "action",
  //   header: "Action",
  //   accessor: (row, handleCellClick) => getVendorInfo(row, handleCellClick),
  // },
];

export const searchBy = {
  filterType: "Sort By",
  inputName: "search_by",
  options: [
    {
      name: "Descending ",
      code: "desc",
    },
    {
      name: "Ascending",
      code: "asc",
    },
  ],
};

// export const filters = [
//   {
//     filterType: "Promo Code for",
//     inputName: "promo_for",
//     options: [
//       {
//         name: "Store",
//         code: "Store",
//       },
//       {
//         name: "Product",
//         code: "Product",
//       },
//       {
//         name: "Category",
//         code: "Category",
//       },
//       {
//         name: "Sub Category",
//         code: "Sub Category",
//       },
//     ],
//   },
//   {
//     filterType: "Frequency",
//     inputName: "repeat",
//     options: [
//       {
//         name: "No",
//         code: "No",
//       },
//       {
//         name: "Daily",
//         code: "Daily",
//       },
//       {
//         name: "Weekly",
//         code: "Weekly",
//       },
//       {
//         name: "Monthly",
//         code: "Monthly",
//       },
//       {
//         name: "Annually",
//         code: "Annually",
//       },
//     ],
//   },
// ];

export const canlenderFilter = {
  filterType: "Start Date",
  inputName: "start_date",
  maxDate: new Date(),
};
