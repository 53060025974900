import React, { useCallback, useEffect, useRef, useState } from "react";
import { columns, searchBy } from "../../../service/AUPromocodeService";
import { toast } from "react-toastify";
import { ApproveUnapproveApi } from "../../../apis/a&uApi";
import BasicTable from "../../../components/Table/BasicTable";
import { saleApi } from "../../../apis/Offer.api";

const AUPromosaleComponent = ({
  disabledFilters,
  pagination,
  handlePagination,
}) => {
  const [search, setSearch] = useState("");
  const debounceTimeout = useRef(null);

  const [isOrderDetail, setIsOrderDetail] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [vendorData, setVendorData] = useState(null);
  const [promocodeData, setPromocodeData] = useState(null);

  const getAllPromocode = async () => {
    try {
      const res = await saleApi.getAllSales(pagination, search);
      console.log("response", res?.data?.data);
      setPromocodeData(res?.data?.data);
    } catch (error) {
      toast.error(error?.message);
    }
  };
  const handleCloseOrderModal = () => {
    setIsOrderDetail(false);
    setSelectedOrder(null);
  };
  const handleRowClick = (row) => {
    console.log("row", row);
    setSelectedOrder(row);
    setIsOrderDetail(true);
  };
  const handleCellClick = async (row, action) => {
    if (action === "Approved" || action === "Unapproved") {
      try {
        await ApproveUnapproveApi.updatePromocodes(row?._id, {
          status: action,
        });
        getAllPromocode();
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const onPaginatorClick = (e) => {
    if (e) {
      const data = {
        limit: e.rows,
        page: +e.page + 1,
      };
      handlePagination && handlePagination(data);
    }
  };

  const handleSearch = useCallback((s) => {
    setSearch(s);
  }, []);

  const debouncedSearch = useCallback(
    (s) => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        handleSearch(s);
      }, 1500);
    },
    [handleSearch]
  );

  const handleClearFilter = () => {
    const clearPagination = {
      page: 1,
      limit: 5,
      sortOrder: "desc",
      filter: {},
    };
    handlePagination && handlePagination({ clearPagination });
  };
  const handleFilter = (name, value) => {
    let data = {};
    if (name === "search_by") {
      data.sortOrder = value.code;
    } else {
      const filter = {};
      filter[name] = value.code ? value.code : value;
      data.filter = filter;
    }

    handlePagination && handlePagination(data);
  };

  useEffect(() => {
    getAllPromocode();
  }, [pagination, search]);

  // const DataFilter = filters.filter((f) =>
  //   disabledFilters ? !disabledFilters.includes(f.inputName) : true
  // );

  return (
    <div className="p-5">
      <BasicTable
        columns={columns}
        data={promocodeData?.sales}
        onPaginatorClick={onPaginatorClick}
        paginatorData={{
          filters: null,
          first: 0,
          multiSortMeta: [],
          page: promocodeData?.limit || 5,
          sortField: null,
          sortOrder: null,
          totalPages: promocodeData?.totalPages || 1,
          totalCount: promocodeData?.totalCount || 1,
        }}
        onCellClick={(e) => {
          console.log("Cell Data", e);
        }}
        SearchBy={searchBy}
        handleSearch={debouncedSearch}
        handleFilter={(name, value) => handleFilter(name, value)}
        handleClearFilter={handleClearFilter}
        // filters={filters}
        selectedData={selectedOrder}
        handleCellClick={handleCellClick}
      />
      {/* <ReferralModal
        handleRowClick={handleRowClick}
        isOrderDetail={isOrderDetail}
        handleCloseOrderModal={handleCloseOrderModal}
        referralData={selectedOrder}
      />
      <StoreModal
        itemData={vendorData}
        setToggleUsersDetails={(v) => {
          setVendorData(null);
        }}
        toggleUsersDetails={Boolean(vendorData)}
      /> */}
    </div>
  );
};

export default AUPromosaleComponent;
