import React from "react";
import { BsTelephone } from "react-icons/bs";
import { CiLocationOn } from "react-icons/ci";
import { useSelector } from "react-redux";
import numeral from "numeral";
import moment from "moment";
import { formattedAmount } from "../../../utils/helpers/Functions.helper";
// import "../../../../Assets/css/tailwind.css";

function OrderDetails({ deliveryAddress, orderDetails, orderData }) {
  const { theme } = useSelector((state) => state.theme);

  return (
    <div
      className={`py-6 px-4 mx-auto rounded-lg shadow-md transition-all duration-300 ease-in-out ${
        theme === "light"
          ? "bg-white text-gray-800"
          : "bg-darkThirsary text-darkSecondary"
      }`}
    >
      <div className="mb-6 border-b border-gray-300 pb-4">
        <div className="flex flex-col gap-2 justify-between">
          <div className=" p-3 border border-gray-100 bg-gray-100 rounded-lg shadow-sm">
            <p className="text-sm font-medium">
              Invoice #{orderData?.order_id} -{" "}
              {moment(deliveryAddress?.createdAt).format("DD MMM YYYY hh:mm A")}
            </p>
            <p>
              Payment status:{" "}
              <span
                className={`text-sm ${
                  orderData?.payment_status === "UNPAID"
                    ? "text-red-500"
                    : "text-green-500"
                }`}
              >
                {orderData?.payment_status}
              </span>
            </p>
            <p className="text-sm text-gray-500">
              Payment type {orderData?.pay_type}
            </p>
          </div>
          <div
            className={`${
              orderData?.delivery_status === "CANCELLED"
                ? "bg-red-500"
                : "bg-blue-900"
            } text-white px-4 py-2 rounded-md text-sm text-center shadow`}
          >
            <p>{orderData?.delivery_status}</p>
            <p>
              {moment(deliveryAddress?.updatedAt).format("DD MMM YYYY hh:mm A")}
            </p>
          </div>
        </div>
      </div>

      <div className="mb-6">
        <h3 className="font-semibold mb-2 text-lg">Order by</h3>
        <p className="text-xl font-semibold hover:text-blue-900 transition duration-300">
          {deliveryAddress?.name ?? "N/A"}
        </p>
        <div className="flex justify-between items-center mt-1">
          <p className="text-gray-500 text-xl hover:text-blue-900 transition duration-300">
            {deliveryAddress?.phone_number ?? "N/A"}
          </p>
          <BsTelephone className="text-2xl text-blue-900 hover:text-blue-700 transition duration-300" />
        </div>

        <div className="flex items-center p-2 gap-4 mt-4 bg-gray-100 rounded-lg shadow-sm">
          <CiLocationOn className="text-3xl text-blue-900" />
          <p className="text-gray-700">
            {deliveryAddress?.flat_no ?? "N/A"},{" "}
            {deliveryAddress?.landmark ?? "N/A"},
            {deliveryAddress?.street ?? "N/A"},{" "}
            {deliveryAddress?.pin_code ?? "N/A"}
          </p>
        </div>
      </div>

      <hr className="my-4 border-gray-300" />

      {orderDetails.map((details, index) => (
        <div
          key={index}
          className="mb-6 p-4 bg-gray-50 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200"
        >
          <div className="flex justify-between items-center">
            <h3 className="font-bold text-xl text-gray-800">
              {details?.product?.name ?? "Unnamed Product"}
            </h3>
          </div>

          <div className="flex justify-between items-center mb-4 mt-2">
            <p className="text-blue-900 font-semibold">
              {details?.quantity ?? 0} Item
              {(details?.quantity ?? 0) > 1 ? "s" : ""}
            </p>
          </div>
          <p className="text-green-600 text-lg font-semibold">
              {formattedAmount(Number(details?.price?.$numberDecimal ?? 0))}
          </p>
          <hr className="border-gray-300" />
        </div>
      ))}
    </div>
  );
}

export default OrderDetails;
