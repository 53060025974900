import React, { useContext, useEffect, useState } from "react";
import BreadCrumb from "../../components/BreadCrumb";
import { useSelector } from "react-redux";
import ToggleRightField from "../../components/ToggleRightField";
import Button, { SecondaryButton } from "../../components/Button";
import TextInput from "../../components/TextInput";
import SidebarField from "../../components/Sidebar/SidebarField";
import Select from "react-select";
import { ErrorMessage, Form, Formik } from "formik";
import ToggleField from "../../components/ToggleField";
import StoreSettingApi, {
  GeneralSettingApi,
} from "../../apis/StoreSettings.api";
import LoaderContext from "../../components/loader";
import { json } from "react-router-dom";

const Storesetting = () => {
  const { handleLoading } = useContext(LoaderContext);
  const [storeSettingData, setStoreSettingData] = useState(null);
  const [generalSettingData, setGeneralSettingData] = useState(null);

  const fetchData = async () => {
    handleLoading(true);

    try {
      // Fetch Store Settings
      const storeRes = await StoreSettingApi.getAllStoreDelivery();
      setStoreSettingData(storeRes.data?.data);
      console.log("storeSettingData Value by Api", storeRes.data?.data);

      // Fetch General Settings
      const generalRes = await GeneralSettingApi.getAllGeneralSetting();
      setGeneralSettingData(generalRes.data?.data);
      console.log("general settings Value by Api", generalRes.data?.data);
    } catch (err) {
      console.log("Error fetching data:", err);
    } finally {
      handleLoading(false); // Set loading state to false when both requests are done
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (values) => {
    try {
      const id = storeSettingData[0]?._id;
      if (!id) {
        const res = await StoreSettingApi.createStoreSettingMaster(values);
        console.log("Created store setting:", res.data?.data);
      } else {
        const res = await StoreSettingApi.updateStoreDelivery(id, values);
        console.log("Updated store setting:", res.data?.data);
      }

      
      fetchData(); 
    } catch (err) {
      console.log("Error submitting store setting data:", err);
    }
  };

  const handleGeneralSubmit = async (values) => {
    try {
      const generalId = generalSettingData[0]?._id; 
      if (!generalId) {
        const res = await GeneralSettingApi.createGeneralSettingMaster(values);
        console.log("Created General setting:", res.data?.data);
      } else {
        const res = await GeneralSettingApi.updateGeneralSetting(generalId,values);
        console.log("Updated store setting:", res.data?.data);
      }

    
      fetchData(); 
    } catch (err) {
      console.log("Error submitting store setting data:", err);
    }
  };

  return (
    <div className="p-4">
      <BreadCrumb title={"Store Setting"} />
      <div className="grid xl:grid-cols-3 lg:grid-col-2 md:grid-cols-2 gap-3">
        {storeSettingData !== null ? (
          <StoreSettings
            storeSettingData={storeSettingData}
            onSubmit={handleSubmit}
          />
        ) :""}
        <GeneralSettings
          generalSettingData={generalSettingData}
          handleGeneralSubmit={handleGeneralSubmit}
        />
      </div>
    </div>
  );
};

export default Storesetting;

const StoreSettings = ({ storeSettingData, onSubmit }) => {
  const { handleLoading } = useContext(LoaderContext);
  const { theme } = useSelector((state) => state.theme);
  const [editStore, setEditStore] = useState(false);
  const [editIdSetting, setEditIdSetting] = useState(null);

  useEffect(() => {
    if (storeSettingData?.length > 0) {
      setEditIdSetting(storeSettingData[0]);
      console.log("storeSetting Data:", editIdSetting);
    }
  }, [storeSettingData]);

  console.log('storeSettingData',storeSettingData);
  

  const handleSettingEdit = () => {
    console.log("Editing id", storeSettingData[0]?._id);
    setEditStore(true);
  };

  const handleFormSubmit = async (values) => {
    await onSubmit(values);
    setEditStore(false);
  };
  const handleClose = () => {
    setEditStore(false);
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          provide_intercity_delivery:editIdSetting?.provide_intercity_delivery ? true:false,
          order_preparation_time: editIdSetting?.order_preparation_time || 30,
          local_delivery_radius: editIdSetting?.local_delivery_radius || 30,
        }}
        onSubmit={handleFormSubmit}
      >
        {({ handleChange, setFieldValue, values, handleSubmit }) => (
          <Form>
            <div
              className={`${
                theme === "light"
                  ? "bg-secondaryColor text-textColorLight"
                  : "bg-darkThirsary text-darkSecondary"
              } w-full h-full p-5  shadow-2xl rounded-xl`}
            >
              <div className="flex justify-between mb-5">
                <span className="font-semibold">Store Delivery Settings</span>
                <SecondaryButton text="Edit" onClick={handleSettingEdit} />
              </div>

              <div className="px-2">              
                <ToggleRightField
                  name={"provide_intercity_delivery"}
                  value={Boolean(values.provide_intercity_delivery)}
                  text={"Do You provide intercity Delivery ?"}
                  // onChange={handleChange}
                  disabled={true}
                />

                <div className="text-sm py-3 border-b">
                  <div className="flex justify-between items-end">
                    <span>Order Preparation Time</span>
                    <span className="flex justify-end">
                      <TextInput
                        name={"order_preparation_time"}
                        type="number"
                        onChange={handleChange}
                        value={values.order_preparation_time}
                        className={"text-center p-2 w-2"}
                        disabled={true}
                      />
                      <input
                        type="text"
                        className="text-center p-2 w-2 border"
                        value={"Min"}
                        readOnly
                      />
                    </span>
                  </div>
                </div>

                <div className="text-sm py-3 border-b">
                  <div className="flex justify-between items-end">
                    <span>Local Delivery Radius</span>
                    <span className="flex justify-end">
                      <TextInput
                        name={"local_delivery_radius"}
                        type="number"
                        onChange={handleChange}
                        value={values.local_delivery_radius}
                        className={"text-center p-2 w-2"}
                        disabled={true}
                      />
                      <input
                        type="text"
                        className="text-center p-2 w-2 border"
                        value={"KM"}
                        readOnly
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {/* Order Setting Edit */}
      {editStore ? (
        <Formik
          initialValues={{
            provide_intercity_delivery:
              editIdSetting?.provide_intercity_delivery || false,
            order_preparation_time: editIdSetting?.order_preparation_time || 30,
            local_delivery_radius: editIdSetting?.local_delivery_radius || 30,
          }}
          onSubmit={handleFormSubmit}
        >
          {({ handleChange, setFieldValue, values, handleSubmit }) => (
            <Form>
              <SidebarField
                button1={
                  <Button text={"Cancel"} type="button" onClick={handleClose} />
                }
                button2={<Button text="Submit " type="submit" />}
                title={"Edit store settings"}
                onClick={handleSubmit}
              >
                <div className="px-2">
                  <ToggleRightField
                    name={"provide_intercity_delivery"}
                    value={values.provide_intercity_delivery}
                    text={"Do You provide intercity Delivery ?"}
                    onChange={handleChange}
                  />

                  <div className="text-sm py-3 border-b">
                    <div className="flex justify-between items-end">
                      <span>Order Preparation Time</span>
                      <span className="flex justify-end">
                        <TextInput
                          name={"order_preparation_time"}
                          type="number"
                          onChange={handleChange}
                          value={values.order_preparation_time}
                          className={"text-center p-2 w-2"}
                        />
                        <input
                          type="text"
                          className="text-center p-2 w-2 border"
                          value={"Min"}
                          readOnly
                        />
                      </span>
                    </div>
                  </div>

                  <div className="text-sm py-3 border-b">
                    <div className="flex justify-between items-end">
                      <span>Local Delivery Radius</span>
                      <span className="flex justify-end">
                        <TextInput
                          name={"local_delivery_radius"}
                          type="number"
                          onChange={handleChange}
                          value={values.local_delivery_radius}
                          className={"text-center p-2 w-2"}
                        />
                        <input
                          type="text"
                          className="text-center p-2 w-2 border"
                          value={"KM"}
                          readOnly
                        />
                      </span>
                    </div>
                  </div>
                </div>

                {/* <hr /> */}
              </SidebarField>
            </Form>
          )}
        </Formik>
      ) : null}
    </>
  );
};

const GeneralSettings = ({ generalSettingData, handleGeneralSubmit }) => {
  const { theme } = useSelector((state) => state.theme);
  const [editGeneral, setEditGeneral] = useState(false);
  const [editGeneralData, setEditGeneralData] = useState(null);

  useEffect(() => {
    if (generalSettingData?.length > 0) {
      setEditGeneralData(generalSettingData[0]); 
      console.log("data for edit",editGeneralData);
      
    }
  }, [generalSettingData]);

  
  const handleFormSubmit = async (values) => {
    await handleGeneralSubmit(values); 
    setEditGeneralData(values); 
    setEditGeneral(false); 
  };

  const handleClose = () => {
    setEditGeneral(false);
  };

 
  const handleAllDaysToggle = (setFieldValue, values) => {
    const newValue = !values.all_day_open;
    setFieldValue("all_day_open", newValue);
    setFieldValue("sunday_open", newValue);
    setFieldValue("monday_open", newValue);
    setFieldValue("tuesday_open", newValue);
    setFieldValue("wednesday_open", newValue);
    setFieldValue("thursday_open", newValue);
    setFieldValue("friday_open", newValue);
    setFieldValue("saturday_open", newValue);
  };

  const handleDayToggle = (day, setFieldValue, values) => {
    const dayKey = `${day.toLowerCase()}_open`;
    const newValue = !values[dayKey];
    setFieldValue(dayKey, newValue);
    setFieldValue('all_day_open', newValue?true:false);
  };

  return (
    <>
      {/* Display Section */}
      <Formik
        enableReinitialize={true}
        initialValues={{
          store_open_time: editGeneralData?.store_open_time || "10:00",
          store_close_time: editGeneralData?.store_close_time || "18:00",
          isBusiness: editGeneralData?.isBusiness ? true:false,
          isBusy: editGeneralData?.isBusy ? true:false,
          isVisible: editGeneralData?.isVisible ? true:false,
        }}
        onSubmit={handleFormSubmit}
      >
        {({ handleChange, setFieldValue, values }) => (
          <Form>
            <div
              className={`${
                theme === "light"
                  ? "bg-secondaryColor text-textColorLight"
                  : "bg-darkThirsary text-darkSecondary"
              } w-full h-full p-5 shadow-2xl rounded-xl`}
            >
              <div className="flex justify-between mb-5">
                <span className="font-semibold">General Settings</span>

                <SecondaryButton
                  text="Edit"
                  onClick={() => setEditGeneral(true)}
                />
              </div>
              <div className="flex justify-between ">
              <div className="flex justify-start items-center px-1 border-r-2">
                    <span className="">Store Open Time:</span>
                    <span>
                      <TextInput
                        name={"store_open_time"}
                        type="time"
                        onChange={handleChange}
                        value={values.store_open_time}
                        className={"text-sm"}
                        disabled={true}
                      />
                    </span>
                  </div>
                  
                  <div className="flex justify-start items-center px-1">
                    <span className="">Store Close Time:</span>
                    <span>
                      <TextInput
                        name={"store_close_time"}
                        type="time"
                        onChange={handleChange}
                        value={values.store_close_time}
                        className={"text-sm"}
                        disabled={true}
                      />
                    </span>
                  </div>
              </div>
              <hr className="my-2" />
              <ToggleRightField
                name={"isBusiness"}
                text={"Business"}
                // subtext={"Business"}
                value={values.isBusiness}
                disabled={true}
              />
              <ToggleRightField
                name={"isBusy"}
                text={"Busy"}
                // subtext={"Busy"}
                value={values.isBusy}
                disabled={true}
              />
              <ToggleRightField
                name={"isVisible"}
                text={"Visible"}
                // subtext={"Visible"}
                value={values.isVisible}
                disabled={true}
              />
            </div>
          </Form>
        )}
      </Formik>

      {/* Edit Section */}
      {editGeneral ? (
        <Formik
          enableReinitialize
          initialValues={{
            store_open_time: editGeneralData?.store_open_time || "10:00",
            store_close_time: editGeneralData?.store_close_time || "18:00",
            isBusiness: editGeneralData?.isBusiness || false,
            isBusy: editGeneralData?.isBusy || false,
            isVisible: editGeneralData?.isVisible || false,
            all_day_open: editGeneralData?.all_day_open ? true:false,
            sunday_open: editGeneralData?.sunday_open ? true:false,
            monday_open: editGeneralData?.monday_open ? true:false,
            tuesday_open: editGeneralData?.tuesday_open ? true:false,
            wednesday_open: editGeneralData?.wednesday_open ? true:false,
            thursday_open: editGeneralData?.thursday_open ? true:false,
            friday_open: editGeneralData?.friday_open ? true:false,
            saturday_open: editGeneralData?.saturday_open ? true:false,
          }}
          onSubmit={handleFormSubmit}
        >
          {({ handleChange, setFieldValue, values,handleSubmit }) => (
            <Form>
              <SidebarField
                button1={<Button text={"Cancel"} type="button" onClick={handleClose} />}
                button2={<Button text="Submit " type="submit" />}
                title={"General Settings"}
                onClick={handleSubmit}
              >
                <div className="px-2">
                  <div className="flex justify-between items-center">
                    <span>Store Open Time</span>
                    <span className="flex justify-end">
                      <TextInput
                        name={"store_open_time"}
                        type="time"
                        onChange={handleChange}
                        value={values.store_open_time}
                        className={"text-xl"}
                      />
                    </span>
                  </div>
                  <hr className="my-4" />
                  <div className="flex justify-between items-center">
                    <span>Store Close Time</span>
                    <span className="flex justify-end">
                      <TextInput
                        name={"store_close_time"}
                        type="time"
                        onChange={handleChange}
                        value={values.store_close_time}
                        className={"text-xl"}
                      />
                    </span>
                  </div>
                  <hr className="mt-4" />
                  <ToggleRightField
                    name={"isBusiness"}
                    text={"Business"}
                    subtext={"Business"}
                    value={values.isBusiness}
                  />
                  <ToggleRightField
                    name={"isBusy"}
                    text={"Busy"}
                    subtext={"Busy"}
                    value={values.isBusy}
                  />
                  <ToggleRightField
                    name={"isVisible"}
                    text={"Visible"}
                    subtext={"Visible"}
                    value={values.isVisible}
                  />
                  <div className="mt-4 px-3">
                    <span className="text-lg">Store Days Settings</span>
                    <hr className="mt-2" />

                    {/* Toggle for "All Days" */}
                    <label className="flex justify-between items-center py-2">
                      <span>All Days</span>
                      <input
                        type="checkbox"
                        checked={values.all_day_open}
                        onChange={() =>
                          handleAllDaysToggle(setFieldValue, values)
                        }
                        className="border border-red-500 text-red-500"
                      />
                    </label>

                    {/* Individual day toggles */}
                    {["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].map((day) => (
                      <label
                        key={day}
                        className="flex justify-between items-center py-2"
                      >
                        <span>{day}</span>
                        <input
                          type="checkbox"
                          checked={values[`${day.toLowerCase()}_open`]}
                          onChange={() => handleDayToggle(day, setFieldValue, values)}
                          className="border border-red-500 text-red-500"
                        />
                      </label>
                    ))}
                  </div>
                </div>
              </SidebarField>
            </Form>
          )}
        </Formik>
      ) : null}
    </>
  );
};



