import "./style.css";
import moment from "moment";

// eslint-disable-next-line react/prop-types
const Messages = ({
  user,
  time_date,
  message,
  classs,
  file,
  isLoading,
  progress,
}) => {
  if (user && user !== "date") {
    return (
      <main className={`messageBox ${classs}`}>
        <span>{`${message}`}</span>
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            fontSize: "10px",
          }}
        >
          {`${moment(time_date).format("LT")}`}
        </span>
      </main>
    );
  } else if (user === "date") {
    return <main className={`messageBox ${classs}`}>{message}</main>;
  } else if (isLoading && progress) {
    return (
      <main className={`messageBox ${classs}`}>
        <img
          src={message}
          alt={`Preview`}
          loading="lazy"
          style={{
            backgroundImage: isLoading
              ? "url(https://ucarecdn.com/faff2888-016b-4c54-9870-25f9a21129f5/-/preview/1600x900/-/blur/500/)"
              : message,
            filter: isLoading ? `blur(${(1 - progress / 100) * 10}px)` : "none",
          }}
          className={`preview__image ${isLoading ? "loading" : ""}`}
        />
      </main>
    );
  } else if (file) {
    return (
      <main className={`messageBox ${classs}`}>
        <img src={message} alt="img" className="message__image" />
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            fontSize: "10px",
            paddingLeft: "2rem",
          }}
        >
          {`${moment(time_date).format("LT")}`}
        </span>
      </main>
    );
  } else {
    return (
      <main className={`messageBox ${classs}`}>
        <span>{`${message}`}</span>
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            fontSize: "10px",
            paddingLeft: "2rem",
          }}
        >
          {`${moment(time_date).format("LT")}`}
        </span>
      </main>
    );
  }
};

export default Messages;
