import { HiMiniXMark } from "react-icons/hi2";
import { IoMdCheckmark } from "react-icons/io";
import { Badge } from "primereact/badge";
import DropdownMenubar from "../components/Menubar/DropdownMenubar.jsx";

const DD = (MenuItems) => {
  return (
    <div className="p-3">
      <DropdownMenubar
        menuItem={[
          {
            label: "Action",
            items: MenuItems,
          },
        ]}
      />
    </div>
  );
};

const getVendorInfo = (row, handleCellClick) => {
  return DD([
    {
      label: "APPROVE",
      icon: (
        <div className="  text-white  rounded-full bg-green-600">
          {" "}
          <IoMdCheckmark className="" />
        </div>
      ),

      command: () => {
        handleCellClick(row, "Approved");
      },
    },
    {
      label: "UNAPPROVE",
      icon: (
        <div className="  text-white  rounded-full bg-red-600">
          {" "}
          <HiMiniXMark className="" />
        </div>
      ),
      command: () => {
        handleCellClick(row, "Unapproved");
      },
    },
  ]);
};
export const columns = [
  {
    field: "Category",
    header: "Category Name",
    accessor: (row, handleCellClick) => (
      <>
        <div onClick={() => handleCellClick(row, "Category")} className="">
          <p>{row?.Category?.name}</p>
          {/* <p className="w-[6rem]">
            {moment(row?.order_date).utc().format("DD MMM YYYY hh:mma")}
          </p> */}
        </div>
      </>
    ),
  },
  {
    field: "name",
    header: "Subcategory Name",
    accessor: (row, handleCellClick) => (
      <>
        <div onClick={() => handleCellClick(row, "name")} className="">
          <p>{row?.name}</p>
          {/* <p className="w-[6rem]">
            {moment(row?.order_date).utc().format("DD MMM YYYY hh:mma")}
          </p> */}
        </div>
      </>
    ),
  },

  {
    field: "status",
    header: "Status",
    accessor: (row, handleCellClick) => (
      <>
        {row?.status && (
          <Badge
            className="w-[6rem]"
            severity={
              row?.status === "Rejected"
                ? "danger"
                : row?.status === "Pending"
                ? "warning"
                : "success"
            }
            value={row?.status}
          />
        )}
      </>
    ),
  },
  // {
  //   field: "action",
  //   header: "Action",
  //   accessor: (row, handleCellClick) => getVendorInfo(row, handleCellClick),
  // },
];

export const searchBy = {
  filterType: "Sort By",
  inputName: "search_by",
  options: [
    {
      name: "Descending ",
      code: "desc",
    },
    {
      name: "Ascending",
      code: "asc",
    },
  ],
};

// export const filters = [
//   // enum: ['Store', 'Product', 'Category', 'Sub Category'],
//   {
//     filterType: "Filter by A/U",
//     inputName: "status",
//     options: [
//       {
//         name: "Approved",
//         code: "Approved",
//       },
//       {
//         name: "Unapproved",
//         code: "Unapproved",
//       },
//     ],
//   },
// ];
