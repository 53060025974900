import { ErrorMessage, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import {
  MdDeleteForever,
  MdEdit,
  MdOutlineDateRange,
  MdOutlineEdit,
} from "react-icons/md";
import { CiDiscount1 } from "react-icons/ci";
import { RiCoupon3Line } from "react-icons/ri";
import { useSelector } from "react-redux";
import promoimg from "../../assest/offer_promo.png";
import BreadCrumb from "../../components/BreadCrumb";
import Button from "../../components/Button";
import Dropdown, { SelectMultipleFields } from "../../components/Dropdown";
import Lable from "../../components/Lable";
import SidebarField from "../../components/Sidebar/SidebarField";
import TextInput from "../../components/TextInput";
import Textarea from "../../components/Textarea";
import ToggleField from "../../components/ToggleField";
import { IoMdInformationCircleOutline, IoMdPerson } from "react-icons/io";
import CalendarComp from "../../components/CalendarComp/CalendarComp";
import moment from "moment/moment";
import ImagePreview from "../../components/ImagePreview";
import LoadingContext from "../../components/loader/index";
import { promoCodeApi } from "../../apis/Offer.api";
import { toast } from "react-toastify";
import { IoPersonOutline } from "react-icons/io5";

import DeleteFieldModal from "../../components/DeleteFieldModal";
import {
  CategoryApi,
  productApi,
  SubCategoryApi,
} from "../../apis/ProductListing.apis";
import Tooltip from "../../components/Tooltip/Tooltip";
import { FaDotCircle } from "react-icons/fa";

const Offers = () => {
  const { handleLoading } = useContext(LoadingContext);
  const [promocodeData, setPromocodeData] = useState([]);
  const [editPromocodeData, setEditPromocodeData] = useState({});
  const [visible, setVisible] = useState(false);
  const [isApplyOfferChecked, setIsApplyOfferChecked] = useState(false);
  const [isRequireUses, setIsRequireUses] = useState(false);
  const [isMinOrderAmount, setIsMinOrderAmount] = useState(false);
  const [isMaxDiscountAmount, setIsMaxDiscountAmount] = useState(false);
  const [isMinItemCart, setIsMinItemCart] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [categoryData, setCategoryData] = useState([]);
  const [subcategoryData, setSubCategoryData] = useState([]);
  const [productData, setProductData] = useState([]);
  const { currentUser } = useSelector((state) => state.store);

  const handleOpen = () => {
    setVisible(true);
  };
  const handleEditOffer = (value) => {
    handleClose();
    setTimeout(() => {
      handlePromoForOption(value.promo_for);
      setIsApplyOfferChecked(Boolean(value?.apply_offer_after_orders));
      setIsRequireUses(Boolean(value?.required_uses));
      setIsMinOrderAmount(
        Boolean(Number(value?.min_order_amount?.$numberDecimal))
      );
      setIsMaxDiscountAmount(
        Boolean(Number(value?.max_discount_amount?.$numberDecimal))
      );
      setIsMinItemCart(value?.min_item_on_cart);
      setEditPromocodeData(value);
      setVisible(true);
    }, 100);
    console.log(value);
  };

  const handleDeleteClose = () => {
    setDeleteModal(false);
  };

  const handleDeleteOffer = (value) => {
    console.log(value);
    setEditPromocodeData(value);
    setDeleteModal(true);
  };

  const handleDelete = async () => {
    handleLoading(true);

    try {
      let res;

      if (editPromocodeData._id) {
        res = await promoCodeApi.deletePromoCode(editPromocodeData._id);
        toast.success(`${res.data.data}`);
        beforeMount();
      }

      console.log(res);
    } catch (err) {
      console.log(err);
      // toast.error(`${err.message}`)
    } finally {
      setEditPromocodeData({});
      handleLoading(false);
      handleDeleteClose();
    }
  };

  const handleClose = () => {
    setVisible(false);
    setError();
    setEditPromocodeData({});
  };

  const handleCheckBox = (e, setFieldValue, cb) => {
    const isChecked = e.target.checked;
    console.log("isChecked", isChecked);

    if (isChecked) {
      setFieldValue(e.target.name, e.target.value);
    } else {
      setFieldValue(e.target.name, 0);
    }

    cb(isChecked);
  };

  const handleSubmit = async (values) => {
    console.log("Offers Value", values);
    handleLoading(true);
    try {
      const res = editPromocodeData._id
        ? await promoCodeApi.updatePromoCode(values, editPromocodeData._id)
        : await promoCodeApi.createPromoCode(values);
      console.log("res", res);
      beforeMount();
      toast.success(
        editPromocodeData._id ? "Promo Code updated" : "Promo Code Created"
      );
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
    handleClose();
    handleLoading(false);
  };

  const fetchingPromoforOptions = async (promoFor) => {
    handleLoading(true);

    try {
      if (promoFor === "Category") {
        const resCategory =
          currentUser?.delivery_business?.name === "Restourant "
            ? await CategoryApi.getAllFoodCategory()
            : await CategoryApi.getAllCategory();
        // setCategoryData(resCategory.data?.data);
        return resCategory.data?.data;
      }
      if (promoFor === "Sub Category") {
        const resSubCategory =
          currentUser?.delivery_business?.name === "Restourant "
            ? await SubCategoryApi.getAllFoodSubCategory()
            : await SubCategoryApi.getSubCategory();
        // setSubCategoryData(resSubCategory.data?.data)
        return resSubCategory.data?.data;
      }

      if (promoFor === "Product") {
        const resProduct =
          currentUser?.delivery_business?.name === "Restourant "
            ? await productApi.getAllFoodProduct()
            : await productApi.getAllApprovedProduct();
        // setProductData(resProduct.data?.data)
        return resProduct.data?.data;
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      handleLoading(false);
    }
  };
  const [optionPromoFor, setOptionPromoFor] = useState([]);

  const handlePromoForOption = async (promoFor) => {
    if (promoFor !== "Store") {
      const data = await fetchingPromoforOptions(promoFor);
      console.log("data", data);
      const option =
        data &&
        data.length &&
        data.map((d) => {
          d.label = d.name;
          d.value = d._id;
          return d;
        });
      console.log(option);

      setOptionPromoFor(option ? option : []);
    }
  };

  const beforeMount = async () => {
    try {
      const resPromocode = await promoCodeApi.getAllPromoCodes();
      console.log("resPromocode", resPromocode);
      setPromocodeData(resPromocode.data.data?.promoCodes);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  const getMultiselectValue = (value) => {
    console.log("value", value);

    if (value && value.length) {
      // values.promo_for==="Category"?"category":values.promo_for==="Sub Category"?"sub_category":"product"
      const selectedId = value.map((item) => {
        if (item._id) {
          return item._id;
        } else {
          return item;
        }
      });
      return selectedId;
    } else {
      return null;
    }
  };

  //image uplaod function with validation of size 1MB
  const [error, setError] = useState("");

  const handleFileUpload = (e, setFieldValue) => {
    const file = e.target.files[0];
    // console.log("e",e.target.files[0].size/ 1024 / 1024);

    if (file) {
      const fileSizeInMB = file.size / 1024 / 1024;
      if (fileSizeInMB > 1) {
        setError("Please select an image smaller than 1MB.");
        setFieldValue("image", null);
        return;
      }

      setError("");
      setFieldValue("image", file);
    }
  };

  useEffect(() => {
    handleLoading(true);
    beforeMount();
    handleLoading(false);
  }, []);

  return (
    <div className="p-5 w-[100%]">
      <div className="flex justify-between items-center flex-wrap mb-2 border-b">
        <BreadCrumb title="Offers" />

        <Button
          text="Add New"
          type="button"
          className="float-end"
          onClick={handleOpen}
        />
      </div>

      <div className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-4 mt-5">
        {promocodeData.length ? (
          promocodeData.map((item) => {
            return (
              <Card
                item={item}
                handleDelete={handleDeleteOffer}
                handleEditOffer={handleEditOffer}
              />
            );
          })
        ) : (
          <p className="text-center w-full">No data found</p>
        )}
      </div>

      {visible && (
        <Formik
          initialValues={{
            image: editPromocodeData?.image || "",
            promo_code: editPromocodeData?.promo_code || null,
            discount_type: editPromocodeData?.discount_type || null,
            discount: editPromocodeData?.discount?.$numberDecimal || 0,
            description: editPromocodeData?.description || null,
            promo_for: editPromocodeData?.promo_for || null,
            category: editPromocodeData?.category || null,
            sub_category: editPromocodeData?.sub_category || null,
            product: editPromocodeData?.product || null,
            active: editPromocodeData?.active || false,
            is_validation_date: editPromocodeData?.is_validation_date || false,
            start_date: editPromocodeData?.start_date || null,
            end_date: editPromocodeData?.end_date || null,
            repeat: editPromocodeData?.repeat || null,
            apply_offer_after_orders:
              editPromocodeData?.apply_offer_after_orders || 0,
            required_uses: editPromocodeData?.required_uses || 0,
            min_order_amount:
              Number(editPromocodeData?.min_order_amount?.$numberDecimal) || 0,
            max_discount_amount:
              Number(editPromocodeData?.max_discount_amount?.$numberDecimal) ||
              0,
            min_item_on_cart: editPromocodeData?.min_item_on_cart || 0,
          }}
          onSubmit={handleSubmit}
          // validationSchema={}
        >
          {({ handleChange, setFieldValue, values, handleSubmit }) => (
            <Form>
              <SidebarField
                handleClose={handleClose}
                title={editPromocodeData._id ? "Update Offer" : "Add New Offer"}
                button1={
                  <Button text="Cancel" type="button" onClick={handleClose} />
                }
                button2={
                  <Button text="Submit" type="submit" onClick={handleSubmit} />
                }
              >
                <div className="w-full gap-4 flex flex-col bg-transparent py-5 px-3">
                  <div className="relative h-fit w-[95%] shadow-xl rounded-xl m-auto">
                    <label
                      htmlFor="image-upload"
                      className="absolute right-1 top-1 p-2 bg-buttonColor rounded-full cursor-pointer text-secondaryColor"
                    >
                      <input
                        type="file"
                        id="image-upload"
                        accept="image/*"
                        onChange={(e) => handleFileUpload(e, setFieldValue)}
                        // value={values.image}
                        name="image"
                        style={{ display: "none" }}
                      />
                      <MdEdit />
                    </label>
                    {typeof values.image === "object" ? (
                      <ImagePreview file={values.image} Id="promo" />
                    ) : (
                      <img
                        src={values.image ? values.image : promoimg}
                        alt="Promo Not Found"
                      />
                    )}
                  </div>
                  {error && (
                    <p className="text-red-500 text-sm mt-2">{error}</p>
                  )}
                  <div>
                    <div className="flex">
                      <Lable lable="Promo code" />
                      <Tooltip text="give a name to your promo code" />
                    </div>
                    <TextInput
                      name="promo_code"
                      type="text"
                      placeholder="Promo Code"
                      onChange={handleChange}
                      value={values.promo_code}
                    />
                    <ErrorMessage
                      name="promo_code"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="flex justify-between gap-2">
                    <div className="w-[90%]">
                      <div className="flex">
                        <Lable lable="Discount type" />
                        <Tooltip text="select descount type" />
                      </div>
                      <Dropdown
                        options={[
                          { label: "Absolute", value: "Absolute" },
                          { label: "Percentage", value: "Percentage" },
                        ]}
                        name="discount_type"
                        value={values.discount_type}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        name="discount_type"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="w-[90%]">
                      <div className="flex">
                        <Lable lable="Discount" />
                        <Tooltip text="give the discounted amount" />
                      </div>
                      <TextInput
                        name="discount"
                        type="number"
                        placeholder="0"
                        onChange={handleChange}
                        value={values.discount}
                      />
                      <ErrorMessage
                        name="discount"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex">
                      <Lable lable="Description" />
                      <Tooltip text="give a brief description of your discount" />
                    </div>
                    <Textarea
                      name="description"
                      type="text"
                      placeholder=""
                      onChange={handleChange}
                      value={values.description}
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div>
                    <div className="flex">
                      <Lable lable="Promo for" />
                      <Tooltip text="select area of promotion" />
                    </div>
                    <Dropdown
                      options={[
                        { label: "Store", value: "Store" },
                        { label: "Category", value: "Category" },
                        { label: "Sub Category", value: "Sub Category" },
                        { label: "Product", value: "Product" },
                      ]}
                      name="promo_for"
                      value={values.promo_for}
                      handleOnchange={(value) => {
                        // console.log(value);
                        setFieldValue("category", null);
                        setFieldValue("sub_category", null);
                        setFieldValue("product", null);
                        setFieldValue("promo_for", value.value);
                        handlePromoForOption(value.value);
                        // handleChange
                      }}
                    />
                    <ErrorMessage
                      name="promo_for"
                      component="div"
                      className="error"
                    />
                  </div>
                  {values.promo_for && values.promo_for !== "Store" && (
                    <div>
                      <Lable lable={`${values.promo_for}`} />
                      <SelectMultipleFields
                        options={optionPromoFor.length ? optionPromoFor : []}
                        name={
                          values.promo_for === "Category"
                            ? "category"
                            : values.promo_for === "Sub Category"
                            ? "sub_category"
                            : "product"
                        }
                        value={
                          values[
                            values.promo_for === "Category"
                              ? "category"
                              : values.promo_for === "Sub Category"
                              ? "sub_category"
                              : "product"
                          ] !== null
                            ? getMultiselectValue(
                                values[
                                  values.promo_for === "Category"
                                    ? "category"
                                    : values.promo_for === "Sub Category"
                                    ? "sub_category"
                                    : "product"
                                ]
                              )
                            : null
                        }
                        handleOnchange={(v) => {
                          console.log("value", v);
                          setFieldValue(`${values.promo_for}`, v);
                        }}
                        className={["w-full"]}
                        sx={{ width: "100%" }}
                      />
                      <ErrorMessage
                        name="promo_for"
                        component="div"
                        className="error"
                      />
                    </div>
                  )}
                  <div>
                    <ToggleField
                      text="Is your offer running ?"
                      name="active"
                      onChange={handleChange}
                      value={values.active}
                    />
                    <ErrorMessage
                      name="active"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="border-b">Offer Advance settings</div>
                  <div>
                    <ToggleField
                      text="Do you want to set offer validation date ?"
                      name="is_validation_date"
                      value={values.is_validation_date}
                      toggaleHandleChange={(e) => {
                        console.log("e.target.value", e.target.value);

                        if (!e.target.value) {
                          setFieldValue("start_date", null);
                          setFieldValue("end_date", null);
                        }
                        handleChange(e);
                      }}
                    />
                    <ErrorMessage
                      name="is_validation_date"
                      component="div"
                      className="error"
                    />
                  </div>
                  {values.is_validation_date && (
                    <div>
                      <div className="flex">
                        <Lable lable="Offer Apply Date Range" />
                        <Tooltip text="set the date range for your offer" />
                      </div>
                      {/* <TextInput
                      name="dateRange"
                      type="text"
                      placeholder="Date Range"
                      onChange={handleChange}
                      value={values.dateRange}
                    /> */}
                      <CalendarComp
                        // numberOfMonths={2}
                        className="w-full h-12 border-[#e5e7eb]"
                        showTime
                        hourFormat="12"
                        sx={{
                          width: "100%",
                          borderRadius: "5px",
                          height: "4rem",
                        }}
                        handleFilter={(type, v) => {
                          console.log("Type", type);
                          console.log("value", v);
                          const formattedDates = v.map((date) =>
                            moment(date)
                              .utc()
                              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                          );
                          console.log(formattedDates);
                          setFieldValue("start_date", formattedDates[0]);
                          setFieldValue("end_date", formattedDates[1]);
                        }}
                      />
                      <ErrorMessage
                        name="dateRange"
                        component="div"
                        className="error"
                      />
                    </div>
                  )}
                  <div>
                    <div className="flex">
                      <Lable lable="Recursion type" />
                      <Tooltip text="select the repertation of offer" />
                    </div>
                    <Dropdown
                      options={[
                        { id: "1", label: "No Recursion", value: "No" },
                        {
                          id: "2",
                          label: "Daily Recursion",
                          value: "Daily Recursion",
                        },
                        {
                          id: "3",
                          label: "Weekly Recursion",
                          value: "Weekly Recursion",
                        },
                        {
                          id: "4",
                          label: "Monthly Recursion",
                          value: "Monthly Recursion",
                        },
                        {
                          id: "5",
                          label: "Annually Recursion",
                          value: "Annually Recursion",
                        },
                      ]}
                      name="repeat"
                      value={values.repeat}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name="repeat"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div>
                    <div className="flex">
                      <Lable lable={"Apply offer after complete no of order"} />
                      <Tooltip text="enter minimum no of order to apply offer" />
                    </div>
                    <div className="flex items-center ">
                      <div className="border px-2.5 py-2">
                        <input
                          type="checkbox"
                          name="apply_offer_after_orders"
                          id="apply_offer_after_orders"
                          value={values.apply_offer_after_orders}
                          checked={
                            Boolean(values.apply_offer_after_orders)
                              ? Boolean(values.apply_offer_after_orders)
                              : isApplyOfferChecked
                          }
                          onChange={(e) =>
                            handleCheckBox(
                              e,
                              setFieldValue,
                              setIsApplyOfferChecked
                            )
                          }
                        />
                      </div>
                      <TextInput
                        name="apply_offer_after_orders"
                        type="number"
                        placeholder="0"
                        onChange={handleChange}
                        value={values.apply_offer_after_orders}
                        disabled={!isApplyOfferChecked}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex">
                      <Lable lable={"Required Uses"} />
                      <Tooltip text="enter the number of required uses" />
                    </div>
                    <div className="flex items-center ">
                      <div className="border px-2.5 py-2">
                        <input
                          type="checkbox"
                          name="required_uses"
                          id="required_uses"
                          value={values.required_uses}
                          checked={
                            Boolean(values.required_uses)
                              ? Boolean(values.required_uses)
                              : isRequireUses
                          }
                          onChange={(e) =>
                            handleCheckBox(e, setFieldValue, setIsRequireUses)
                          }
                        />
                      </div>
                      <TextInput
                        name="required_uses"
                        type="number"
                        placeholder="0"
                        onChange={handleChange}
                        value={values.required_uses}
                        disabled={!isRequireUses}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex">
                      <Lable
                        lable={
                          "Do you want to set min order amount for apply ?"
                        }
                      />
                      <Tooltip text="you can set minimum order amount to apply offer" />
                    </div>
                    <div className="flex items-center ">
                      <div className="border px-2.5 py-2">
                        <input
                          type="checkbox"
                          name="min_order_amount"
                          id="min_order_amount"
                          value={values.min_order_amount}
                          checked={
                            Boolean(values.min_order_amount)
                              ? Boolean(values.min_order_amount)
                              : isMinOrderAmount
                          }
                          onChange={(e) =>
                            handleCheckBox(
                              e,
                              setFieldValue,
                              setIsMinOrderAmount
                            )
                          }
                        />
                      </div>
                      <TextInput
                        name="min_order_amount"
                        type="number"
                        placeholder="0"
                        onChange={handleChange}
                        value={values.min_order_amount}
                        disabled={!isMinOrderAmount}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex">
                      <Lable
                        lable={"Do you want to set max discount amount ?"}
                      />
                      <Tooltip text="you can set maximum discounted amount here" />
                    </div>
                    <div className="flex items-center ">
                      <div className="border px-2.5 py-2">
                        <input
                          type="checkbox"
                          name="max_discount_amount"
                          id="max_discount_amount"
                          // checked={isMaxDiscountAmount}
                          // onChange={(e) =>
                          //   setIsMaxDiscountAmount(e.target.checked)
                          // }
                          value={values.max_discount_amount}
                          checked={
                            Boolean(values.max_discount_amount)
                              ? Boolean(values.max_discount_amount)
                              : isMaxDiscountAmount
                          }
                          onChange={(e) =>
                            handleCheckBox(
                              e,
                              setFieldValue,
                              setIsMaxDiscountAmount
                            )
                          }
                        />
                      </div>
                      <TextInput
                        name="max_discount_amount"
                        type="number"
                        placeholder="0"
                        onChange={handleChange}
                        value={values.max_discount_amount}
                        disabled={!isMaxDiscountAmount}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex">
                      <Lable
                        lable={
                          "Do you want to set min item on cart for apply ?"
                        }
                      />
                      <Tooltip text="you can set minimum number of item on cart to apply offer" />
                    </div>
                    <div className="flex items-center ">
                      <div className="border px-2.5 py-2">
                        <input
                          type="checkbox"
                          name="min_item_on_cart"
                          id="min_item_on_cart"
                          value={values.min_item_on_cart}
                          checked={
                            Boolean(values.min_item_on_cart)
                              ? Boolean(values.min_item_on_cart)
                              : isMinItemCart
                          }
                          onChange={(e) =>
                            handleCheckBox(e, setFieldValue, setIsMinItemCart)
                          }
                        />
                      </div>
                      <TextInput
                        name="min_item_on_cart"
                        type="number"
                        placeholder="0"
                        onChange={handleChange}
                        value={values.min_item_on_cart}
                        disabled={!isMinItemCart}
                      />
                    </div>
                  </div>
                </div>
              </SidebarField>
            </Form>
          )}
        </Formik>
      )}
      <DeleteFieldModal
        isOpen={deleteModal}
        onClose={handleDeleteClose}
        onDelete={() => handleDelete()}
      />
    </div>
  );
};

export default Offers;

const Card = ({ key, handleEditOffer, handleDelete, item }) => {
  const { theme } = useSelector((state) => state.theme);
  const [isShowDescriptionid, setIsShowDescriptionid] = useState(null);
  const handleDescriptionToggle = (id) => {
    setIsShowDescriptionid(isShowDescriptionid === id ? null : id);
  };
  return (
    <div
      key={item._id}
      className={`${
        theme === "light" ? "bg-secondaryColor" : "bg-darkThirsary"
      } border border-gray-300 rounded-lg shadow-lg overflow-hidden w-full ${
        !isShowDescriptionid ? `h-auto` : ``
      } max-w-sm mx-auto`}
    >
      <div className="flex-col items-center justify-center">
        <div className="w-full h-40 object-cover">
          <img
            src={item.image ? item.image : promoimg}
            alt="promo logo"
            className="w-full h-full object-cover rounded-lg"
          />
        </div>

        <p
          className={`${
            item.status === "Pending"
              ? "text-yellow-500"
              : item.status === "Approved"
              ? "text-green-500"
              : "text-red-500"
          } flex items-center gap-1 justify-end pr-3 pt-1`}
        >
          <span
            className={`${
              item.status === "Pending"
                ? "text-yellow-500"
                : item.status === "Approved"
                ? "text-green-500"
                : "text-red-500"
            }`}
          >
            <FaDotCircle />
          </span>
          {item.status}
        </p>

        <div className="w-full flex flex-col justify-evenly px-4 py-2">
          <div className="uppercase flex justify-between items-center">
            <div className="flex gap-2">
              <span className="pt-1.5 text-[#145388]">
                <RiCoupon3Line />
              </span>
              <h3 className="text-xl font-sans font-bold text-blue-800 dark:text-white">
                {item.promo_code}
              </h3>
            </div>

            <div className="flex gap-2">
              <span
                onClick={() => handleEditOffer(item)}
                className="p-1  text-blue-700 rounded-full hover:bg-blue-100 hover:border border-blue-500  transition-colors duration-200"
              >
                <MdOutlineEdit className="text-lg" />
              </span>
              <span
                onClick={() => handleDelete(item)}
                className=" p-1  text-red-700 rounded-full hover:bg-red-100 hover:border border-red-500  transition-colors duration-200"
              >
                <MdDeleteForever className="text-lg" />
              </span>
            </div>
          </div>

          <div>
            <div className="flex gap-2 items-start">
              <span className="pt-1">
                <CiDiscount1 className="text-[#145388]" />
              </span>
              <span>
                <span className="inline font-semibold">Discount:</span>{" "}
                <p className="inline ">
                  {" "}
                  {item.discount.$numberDecimal}
                  {item.discount_type === "Percentage" ? "%" : ""}
                </p>
              </span>
            </div>
            <div className="flex gap-2 items-start">
              <span className="pt-1">
                <MdOutlineDateRange className="text-[#145388]" />
              </span>
              <span>
                <span className="inline font-semibold">Start:</span>{" "}
                <p className="inline ">
                  {" "}
                  {moment(item.start_date).utc().format("DD MMM YYYY")}{" "}
                </p>
              </span>
            </div>
            <div className="flex gap-2 items-start">
              <span className="pt-1">
                <MdOutlineDateRange className="text-[#145388]" />
              </span>
              <span>
                <span className="inline font-semibold">Expiry:</span>{" "}
                <p className="inline ">
                  {" "}
                  {moment(item.end_date).utc().format("DD MMM YYYY")}
                </p>
              </span>
            </div>
            <div className="flex gap-2 items-start">
              <span className="pt-1">
                <IoPersonOutline className="text-[#145388]" />
              </span>
              <span className="">
                <span className="inline font-semibold">No of usages: </span>{" "}
                <p className="inline ">{item.required_uses}</p>
              </span>
            </div>
            {/* <div className="flex gap-2 items-center">
              <span className="pt-1">
                <IoMdInformationCircleOutline className="text-[#145388]"/>
              </span>
              <span><span className="font-semibold">Description: </span> <p className="inline-block w-48 whitespace-nowrap overflow-hidden text-ellipsis">{ item.description}</p></span>
            </div> */}
            <div className="flex gap-2 items-start">
              <span className="pt-1">
                <IoMdInformationCircleOutline className="text-[#145388]" />
              </span>
              <span className="flex items-start">
                <span className="font-semibold">Description:</span>
                <p
                  className={`inline-block w-48 ${
                    isShowDescriptionid === item._id
                      ? `whitespace-normal `
                      : `whitespace-nowrap`
                  } overflow-hidden text-ellipsis ml-2 cursor-pointer`}
                  onClick={() => handleDescriptionToggle(item._id)}
                >
                  {item.description}
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
