import React, { useState } from "react";
// import CommonLayout from "../../../Components/CommonLayout/CommonLayout";
import AUPromoCodeRejectedComponent from "./AUPromoCodeRejectedComponent";

const AUPromocodeRejected = ({ meta }) => {
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 5,
    sortOrder: -1,
    filter: {
      status: "Rejected",
    },
  });

  const handlePagination = (data) => {
    if (data.clearPagination) {
      setPagination(data.clearPagination);
      return;
    }

    setPagination((prev) => {
      let copyfilter = { ...prev.filter };
      if (data.filter) {
        if (copyfilter[Object.keys(data.filter)[0]]) {
          copyfilter[Object.keys(data.filter)[0]] = Object.values(
            data.filter
          )[0];
        } else {
          copyfilter = { ...data?.filter, ...copyfilter };
        }
      }

      return {
        page: data.page ? data.page : prev.page,
        limit: data.limit ? data.limit : prev.limit,
        sortOrder: data.sortOrder ? data.sortOrder : prev.sortOrder,
        filter: copyfilter,
      };
    });
  };

  return (
    <div className="p-5">
      {/* <CommonLayout /> */}
      <AUPromoCodeRejectedComponent
        pagination={pagination}
        handlePagination={handlePagination}
      />
    </div>
  );
};

export default AUPromocodeRejected;

// {
//   "title": "Current Week",
//   "path": "history/current-week",
//   "icon": "SlCalender"
// },
// {
//   "title": "Current Month",
//   "path": "history/current-month",
//   "icon": "VscCalendar"
// },

/*
"submenu": [
      {
        "icon": "IoMdCart",
        "title": "Create Order",
        "path": "order/create-order"
      }
    ],
 */
