import React, { useState } from "react";
import styles from "./users.module.css";
import { RxCross1 } from "react-icons/rx";
import OrderDetails from "./OrderDetails";
import OrderInvoice from "./OrderInvoice";
import OrderStatus from "./OrderStatus";
import OrderChat from "./OrderChat";
import { useSelector } from "react-redux";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import SidebarField from "../../../components/Sidebar/SidebarField";
import ChatComponent from "../../../components/Socket/ChatUI";

const OrderModal = ({ handleCloseOrderModal, isOrderDetail, orderData }) => {
  const [toggleContent, setToggleContent] = useState("Details");
  const [isFullWidth,setIsFullWidth]=useState(false)
  console.log("orderData", orderData);
  

  const { theme } = useSelector((state) => state.theme);
  if (isOrderDetail) {
    return (
      <>
       
            <SidebarField footer={false} handleClose={()=>handleCloseOrderModal()} title={`Order Details`}>
            <div style={{ display: "flex" }}>
              {["Details", "Invoice", "Status", "Chat"].map((item) => (
                <hr
                  style={{
                    width: "25%",
                    borderTop:
                      item === toggleContent ? "5px solid  " : "1px solid",
                    color:
                      item === toggleContent
                        ? "rgb(15 61 100 / var(--tw-text-opacity)"
                        : "var(--light-color3)",
                    borderRadius: "10px",
                    borderBottom: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                />
              ))}
            </div>

            <div className={`${styles.content_list}  border-black-700 `}>
              {["Details", "Invoice", "Status", "Chat"].map((item) => (
                <span
                  onClick={() => setToggleContent(item)}
                  style={{
                    color:
                      item === toggleContent
                        ? "var(--color--2) "
                        : "var(--light-color3)",
                    cursor: "pointer",
                  }}
                >
                  {item}
                </span>
              ))}
            </div>
            <hr />
            {toggleContent === "Details" && (
              <OrderDetails
                deliveryAddress={orderData?.delivery_address}
                orderDetails={orderData?.orderDetails}
                orderData={orderData}
              />
            )}
            {toggleContent === "Invoice" && (
              <OrderInvoice invoiceData={orderData} />
            )}
            {toggleContent === "Status" && (
              <OrderStatus orderStatus={orderData?.orderStatus} />
            )}
            {/* {toggleContent === "Chat" && <OrderChat />} */}
            {toggleContent === "Chat" && <ChatComponent data={orderData} />}
            </SidebarField>
      </>
    );
  }
};

export default OrderModal;
