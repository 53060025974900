import { ErrorMessage, Form, Formik } from "formik";
import "./itemList.style.css";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FaCamera, FaRegCheckCircle, FaSearch } from "react-icons/fa";
import { FaRegCircleCheck } from "react-icons/fa6";
import { FiInfo } from "react-icons/fi";
import { IoAddCircleOutline } from "react-icons/io5";
import { TbPoint } from "react-icons/tb";
import BreadCrumb from "../../components/BreadCrumb";
import Button, { SecondaryButton } from "../../components/Button";
import Dropdown from "../../components/Dropdown";
import { FormField } from "../../components/FormField";
import Lable from "../../components/Lable";
import SidebarField from "../../components/Sidebar/SidebarField";
import SidebarRight from "../../components/Sidebar/SidebarRight";
import TextInput from "../../components/TextInput";
import ToggleField from "../../components/ToggleField";
import ReactQuill from "react-quill";
import PaginatorCom from "../../components/Paginator/PaginatorCom";

import {
  CategoryApi,
  modifierCategory,
  SubCategoryApi,
  TaxRegimeApi,
} from "../../apis/ProductListing.apis";
import LoadingContext from "../../components/loader/index";
import ImagePreview from "../../components/ImagePreview";
import { MdDeleteOutline } from "react-icons/md";
import { toast } from "react-toastify";
import DeleteFieldModal from "../../components/DeleteFieldModal";
import { compressImage } from "../../custom/compressImage";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Tooltip from "../../components/Tooltip/Tooltip";
import masterApi from "../../apis/master.api";
import LoaderContext from "../../components/loader/index";
import FoodCard from "../../components/FoodCard";
import {
  FoodListingApi,
  FoodModifierApi,
  foodProductImageApi,
} from "../../apis/FoodListing.api";
import { useFetchAll } from "../../custom/FetchApiHook";

const ItemFoodList = () => {
  const debounceTimeout = useRef(null);
  const { handleLoading } = useContext(LoadingContext);

  const [allTax, setAllTax] = useState([]);
  const [error, setError] = useState("");
  const [addItemVisible, setAddItemVisible] = useState(false);
  const [addModifier, setAddModifier] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [step, setStep] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [productPagination, setProductPagination] = useState({
    page: 1,
    limit: 8,
    sortOrder: "desc",
    filter: {},
    totalPages: 1,
    totalCount: 5,
  });
  const [editData, setEditData] = useState("");
  const [editProduct, setEditProduct] = useState("");
  const [imagesList, setImagesList] = useState([]);
  const [selectedModifierCategory, setSelectedModifierCategory] =
    useState(null);
  const [modifierCategories, setModifierCategories] = useState([]);
  const [productModifiers, setProductModifiers] = useState([]);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalData, setDeleteModalData] = useState({
    itemName: null,
    itemData: null,
  });

  const [, setColorPicker] = useState(false);
  const [isShowDescriptionid, setIsShowDescriptionid] = useState(null);
  const [Taxes, setTaxes] = useState([]);

  const initialProductValues = {
    primary_image: editProduct?.primary_image || null,
    name: editProduct?.name || null,
    price: editProduct?.price?.$numberDecimal || null,
    tax: editProduct?.tax?._id || null,
    sub_category: editProduct?.sub_category || null,
    additional_info: editProduct?.additional_info || null,
    description: editProduct?.description || null,
    cancellation_policy: editProduct?.cancellation_policy || null,
    return_policy: editProduct?.return_policy || null,
    isCustomizable: editProduct?.isCustomizable || false,
  };

  const taxRegimeData = useFetchAll(() => TaxRegimeApi.getAllRegimeApi());
  const taxOptions = taxRegimeData.value?.data
    ? taxRegimeData.value?.data?.map((d) => {
        d.label = d.name;
        d.value = d._id;
        return d;
      })
    : [{}];

  const handleDeleteClose = () => {
    setDeleteModal(false);
  };

  const openDeleteModal = (name, value) => {
    setDeleteModalData((prev) => {
      return {
        itemName: name,
        itemData: value,
      };
    });
    setDeleteModal(true);
  };

  const handleDelete = async () => {
    handleLoading(true);

    try {
      let res;

      if (deleteModalData.itemName === "modifier_category") {
        res = await FoodModifierApi.deleteFoodProductModifier(
          deleteModalData?.itemData?._id
        );
        toast.success(`${res.data.message}`);
        getAllProductModifier();
      } else if (deleteModalData.itemName === "product_images") {
        res = await foodProductImageApi.deleteFoodProductImage(
          deleteModalData.itemData._id
        );
        toast.success(`${res.data.message}`);
        getAllProductImages();
      } else if (deleteModalData.itemName === "subcategory") {
        res = await SubCategoryApi.deleteSubCategory(
          deleteModalData.itemData._id
        );
        toast.success(`${res.data.message}`);
        getAllSubcategory();
      } else {
        if (deleteModalData.itemName) {
          res = await FoodListingApi.deleteFoodProduct(
            deleteModalData.itemName._id
          );
          toast.success(`${res.data.message}`);
          getAllProducts();
        }
      }
      console.log(res);
    } catch (err) {
      console.log(err);
      toast.error(`${err.message}`);
    } finally {
      setDeleteModalData({ itemName: null, itemData: null });
      handleLoading(false);
      handleDeleteClose();
    }
  };

  const handleOpen = () => {
    setEditVisible(true);
  };

  const handleClose = () => {
    setEditData("");
    setEditVisible(false);
  };
  const handleCloseItem = () => {
    setEditProduct("");

    setColorPicker(false);
    setImagesList([]);
    setSelectedModifierCategory(null);
    setModifierCategories([]);
    setProductModifiers([]);
    setAddItemVisible(false);
    setStep(0);
  };

  const handleProductNav = (index) => {
    if (editProduct._id) {
      setStep(index);
    }
  };

  const handleProductClick = (value) => {
    setIsShowDescriptionid(
      isShowDescriptionid === value._id ? null : value._id
    );
    handleCloseItem();
    if (!value?.product_details) {
      value.product_details = [
        {
          mrp_price: null,
          sales_price: null,
        },
      ];
    }

    setTimeout(() => {
      setEditProduct(value);
      setAddItemVisible(true);
      setStep(0);
    }, 100);
  };

  const handleNext = async (values, resetForm) => {
    const primary__image = document.getElementById("primary__image");
    const item_name = document.getElementById("item_name");
    // const price = document.getElementById("price");
    const sub_category = document.getElementById("sub_category");
    const description = document.getElementById("description");
    const cancellation_policy = document.getElementById("cancellation_policy");
    const return_policy = document.getElementById("return_policy");

    setTimeout(() => {
      if (primary__image) primary__image.classList.remove("blink-shadow");
      if (item_name) item_name.classList.remove("blink-shadow");
      if (sub_category) sub_category.classList.remove("blink-shadow");
      if (description) description.classList.remove("blink-shadow");
      if (cancellation_policy)
        cancellation_policy.classList.remove("blink-shadow");
      if (return_policy) return_policy.classList.remove("blink-shadow");
    }, 3000);

    if (!values.primary_image) {
      toast("Primary Image is required !!");
      if (primary__image) primary__image.classList.add("blink-shadow");
      return;
    } else if (!values.name) {
      toast("Item name is required !!");
      if (item_name) item_name.classList.add("blink-shadow");
      return;
    } else if (!values.sub_category) {
      toast("Subcategory is required !!");
      if (sub_category) sub_category.classList.add("blink-shadow");
      return;
    } else if (!values.description || values.description === "<p><br></p>") {
      toast("Description is required !!");
      if (description) description.classList.add("blink-shadow");
      return;
    } else if (
      !values.cancellation_policy ||
      values.cancellation_policy === "<p><br></p>"
    ) {
      toast("cancellation is required !!");
      if (cancellation_policy)
        cancellation_policy.classList.add("blink-shadow");
      return;
    } else if (
      !values.return_policy ||
      values.return_policy === "<p><br></p>"
    ) {
      toast("return policy is required !!");
      if (return_policy) return_policy.classList.add("blink-shadow");
      return;
    }

    handleLoading(true);
    if (step === 0) {
      try {
        const resProduct = editProduct._id
          ? await FoodListingApi.updateFoodProduct(values, editProduct._id)
          : await FoodListingApi.createFoodProduct(values);
        setEditProduct(resProduct?.data?.data);
        resetForm();
      } catch (error) {
        console.log(error);
      }
    }
    if (step === 2) {
      handleCloseItem();
      getAllProducts();
      handleLoading(false);
      return;
    }

    handleLoading(false);
    setStep((prevStep) => Math.min(prevStep + 1, 2));
  };

  const handleBack = async () => {
    setStep((prevStep) => Math.max(prevStep - 1, 0));
  };
  const fistStep = async () => {
    setStep((prevStep) => Math.max(prevStep - 2, 0));
  };

  const handleAddMultipleImages = async (e, id = false) => {
    const images = e.target.files;
    const formData = new FormData();

    for (let i = 0; i < images.length; i++) {
      formData.append("image", images[i]);
    }

    !id && formData.append("product", editProduct._id);

    if (images.length) {
      handleLoading(true);
      try {
        id
          ? await foodProductImageApi.updateFoodProductImage(formData, id)
          : await foodProductImageApi.createFoodProductImages(formData);
        getAllProductImages();
      } catch (error) {
        console.error("Error uploading images:", error);
        toast.error(error.message);
      }
      handleLoading(false);
    }
  };

  // const handleDeleteImages = async (id) => {
  //   if (id) {
  //     handleLoading(true);
  //     try {
  //       const res = await productImageApi.deleteProductImage(id);
  //       console.log("res::Image Delete", res.data.data);
  //       getAllProductImages();
  //     } catch (error) {
  //       console.error("Error Deleteing images:", error);
  //     }
  //     handleLoading(false);
  //   }
  // };

  const handleFileUpload = (e, setFieldValue) => {
    const file = e.target.files[0];
    if (file) {
      // Check if file size exceeds 1MB
      const fileSizeInMB = file.size / 1024 / 1024;
      if (fileSizeInMB > 1) {
        setError("Please select an image smaller than 1MB.");
        setFieldValue("primary_image", null);
        return;
      }

      // Clear error and proceed with compression if file size is within limit
      setError("");
      compressImage(file, (ci) => {
        setFieldValue("primary_image", ci);
      });
    }
  };

  const HandleModifierOpen = () => {
    setAddModifier(true);
  };
  const HandleModifierClose = () => {
    setAddModifier(false);
    getAllProductModifier();
  };
  const handleAddUpdateModifier = async (item) => {
    if (item === null || !Boolean(item.modifier.length)) {
      setSelectedModifierCategory(null);
      item !== null && toast.success("category have no Modifiers");
      return;
    }

    if (item !== null && item._id && item.product) {
      const updateModifierData = {
        modifier_mapping_id: item._id,
        product: item.product,
        modifier_category: item.modifier_category,
        name: item?.modifier_category?.name,
        modifier: item.modifier,
        default_selected_modifier: item?.default_selected_modifier || null,
        is_add_quantity: item.is_add_quantity,
        is_modifier_settings: item.is_modifier_settings,
        min: item.min,
        max: item.max,
        sequence_no: item.sequence_no,
        type: item.type,
      };

      setSelectedModifierCategory(updateModifierData);
    } else {
      const addModifierData = {
        modifier_category: { modifier: item.modifier, _id: item._id },
        name: item.name,
      };
      setSelectedModifierCategory(addModifierData);
    }
  };

  const handleSubmitProductModifier = async (values, resetForm) => {
    values.product = editProduct._id;
    values.modifier_category = selectedModifierCategory.modifier_category._id;
    values.default_selected_modifier =
      values.min === 0 ? null : values.default_selected_modifier;

    try {
      if (values.type === "Select Range" && values.max < values.min) {
        toast.error("Max modifier should be greater than Min modifier");
        return;
      } else {
        const resModifier = selectedModifierCategory.modifier_mapping_id
          ? await FoodModifierApi.updateFoodProductModifier(
              values,
              selectedModifierCategory.modifier_mapping_id
            )
          : await FoodModifierApi.createFoodModifer(values);
        getAllProductModifier();
      }
    } catch (error) {
      console.log(error);
    } finally {
      getAllProducts();
      resetForm();
    }
    setSelectedModifierCategory(null);
  };
  const handleSubmitSubcategory = async (values, resetForm) => {
    try {
      // editData
      //   ? await SubCategoryApi.updateSubCategory(values, editData._id)
      await SubCategoryApi.createSubCategory(values);
      toast.success(
        editData._id ? "Subcategory updated" : "Subcategory created"
      );
      getAllSubcategory();
    } catch (err) {
      console.log(err);
      toast.error(err?.message);
    } finally {
      resetForm();
      setEditData("");
      handleClose();
    }
  };
  // const handleSubcategoryDelete = async (item) => {
  //   console.log("item", item);
  //   handleLoading(true);
  //   try {
  //     const deleteRes = await SubCategoryApi.deleteSubCategory(item._id);
  //     getAllSubcategory();
  //   } catch (err) {
  //     console.log(err);
  //     toast.error(err?.message);
  //   } finally {
  //     handleLoading(false);
  //   }
  // };

  // const handleSubcategoryEdit = (item) => {
  //   setEditData(item);
  //   handleOpen();
  // };
  const handleSubmit = async (values, { resetForm }) => {
    handleLoading(true);
    if (step === 2) {
      handleSubmitProductModifier(values, resetForm);
    } else {
      handleSubmitSubcategory(values, resetForm);
    }
    handleLoading(false);
  };

  const handleProductPagination = (data) => {
    console.log("data", data);
    if (data.clearPagination) {
      setProductPagination(data.clearPagination);
      return;
    }

    setProductPagination((prev) => {
      let copyPagination = { ...prev.filter };

      if (data.filter) {
        Object.keys(data?.filter).forEach((key, i) => {
          copyPagination[key] = data?.filter[key];
        });
      }

      return {
        page: data.page ? data.page : 1,
        limit: data.limit ? data.limit : 5,
        sortOrder: data.sortOrder ? data.sortOrder : "desc",
        filter: copyPagination,
      };
    });
  };

  const getAllSubcategory = async () => {
    handleLoading(true);
    try {
      const res = await FoodListingApi.getSubCategory();
      const subcategoriesData =
        res.data?.data.length &&
        res.data?.data.map((o, i) => {
          o.value = o._id;
          o.label = o?.category ? `${o.category.name} > ${o.name}` : o.name;
          return o;
        });

      setSubCategory(subcategoriesData);
    } catch (err) {
      console.log(err);
      toast.error(err?.message);
    } finally {
      handleLoading(false);
    }
  };

  // console.log("subcategory", subCategory);
  const getAllProducts = async () => {
    handleLoading(true);
    try {
      const res = await FoodListingApi.getAllFoodProduct(
        productPagination,
        searchQuery
      );
      setAllProducts(res.data?.data);
    } catch (err) {
      console.log(err);
      toast.error(err?.message);
    } finally {
      handleLoading(false);
    }
  };

  const getProductById = async () => {
    handleLoading(true);
    if (editProduct._id) {
      try {
        const resProduct = await FoodListingApi.getFoodProductById(
          editProduct._id
        );
        setEditProduct(resProduct.data.data);
      } catch (error) {
        console.log(error);
        toast.error(error?.message);
      }
    }
    handleLoading(false);
  };
  const getAllProductImages = async () => {
    handleLoading(true);
    if (editProduct._id) {
      try {
        const resUpload = await foodProductImageApi.getAllFoodProductImages(
          editProduct._id
        );
        setImagesList(resUpload.data.data);
      } catch (error) {
        console.log(error);
        toast.error(error?.message);
      }
    }
    handleLoading(false);
  };
  const getModifierCategory = async () => {
    handleLoading(true);
    try {
      const res = await modifierCategory.getModifierCategory();
      setModifierCategories(res.data.data);
    } catch (error) {
      console.log(error);
      toast.error(error?.message);
    }

    handleLoading(false);
  };
  const getAllProductModifier = async () => {
    handleLoading(true);
    try {
      const res = await FoodModifierApi.getAllFoodProductModifier(
        editProduct._id
      );
      setProductModifiers(res.data.data);
    } catch (error) {
      console.log(error);
      toast.error(error?.message);
    }

    handleLoading(false);
  };

  // const getAllProductVariants = async () => {
  //   handleLoading(true);
  //   try {
  //     const res = await productVariantApi.getAllProductVariants(
  //       editProduct._id
  //     );
  //     // console.log("res::All Product Variants", res);
  //     setAllVariantData(res.data.data);
  //   } catch (error) {
  //     console.log(error);
  //     toast.error(error?.message);
  //   }

  //   handleLoading(false);
  // };

  const getAllTax = async () => {
    handleLoading(true);
    try {
      const res = await masterApi.getAllTax();
      // console.log("setAllTax", res?.data?.data.allTaxMaster);
      let allTaxData = res?.data?.data?.allTaxMaster
        ? res?.data?.data?.allTaxMaster
        : [];
      allTaxData = allTaxData.length
        ? allTaxData.map((t, i) => {
            t.label = `${t.name}@${t.rate.$numberDecimal}%`;
            t.value = t._id;
            return t;
          })
        : [{}];

      setAllTax(allTaxData);
    } catch (error) {
      console.log(error);
      // toast.error(error?.message);
    }

    handleLoading(false);
  };

  // @Get All Data API @end

  const handleSearch = useCallback((s) => {
    setSearchQuery(s);
  }, []);

  const debouncedSearch = useCallback(
    (s) => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        handleSearch(s.target.value);
      }, 1500);
    },
    [handleSearch]
  );

  // const [filteredData,setFilteredData]= useState([])
  //   useMemo(()=>{
  //    const data=allProducts?.products? allProducts?.products.filter((item) => {
  //       return Object.values(item).some(
  //         (objValue) =>
  //           objValue !== null &&
  //           objValue
  //             .toString()
  //             .toLowerCase()
  //             .includes(searchQuery.trim().toLowerCase())
  //       );
  //     }):[];
  //     setFilteredData(data)
  //   },[allProducts])

  const FilterSubcategoryProdct = (item) => {
    if (item) {
      const data = {
        filter: {
          sub_category: item._id,
        },
      };

      handleProductPagination(data);
    } else {
      const clearPagination = {
        page: 1,
        limit: 8,
        sortOrder: "desc",
        filter: {},
      };

      handleProductPagination({ clearPagination });
    }
  };

  useMemo(async () => {
    getProductById();
    getAllProducts();
    if (step === 1) {
      getAllProductImages();
    }
    if (step === 2) {
      getModifierCategory();
      getAllProductModifier();
    }
  }, [step]);

  useEffect(() => {
    getAllProducts();
  }, [productPagination, searchQuery]);

  useEffect(() => {
    getAllSubcategory();
    getAllTax();
  }, []);

  // const data = [];

  // const notMatchedData = data
  //   .map((item) => {
  //     const matchedModifiers = item.modifier;

  //     const unmatchedModifiers = item.modifier_category.modifier.filter(
  //       (modifier) => !matchedModifiers.includes(modifier._id)
  //     );

  //     return {
  //       ...item,
  //       modifier_category: {
  //         ...item.modifier_category,
  //         modifier: unmatchedModifiers,
  //       },
  //     };
  //   })
  //   .filter((item) => item.modifier_category.modifier.length > 0);

  const newModifierCategories = productModifiers?.map(
    (item) => item?.modifier_category
  );

  const filteredDataModifierCategory = modifierCategories.filter(
    (itemA) => !newModifierCategories.some((itemB) => itemB._id === itemA._id)
  );

  // const filteredData = modifierCategories.filter((item) => {
  //   newModifierCategories?.filter((product) => item?._id
  // });

  return (
    <>
      <div className="flex justify-between">
        <div className="lg:w-9/12 p-10">
          <div className="flex justify-between items-center flex-wrap mb-2">
            <BreadCrumb title="Item" />
            <Button
              text="Add New"
              type="button"
              className="float-end"
              onClick={() => setAddItemVisible(true)}
            />
          </div>
          <div className="mb-5 border-b pb-2 flex items-center justify-between">
            <div className="border rounded-full px-3 py-1.5 text-xs bg-transparent w-fit flex justify-between items-center">
              <input
                type="search"
                placeholder="search..."
                // value={searchQuery}
                onChange={debouncedSearch}
                className="bg-transparent outline-none"
              />
              <FaSearch />
            </div>
            <SecondaryButton
              onClick={() => FilterSubcategoryProdct()}
              text={"Clear Filter"}
              type={"submit"}
              padding={"py-2 px-3"}
            />
          </div>
          <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4">
            {allProducts?.products &&
              allProducts?.products.map((item) => (
                <FoodCard
                  data={item}
                  // isShowDescriptionid={isShowDescriptionid}
                  handleProductClick={handleProductClick}
                  // item={item}
                  // key={item.id}
                  openProductDeletModal={openDeleteModal}
                />
              ))}
          </div>
          <div className="flex justify-center items-center">
            <PaginatorCom
              paginatorData={{
                page: allProducts?.page ? allProducts?.page : 1,
                rows: allProducts?.limit ? allProducts?.limit : 5,
                totalPages: allProducts?.totalPages
                  ? allProducts?.totalPages
                  : 1,
                totalCount: allProducts?.totalCount
                  ? allProducts?.totalCount
                  : 1,
              }}
              onPaginatorClick={(e) => {
                if (e) {
                  const data = {
                    limit: e.rows,
                    page: +e.page + 1,
                  };
                  handleProductPagination(data);
                }
              }}
            />
          </div>
        </div>
        <SidebarRight>
          <div className=" pb-2  flex items-center justify-between text-lg font-semibold border-b">
            <h1>Sub Category</h1>
            <div className="mt-5">
              <Button
                cssClass="px-3 py-2"
                type="button"
                text="Add New"
                onClick={handleOpen}
              />
            </div>
          </div>
          <div className="w-full flex flex-col">
            {subCategory?.length > 0
              ? subCategory.map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between py-3 border-b"
                  >
                    <div
                      onClick={() => FilterSubcategoryProdct(item)}
                      className="flex gap-2 cursor-pointer"
                    >
                      <TbPoint
                        style={{ color: item.is_visible ? "green" : "red" }}
                        className="text-xl"
                      />
                      {item.name}
                    </div>
                  </div>
                ))
              : ""}
            {/* <div className="mt-5">
              <Button type="button" text="Add New" onClick={handleOpen} />
            </div> */}
          </div>
        </SidebarRight>
      </div>

      {addItemVisible && (
        <Formik initialValues={initialProductValues} onSubmit={handleSubmit}>
          {({ handleChange, setFieldValue, values }) => (
            <SidebarField
              button1={
                step === 0 ? (
                  ""
                ) : (
                  <Button text="Back" type="button" onClick={handleBack} />
                )
              }
              button0={
                step === 2 && (
                  <Button
                    text="Add Modifier"
                    type="button"
                    disable={
                      !values.isCustomizable && step === 2 ? true : false
                    }
                    onClick={HandleModifierOpen}
                  />
                )
              }
              button2={
                <Button
                  text={step === 2 ? "Submit" : "Next "}
                  type="submit"
                  onClick={() => handleNext(values)}
                />
              }
              handleClose={() => handleCloseItem()}
              title={editProduct?._id ? "Edit Item " : "Add New Item"}
            >
              <div className="flex items-center mx-10">
                {["iteminfo", "images", "Modifier"].map((section, index) => (
                  <React.Fragment key={index}>
                    <div
                      className={`flex flex-col gap-2 items-center w-5 justify-center cursor-pointer`}
                      onClick={() => handleProductNav(index)}
                      // aria-disabled={
                      //   toggle !== "iteminfo" && section !== toggle
                      // }
                      // disabled={toggle !== "iteminfo" && section !== toggle}
                    >
                      <span
                        className={`p-2 border rounded-full ${
                          index === step
                            ? "border-buttonColor"
                            : "border-slate-300"
                        }`}
                      >
                        {section === "iteminfo" && (
                          <FiInfo
                            className={`${
                              index === step ? "" : "text-slate-300"
                            } text-lg`}
                          />
                        )}
                        {section === "images" && (
                          <FaCamera
                            className={`${
                              index === step ? "" : "text-slate-300"
                            } text-lg`}
                          />
                        )}
                        {section === "Modifier" && (
                          <FaRegCircleCheck
                            className={`${
                              index === step ? "" : "text-slate-300"
                            } text-lg`}
                          />
                        )}
                      </span>
                      <span className="font-semibold">
                        {section.charAt(0).toUpperCase() + section.slice(1)}
                      </span>
                    </div>
                    {index < 2 && <div className="border-t w-full"></div>}
                  </React.Fragment>
                ))}
              </div>
              {/* <div className="flex justify-center">
              {step===1 && imagesList.length? (<MdDeleteOutline className="text-3xl text-red-700"/>):""}
              </div> */}
              <Form className="w-full gap-4 flex flex-col bg-transparent py-5 px-3">
                {/* {JSON.stringify(editProduct)} */}
                {step === 0 && (
                  <>
                    <div className="flex items-center justify-center">
                      <label
                        htmlFor="primary_image"
                        className="pt-12 w-52 h-52 pb-3  shadow-2xl text-center rounded-xl flex items-center justify-center gap-3 flex-col cursor-pointer"
                      >
                        <IoAddCircleOutline
                          className="text-5xl text-slate-500 rounded-full"
                          id="primary__image"
                        />
                        <input
                          type="file"
                          id="primary_image"
                          accept="image/*"
                          name="primary_image"
                          onChange={(e) => handleFileUpload(e, setFieldValue)}
                          style={{ display: "none" }}
                          required
                        />
                        {!values.primary_image && (
                          <span>Add Primary Image</span>
                        )}
                        {editProduct._id &&
                        typeof values.primary_image !== "object" ? (
                          <LazyLoadImage
                            src={values.primary_image}
                            alt="img"
                            loading="lazy"
                            effect="blur w-full h-full"
                            style={{
                              width: "100%!important",
                              height: "100% !important",
                              objectFit: "contain",
                            }}
                            className="rounded-xl cursor-pointer"
                          />
                        ) : (
                          <ImagePreview
                            file={values.primary_image}
                            id="primary_image"
                          />
                        )}
                      </label>
                    </div>
                    {error && (
                      <p className="text-red-500 mt-2 text-sm">{error}</p>
                    )}
                    <FormField
                      label="Sub Category"
                      tooltip="Select type of Sub Category"
                    >
                      <div id="sub_category">
                        <Dropdown
                          options={subCategory?.length > 0 ? subCategory : [{}]}
                          name="sub_category"
                          value={values?.sub_category?._id}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="sub_category"
                          component="div"
                          className="error"
                        />
                      </div>
                    </FormField>
                    <FormField label="Item Name" tooltip="Enter product name">
                      <div className="flex items-center" id="item_name">
                        <span className="border p-2 pl-3 pr-5 text-sm border-r-none">
                          EN
                        </span>
                        <TextInput
                          name="name"
                          type="text"
                          placeholder="Item Name"
                          value={values.name}
                          onChange={handleChange}
                          required={true}
                        />
                      </div>
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error"
                      />
                    </FormField>
                    <FormField label="Price" tooltip="Enter Price">
                      <div className="flex items-center" id="item_name">
                        <TextInput
                          name="price"
                          type="number"
                          placeholder="Product Price"
                          value={values.price}
                          onChange={handleChange}
                          required={true}
                        />
                      </div>
                      <ErrorMessage
                        name="price"
                        component="div"
                        className="error"
                      />
                    </FormField>

                    <FormField
                      label="TAX"
                      className="w-[100%]"
                      tooltip="Select type of Tax"
                    >
                      <Dropdown
                        // options={allTax ? allTax : [{}]}
                        options={taxOptions ? taxOptions : [{}]}
                        name="tax"
                        value={values.tax}
                        onChange={handleChange}
                        handleOnchange={(v) => {
                          console.log(v);
                          const d = v.taxes.sort((a, b) =>
                            a.taxType.localeCompare(b.taxType)
                          );
                          console.log(d);
                          setTaxes(d);
                        }}
                      />
                      <ErrorMessage
                        name="tax"
                        component="div"
                        className="error"
                      />
                    </FormField>

                    <FormField
                      label="Description"
                      tooltip="write a brief description of item"
                    >
                      <div id="description">
                        <ReactQuill
                          theme="snow"
                          value={values.description}
                          onChange={(v) => setFieldValue("description", v)}
                        />
                        <ErrorMessage
                          name="description"
                          component="div"
                          className="error"
                        />
                      </div>
                    </FormField>

                    <FormField
                      label="Additional Information"
                      tooltip="write a brief additional information of item"
                    >
                      <div id="additional_info">
                        <ReactQuill
                          theme="snow"
                          value={values.additional_info}
                          onChange={(v) => setFieldValue("additional_info", v)}
                        />
                        <ErrorMessage
                          name="additional_info"
                          component="div"
                          className="error"
                        />
                      </div>
                    </FormField>

                    <FormField
                      label="Cancellation policy"
                      tooltip="write a brief Cancellation policy of item"
                    >
                      <div id="cancellation_policy">
                        <ReactQuill
                          theme="snow"
                          value={values.cancellation_policy}
                          onChange={(v) =>
                            setFieldValue("cancellation_policy", v)
                          }
                        />
                        <ErrorMessage
                          name="cancellation_policy"
                          component="div"
                          className="error"
                        />
                      </div>
                    </FormField>

                    <FormField
                      label="Return policy"
                      tooltip="write a brief return policy of item"
                    >
                      <div id="return_policy">
                        <ReactQuill
                          theme="snow"
                          value={values.return_policy}
                          onChange={(v) => setFieldValue("return_policy", v)}
                        />
                        <ErrorMessage
                          name="return_policy"
                          component="div"
                          className="error"
                        />
                      </div>
                    </FormField>
                    <ToggleField
                      text="Do you want to add any food modifier?"
                      name={"isCustomizable"}
                      value={values.isCustomizable}
                    />
                    <div className="flex justify-between flex-wrap-reverse gap-4"></div>
                  </>
                )}
                {step === 1 && (
                  <div className="flex justify-center  gap-5 flex-wrap">
                    {imagesList.length > 0 &&
                      imagesList.map((imgUrl, index) => (
                        <div
                          key={index}
                          className="w-52 h-52 pb-3   shadow-2xl border  text-center rounded-xl relative"
                          onMouseEnter={() =>
                            (document.getElementById(
                              `deleteProductIcon-${index}`
                            ).style.display = "inline-block")
                          }
                          onMouseLeave={() =>
                            (document.getElementById(
                              `deleteProductIcon-${index}`
                            ).style.display = "none")
                          }
                        >
                          <i
                            id={`deleteProductIcon-${index}`}
                            style={{ display: "none" }}
                            className="absolute text-red-700 top-0 right-0  bg-black rounded-full p-1 "
                          >
                            <MdDeleteOutline
                              onClick={() =>
                                openDeleteModal("product_images", imgUrl)
                              }
                              className="cursor-pointer  text-3xl"
                            />
                          </i>
                          <label htmlFor={`${imgUrl._id}`}>
                            <input
                              type="file"
                              id={`${imgUrl._id}`}
                              accept="image/*"
                              name="images"
                              onChange={(e) =>
                                handleAddMultipleImages(e, imgUrl._id)
                              }
                              style={{ display: "none" }}
                            />
                            <LazyLoadImage
                              src={imgUrl.image}
                              alt={`Item ${index + 1}`}
                              className="rounded-xl cursor-pointer"
                              loading="lazy"
                              effect="blur"
                            />
                          </label>
                        </div>
                      ))}
                    <label
                      htmlFor="image-upload"
                      className="w-52 h-44 shadow-2xl text-center rounded-xl flex items-center justify-center gap-3 flex-col cursor-pointer"
                    >
                      <IoAddCircleOutline className="text-5xl text-slate-500" />

                      <input
                        type="file"
                        id="image-upload"
                        accept="image/*"
                        multiple
                        name="images"
                        onChange={handleAddMultipleImages}
                        style={{ display: "none" }}
                      />
                      <span>Add Images</span>
                    </label>
                  </div>
                )}

                {step === 2 && productModifiers.length ? (
                  values.isCustomizable ? (
                    productModifiers.map((p, i) => (
                      <div
                        key={p._id}
                        className="w-full h-fit p-3 shadow-2xl rounded-lg flex flex-col gap-4"
                      >
                        <div className="flex justify-between items-center">
                          <div className="flex gap-1.5 items-center ">
                            <FaRegCheckCircle className="text-lg text-hoverColor" />
                            <span className="text-[17px]">
                              {i + 1}. {p.modifier_category?.name}
                            </span>
                          </div>
                          <div className="flex gap-2">
                            <SecondaryButton
                              onClick={() => {
                                handleAddUpdateModifier(p);
                              }}
                              text="Edit"
                            />
                            <span
                              onClick={() =>
                                openDeleteModal("modifier_category", p)
                              }
                              className="border cursor-pointer border-errorColor hover:bg-red-700 hover:text-white rounded-full  font-bold px-3 text-[12px]"
                            >
                              Delete
                            </span>
                          </div>
                        </div>
                        <div className="text-sm">
                          {p.modifier_category?.modifier?.length
                            ? p.modifier_category?.modifier?.map(
                                (m, i) =>
                                  p.modifier?.includes(m._id) && (
                                    <React.Fragment key={m._id}>
                                      {" "}
                                      {m.name} |{" "}
                                    </React.Fragment>
                                  )
                              )
                            : ""}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="flex flex-col items-start space-y-4 p-6 bg-gray-50 rounded-lg shadow-md max-w-lg">
                      <p className="text-lg text-gray-700">
                        To add food modifiers, make sure you have turned on the
                        modifier indicator.
                      </p>

                      <div className="flex items-center space-x-2 text-gray-600">
                        <Tooltip text="Go to item info and enable the modifier indicator" />

                        <p className="text-sm">
                          <span className="font-semibold text-gray-800">
                            Note:
                          </span>{" "}
                          You need to enable the modifier indicator.
                        </p>
                      </div>

                      <button
                        className="text-[#e34e4e] underline font-semibold transition duration-300 ease-in-out hover:text-[#c33b3b] focus:outline-none focus:ring-2 focus:ring-[#e34e4e] rounded"
                        onClick={fistStep}
                      >
                        Click here
                      </button>
                    </div>
                  )
                ) : (
                  ""
                )}
              </Form>
            </SidebarField>
          )}
        </Formik>
      )}

      {addModifier && (
        <SidebarField
          button1={""}
          button2={
            <Button
              text="Back "
              type="submit"
              onClick={() => HandleModifierClose()}
            />
          }
          handleClose={() => HandleModifierClose()}
          title="Add New Item"
        >
          <ul>
            {filteredDataModifierCategory.length
              ? filteredDataModifierCategory.map((item, i) => {
                  return (
                    <li
                      key={i}
                      className="p-3 border-t text-buttonColor cursor-pointer"
                      onClick={() => handleAddUpdateModifier(item)}
                    >
                      {item.name}
                    </li>
                  );
                })
              : ""}
          </ul>
        </SidebarField>
      )}

      {selectedModifierCategory != null && (
        <Formik
          initialValues={{
            type: selectedModifierCategory.type || "Select",
            sequence_no: selectedModifierCategory.sequence_no || 0,
            min: selectedModifierCategory.min || 0,
            max: selectedModifierCategory.max || 1,
            is_add_quantity: selectedModifierCategory.is_add_quantity || false,
            is_modifier_settings:
              selectedModifierCategory.is_modifier_settings || false,
            select_will_be_shown_to_users: true,
            default_selected_modifier:
              selectedModifierCategory.default_selected_modifier || null,
            modifier: selectedModifierCategory.modifier || [],
            isCustomizable: selectedModifierCategory.isCustomizable || false,
          }}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, handleChange, values }) => (
            <Form>
              <SidebarField
                button1={
                  <Button
                    text="Back "
                    // type="submit"
                    onClick={() => handleAddUpdateModifier(null)}
                  />
                }
                button2={
                  <Button
                    text={
                      selectedModifierCategory.modifier_mapping_id
                        ? "Update "
                        : "Add"
                    }
                    type="submit"
                  />
                }
                handleClose={() => handleAddUpdateModifier(null)}
                title="Edit item"
              >
                <div className="p-2 flex flex-col gap-4">
                  <h1 className="border-b pb-2 text-lg ">
                    {selectedModifierCategory.name}
                  </h1>
                  <div className="flex justify-between w-full gap-3">
                    <div className="w-full">
                      <div className="flex">
                        <Lable lable={"Modifier Group Type"} />
                        <Tooltip text="select type of modifier group" />
                      </div>
                      <Dropdown
                        name="type"
                        value={values.type}
                        options={[
                          { id: "1", label: "Select", value: "Select" },
                          {
                            id: "2",
                            label: "Select Range",
                            value: "Select Range",
                          },
                        ]}
                        // required={true}
                      />
                    </div>
                    <div className="w-full">
                      <div className="flex">
                        <Lable lable={"Modifier Sequence Number"} />
                        <Tooltip text="Enter a Sequence Number" />
                      </div>
                      <TextInput
                        type="number"
                        value={values.sequence_no}
                        name="sequence_no"
                        placeholder={"0"}
                        // minimumValue={values.sequence>0}
                        required={true}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex justify-between w-full gap-3">
                      <div className="w-full">
                        <div className="flex">
                          <Lable lable={"Min Modifier"} />
                          <Tooltip text="add Minimum Modifier number" />
                        </div>
                        <TextInput
                          type="number"
                          value={values.min}
                          name="min"
                          maximumValue={
                            values.min < values.max ||
                            selectedModifierCategory?.modifier?.length
                          }
                          minimumValue={0}
                          placeholder={"0"}
                          onChange={handleChange}
                        />
                      </div>
                      {values.type === "Select Range" ? (
                        <div className="w-full">
                          <div className="flex">
                            <Lable lable={"Max Modifier"} />
                            <Tooltip text="add Maximum Modifier number" />
                          </div>
                          <TextInput
                            type="number"
                            value={values.max}
                            maximumValue={
                              values?.modifier?.length ||
                              selectedModifierCategory?.modifier?.length
                            }
                            minimumValue={1}
                            required={true}
                            name="max"
                            placeholder={"0"}
                            onChange={handleChange}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* <div>
                    <ToggleField
                      text="Can User Add Modifier Quantity ?"
                      name={"is_add_quantity"}
                      value={values.is_add_quantity}
                    />
                  </div> */}
                  {/* <div className="pb-2 border-b">
                    <ToggleField
                      text="Associate Modifier Settings"
                      name={"is_modifier_settings"}
                      value={values.is_modifier_settings}
                    />
                  </div> */}
                  <div className="pb-2 border-b flex justify-between">
                    <div className="flex">
                      <span>Modifier Selection</span>
                      <Tooltip text="You can Select your Modifier" />
                    </div>
                    {/* <span className="text-red-600 ">Optional</span> */}
                  </div>
                  <div className="flex justify-start gap-4 pb-2 border-b">
                    <div>
                      {/* <ToggleField
                        text="Selected will be shown to users"
                        name={"select_will_be_shown_to_users"}
                      /> */}
                      <span>Selected will be shown to users</span>
                    </div>
                    {/* <div>
                      <ToggleField
                        text="Default Selected"
                        name={"default_select"}
                        value={values?.default_selected_modifier ? false : true}
                      />
                    </div> */}
                  </div>

                  {selectedModifierCategory?.modifier_category?.modifier
                    ?.length > 0
                    ? selectedModifierCategory.modifier_category.modifier.map(
                        (item, i) => {
                          const isChecked = values.modifier.includes(item._id);
                          const isDefault =
                            values?.default_selected_modifier?.[0] === item._id;
                          const canSelectDefault = values.min > 0;

                          return (
                            <div
                              key={item._id}
                              className="flex justify-between items-center"
                            >
                              <div className="flex gap-3 items-center">
                                <input
                                  type="checkbox"
                                  value={item._id}
                                  checked={isChecked}
                                  name={`modifier${i}`}
                                  className="focus:outline-none cursor-pointer"
                                  onChange={(e) => {
                                    const checked = e.target.checked;
                                    const updatedModifiers = checked
                                      ? [...values.modifier, item._id]
                                      : values.modifier.filter(
                                          (mod) => mod !== item._id
                                        );

                                    setFieldValue("modifier", updatedModifiers);
                                  }}
                                  required={values?.modifier?.length < 1}
                                />
                                <span>{item.name}</span>
                              </div>
                              <div className="flex gap-3 items-center">
                                {values?.default_selected_modifier ===
                                  item._id &&
                                  values.min > 0 && (
                                    <Tooltip text="This will be selected by default" />
                                  )}
                                <TextInput
                                  value={item.price?.$numberDecimal}
                                  disabled={true}
                                  placeholder="0"
                                />
                                {canSelectDefault && (
                                  <input
                                    type="radio"
                                    value={item._id}
                                    name="default_selected_modifier"
                                    className="focus:outline-none cursor-pointer"
                                    onChange={() =>
                                      values?.min === 0
                                        ? setFieldValue(
                                            "default_selected_modifier",
                                            null
                                          )
                                        : setFieldValue(
                                            "default_selected_modifier",
                                            item._id
                                          )
                                    }
                                    disabled={!isChecked}
                                  />
                                )}
                              </div>
                            </div>
                          );
                        }
                      )
                    : null}
                </div>
              </SidebarField>
            </Form>
          )}
        </Formik>
      )}

      {editVisible && (
        <SubCategoryForm
          handleSubmit={handleSubmit}
          handleClose={() => handleClose()}
          initialValues={{
            name: editData.name || "",
            sequence: editData.sequence || "",
            category: editData.category || "",
            is_visible: editData.is_visible || false,
            categoryType: "Food",
          }}
        />
      )}
      <DeleteFieldModal
        isOpen={deleteModal}
        onClose={handleDeleteClose}
        onDelete={() => handleDelete()}
      />
    </>
  );
};

export const SubCategoryForm = ({
  initialValues,
  handleSubmit,
  handleClose,
}) => {
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const { handleLoading } = useContext(LoaderContext);

  const fetchingCategoryItems = async () => {
    handleLoading(true);
    try {
      const res = await CategoryApi.getAllCategory();

      setCategoriesOptions(res.data?.data?.categories);
    } catch (err) {
      console.log(err);
    } finally {
      handleLoading(false);
    }
  };

  const categoryOptions = categoriesOptions.map((cat) => {
    return {
      value: cat._id,
      label: cat.name,
    };
  });

  useEffect(() => {
    fetchingCategoryItems();
  }, []);
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ handleChange, values }) => (
        <Form>
          <SidebarField
            button1={
              <Button text="Cancel" type="button" onClick={handleClose} />
            }
            button2={<Button text="Submit" type="submit" />}
            handleClose={handleClose}
            title={values.name ? "Edit Sub Category" : "Add New Sub Category"}
          >
            <div className="w-full gap-4 flex flex-col bg-transparent py-5 px-3">
              <FormField label="Sub Category Name" tooltip="Enter subcategory">
                <TextInput
                  name="name"
                  type="text"
                  placeholder="Sub Category Name"
                  onChange={handleChange}
                  required={true}
                  value={values.name}
                />
                <ErrorMessage name="name" component="div" className="error" />
              </FormField>

              <FormField label="Category" tooltip="Choose or create a category">
                <div className="flex items-center gap-2">
                  <Dropdown
                    options={categoryOptions}
                    name="category"
                    value={values.category}
                    placeholder="Choose your category"
                  />
                </div>
                <ErrorMessage
                  name="category"
                  component="div"
                  className="error"
                />
              </FormField>

              <FormField
                label="Sequence Number"
                tooltip="Enter sequence number to sort the subcategory"
              >
                <TextInput
                  name="sequence"
                  type="number"
                  placeholder="Sequence Number"
                  onChange={handleChange}
                  required={true}
                  value={values.sequence}
                />
                <ErrorMessage name="number" component="div" className="error" />
              </FormField>
              <ToggleField
                text="Is subcategory visible to user?"
                name="is_visible"
                onChange={handleChange}
                value={values.is_visible}
              />
            </div>
          </SidebarField>
        </Form>
      )}
    </Formik>
  );
};

export default ItemFoodList;
