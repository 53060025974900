import React, { useEffect, useState } from "react";
import { GoDotFill } from "react-icons/go";
import { MdDeleteOutline, MdEdit } from "react-icons/md";
import Lable from "../../components/Lable";
import Tooltip from "../../components/Tooltip/Tooltip";
import ToggleField from "../../components/ToggleField";
import { ErrorMessage, Form, Formik,useFormik } from "formik";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import SidebarField from "../../components/Sidebar/SidebarField";
import { FormField } from "../../components/FormField";
import ColorPicker from "../../components/ColorPicker";
import { IoMdAddCircleOutline } from "react-icons/io";
import { toast } from "react-toastify";
import { IoAddCircleOutline } from "react-icons/io5";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactQuill from "react-quill";
import ImagePreview from "../../components/ImagePreview";
import { productVariantSchema } from "../../validationSchema/ValidationSchema";
import { compressImage } from "../../custom/compressImage";
import { Divider } from "primereact/divider";
import TaxData from "./TaxData";

function Variant({
  isOpen,
  editVariantData,
  editProduct,
  handleVariantClose,
  handleSubmitVariant,
  taxDetails,
  DeliveryTypes
}) {
  const [isVariant, setIsVariant] = useState(false);
  const [variantSpecifications, setVariantSpecifications] = useState(null);
  const [productDetailsWithTax, setProductDetailsWithTax] = useState([]);
  const [is_color, setColorPicker] = useState(false);
 

  const closeVariantSpecifications = async () => {
    setIsVariant(false);
    setVariantSpecifications(null);
  };

  const handleAddVariant = async () => {
    setIsVariant(true);
  };

  console.log("editVariantData", editVariantData);

  const handleMultiImageUpload = (imgs, setFieldValue) => {
    console.log("imgs", imgs);
    if (editVariantData?.secondary_image) {
    }
    // setFieldValue("secondary_image",imgs)
    const multiImg = [];
    imgs.forEach((img, i) => {
      if (typeof img === "object") {
        compressImage(img, (ci) => {
          multiImg.push(ci);
        });
      } else {
        multiImg.push(img);
      }
      console.log("multiImg", multiImg);
    });

    setFieldValue("secondary_image", multiImg);
  };

  const handleSubmit = async (data) => {
    console.log("productDetailsWithTax", JSON.stringify(productDetailsWithTax));
    data.product_details=productDetailsWithTax
    console.log("data", data);

    handleSubmitVariant && handleSubmitVariant(data);
  };

  const handleFileUpload = (e, setFieldValue) => {
    const f = e.target.files[0];

    compressImage(f, (ci) => {
      setFieldValue("primary_image", ci);
    });
  };

  const product_details_info = {
    product_size: "Enter a size of product eg. 120GB, S/M/L/XL/XXL, 1 liter!",
    mrp_price: "Enter a MRP Price of product!",
    sales_price: "Enter a Sales Price of product!",
    stock: "Enter a number of stock!",
    product_width: "Enter a Width in of product in centimetre!",
    product_height: "Enter a Height of product in centimetre !",
    product_depth: "Enter a Depth of product in centimetre!",
    product_weight: "Enter a Weight of product in gram!",
  };

  const TaxCalculation = (data = false,setFieldValue) => {
    // console.log('DeliveryTypes',DeliveryTypes);
    // console.log('taxDetails',taxDetails);
    // console.log('editData',editProduct);
    // console.log('productDetailsWithTaxCopy',productDetailsWithTaxCopy);
    const productDetailsWithTaxCopy = data ? data : [...productDetailsWithTax];

    const deliveryTypeCopy = DeliveryTypes ? { ...DeliveryTypes } : null;
    const TaxesCopy = [...taxDetails] 

    if (productDetailsWithTaxCopy?.length && TaxesCopy?.length) {
      productDetailsWithTaxCopy.forEach((item, index) => {
        if (item.sales_price) {
          item.mrp_price = item?.mrp_price?.$numberDecimal
            ? item?.mrp_price?.$numberDecimal
            : item.mrp_price;
          item.sales_price = item?.sales_price?.$numberDecimal
            ? item?.sales_price?.$numberDecimal
            : item.sales_price;

          const standardTax = [];
          const standardTotalTaxAmount = TaxesCopy.filter(
            (t) => t.taxType !== "TAX"
          ) // Exclude items where taxType is "TAX"
            .reduce((sum, t) => {
              // console.log("Sum",sum);
              // console.log("TTTTT",t);

              const rate = parseFloat(t.rate.$numberDecimal);
              //console.log("rate", rate);
              const salesPrice = parseFloat(item.sales_price);
              const taxAmount = (rate * salesPrice) / 100;
              t.taxAmount = parseFloat(taxAmount).toFixed(2);
              // console.log("taxAmount",taxAmount);

              const data = {
                name: t.name,
                rate: parseFloat(rate),
                taxType: "REBASE TAX",
                taxAmount: taxAmount,
              };
              standardTax.push(data);
              return sum + taxAmount;
            }, 0);

          const additionalFee = deliveryTypeCopy?.additionalFee;
          const closingFee = deliveryTypeCopy?.closingFeeRate;
          const rebase = parseFloat(
            parseFloat(standardTotalTaxAmount) + parseFloat(item?.sales_price)
          ).toFixed(2);
          const referralFee =
            (parseFloat(rebase) *
              parseFloat(deliveryTypeCopy?.referralFeeRate)) /
            100;
          const taxableAmount =
            parseFloat(rebase) +
            parseFloat(referralFee) +
            parseFloat(closingFee) +
            parseFloat(additionalFee);

          const Vat = TaxesCopy.filter((t) => t.taxType === "TAX").map((d) => {
            // d.rate=parseFloat(d.rate.$numberDecimal);

            d.taxAmount = parseFloat(
              (parseFloat(taxableAmount) *
                parseFloat(d?.rate?.$numberDecimal)) /
                100
            ).toFixed(2);
            const data = {
              name: d?.name,
              rate: parseFloat(d?.rate?.$numberDecimal),
              taxType: "REBASE TAX",
              taxAmount: parseFloat(
                (parseFloat(taxableAmount) *
                  parseFloat(d?.rate?.$numberDecimal)) /
                  100
              ).toFixed(2),
            };
            // standardTax.push(d)
            return data;
          });
          const data = {
            standardTax: standardTax,
            standardTotalTaxAmount: parseFloat(standardTotalTaxAmount).toFixed(
              2
            ),
            referralFee: {
              percentage: deliveryTypeCopy?.referralFeeRate,
              amount: parseFloat(referralFee).toFixed(2),
            },
            closingFee: parseFloat(closingFee).toFixed(2),
            additionalFee: parseFloat(additionalFee).toFixed(2),
            rebase: parseFloat(rebase).toFixed(2),
            taxableAmount: parseFloat(taxableAmount).toFixed(2),
            vat: Vat,
            grandTotal: parseFloat(
              taxableAmount + parseFloat(Vat[0]?.taxAmount)
            ).toFixed(2),
          };
          item.taxData = data;
          productDetailsWithTaxCopy[index] = item;
          setProductDetailsWithTax(productDetailsWithTaxCopy);
          setFieldValue && setFieldValue('taxData',productDetailsWithTaxCopy)
          return productDetailsWithTaxCopy;
        }
      });
    }
  };

  

  useEffect(()=>{
    
    if (isOpen) {
      editVariantData&& TaxCalculation(editVariantData?.product_details)
      
    }
    else{
      setProductDetailsWithTax([]);
    }
  },[editVariantData,isOpen])

  console.log('productDetailsWithTax',productDetailsWithTax);
  console.log('  taxDetails,', taxDetails );
  console.log('  DeliveryTypes,', DeliveryTypes );
  console.log('  editProduct,', editProduct );
  

  if (isOpen) {
    return (
      <div>
        (
        <Formik
          initialValues={{
            primary_image: editVariantData?.primary_image || null,
            secondary_image: editVariantData?.secondary_image || null,
            name: editVariantData?.name,
            serial_number: editVariantData?.serial_number || null,
            is_visible: editVariantData?.is_visible || false,
            // product_size: editVariantData?.product_size[0] || null,
            product_color: editVariantData?.product_color[0] || "",
            product_details:
              editVariantData === null ||
              editVariantData?.product_details?.length === 0
                ? [
                    {
                      product_size: null,
                      mrp_price: null,
                      sales_price: null,
                      stock: null,
                      product_width: null,
                      product_height: null,
                      product_depth: 0,
                      product_weight: null,
                    },
                  ]
                : editVariantData?.product_details?.map((d) => {
                    d.mrp_price = d.mrp_price?.$numberDecimal || d.mrp_price;
                    d.sales_price =
                      d.sales_price?.$numberDecimal || d.sales_price;
                    return d;
                  }),
            description: editVariantData?.description || "",
            additional_info: editVariantData?.additional_info || "",
            specification: editVariantData?.specification || "",
            // mrp_price: editVariantData?.mrp_price?.$numberDecimal || null,
            // sales_price: editVariantData?.sales_price?.$numberDecimal || null,
            // stock: editVariantData?.stock || null,
          }}
          validationSchema={productVariantSchema}
          onSubmit={handleSubmit}
        >
          {({ handleChange, setFieldValue, values, handleSubmit }) => (
            <SidebarField
              button1={
                <Button
                  text="Back"
                  type="button"
                  onClick={handleVariantClose}
                />
              }
              button2={
                <Button text="Save" type="submit" onClick={handleSubmit} />
              }
              handleClose={handleVariantClose}
              title="Add variant Name"
            >
              <Form className="w-full gap-4 flex flex-col bg-transparent py-5 px-3">
                <FormField label="Primary Image" tooltip="Choose Primary Image">
                  <div className="flex flex-col items-center justify-center">
                    <label
                      htmlFor="primary_image"
                      className="w-52 h-52 pb-3  shadow-2xl text-center rounded-xl flex items-center justify-center gap-3 flex-col cursor-pointer"
                    >
                      <IoAddCircleOutline
                        className="text-5xl text-slate-500 rounded-full"
                        id="primary__image"
                      />
                      <input
                        type="file"
                        id="primary_image"
                        accept="image/*"
                        name="primary_image"
                        onChange={(e) => handleFileUpload(e, setFieldValue)}
                        style={{ display: "none" }}
                        required
                      />
                      {values.primary_image ? (
                        ""
                      ) : (
                        <span>Add Primary Images</span>
                      )}
                      {editVariantData?._id &&
                      typeof values.primary_image !== "object" ? (
                        <LazyLoadImage
                          src={values.primary_image}
                          alt="img"
                          loading="lazy"
                          effect="blur w-full h-full"
                          style={{
                            width: "100%!important",
                            height: "100% !important",
                            objectFit: "contain",
                          }}
                          className="rounded-xl cursor-pointer"
                        />
                      ) : (
                        <ImagePreview
                          file={values.primary_image}
                          id="primary_image"
                        />
                      )}
                    </label>
                  </div>
                  <ErrorMessage
                    name="primary_image"
                    component="div"
                    className="blink-shadow error px-4 py-1"
                  />
                </FormField>
                <FormField
                  label="Secondary image"
                  tooltip="Pick atleast 3 images"
                >
                  <VariantImages
                    imagesData={
                      values.secondary_image !== null
                        ? values.secondary_image
                        : []
                    }
                    handleMultiImageUpload={(imgs) =>
                      handleMultiImageUpload(imgs, setFieldValue)
                    }
                  />
                  <ErrorMessage
                    name="secondary_image"
                    component="div"
                    className="blink-shadow error px-4 py-1"
                  />
                </FormField>
                <div>
                  <div className="flex">
                    <Lable lable="variant Name" />
                    <Tooltip text="Enter a name for Variant" />
                  </div>
                  <TextInput
                    name="name"
                    type="text"
                    placeholder="variant Name"
                    onChange={handleChange}
                    value={values.name}
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="blink-shadow error px-4 py-1"
                  />
                </div>
                {/* <FormField label="Serial number" tooltip="Enter serial number">
                  <div className="flex items-center">
                    <span className="border p-2 pl-3 pr-5 text-sm border-r-none">
                      EN
                    </span>
                    <TextInput
                      name="serial_number"
                      type="text"
                      placeholder="Item serial number"
                      value={values.serial_number}
                      onChange={handleChange}
                    />
                  </div>
                  <ErrorMessage
                    name="serial_number"
                    component="div"
                    className="blink-shadow error px-4 py-1"
                  />
                </FormField> */}

                {/* <FormField
                  label="Variant Product size"
                  className="grow"
                  tooltip="Enter only single size"
                >
                  <div className="flex items-center">
                    <TextInput
                      name="product_size"
                      type="text"
                      placeholder="Variant product size"
                      value={values.product_size}
                      onChange={handleChange}
                    />
                  </div>
                  <ErrorMessage
                    name="product_size"
                    component="div"
                    className="blink-shadow error px-4 py-1"
                  />
                </FormField>


                <div className="my-2">
                    <div className="flex">
                      <Lable lable="MRP Price" />
                      <Tooltip text="Enter MRP a price" />
                    </div>
                    <TextInput
                      name="mrp_price"
                      type="text"
                      placeholder="MRP Price"
                      onChange={handleChange}
                      value={values.mrp_price}
                    />
                    <ErrorMessage
                      name="mrp_price"
                      component="div"
                      className="blink-shadow error px-4 py-1"
                    />
                  </div>
                  <div className="my-2">
                    <div className="flex">
                      <Lable lable="Sales Price" />
                      <Tooltip text="Enter a sales price" />
                    </div>
                    <TextInput
                      name="sales_price"
                      type="text"
                      placeholder="Sales Price"
                      onChange={handleChange}
                      value={values.sales_price}
                    />
                    <ErrorMessage
                      name="sales_price"
                      component="div"
                      className="blink-shadow error px-4 py-1"
                    />
                  </div>
                  <div className="my-2">
                    <div className="flex">
                      <Lable lable="Stock" />
                      <Tooltip text="Enter a quantity of variant" />
                    </div>
                    <TextInput
                      name="stock"
                      type="text"
                      placeholder="Stock"
                      onChange={handleChange}
                      value={values.stock}
                    />
                    <ErrorMessage
                      name="stock"
                      component="div"
                      className="blink-shadow error px-4 py-1"
                    />
                  </div>
                  <div>
                    <ToggleField
                      text="Is variant visible to user?"
                      name="is_visible"
                      onChange={handleChange}
                      value={values.is_visible}
                    />
                  </div> */}
                <div id="is_color">
                  <ToggleField
                    text="Color?"
                    name="is_color"
                    value={is_color}
                    toggaleHandleChange={() => {
                      setColorPicker(!is_color);
                      setFieldValue("product_color", is_color ? "#fff" : "");
                    }}
                  />
                  <ErrorMessage
                    name="is_color"
                    component="div"
                    className="error"
                  />
                </div>
                {is_color ? (
                  <FormField label="Product color" tooltip="Pick Product Color">
                    <div
                      className="flex items-center  relative"
                      id="product_color"
                    >
                      <label
                        style={{ background: values.product_color }}
                        htmlFor="product_color"
                        className={`overflow-hidden  border-2 border-gray-950  w-full h-[2rem] inline-block`}
                      >
                        <input
                          id="product_color"
                          name="product_color"
                          type="color"
                          placeholder="color"
                          value={values.product_color}
                          onChange={handleChange}
                          required={true}
                          style={{
                            width: "100%",
                            height: "6rem",
                            marginLeft: "-12px",
                            marginTop: "-13px",
                            border: "none",
                            outline: "none",
                            borderRadius: "100%",
                            background: "transparent",
                          }}
                        />
                      </label>
                    </div>
                  </FormField>
                ) : (
                  ""
                )}

                <div>
                  <div className="flex items-center justify-between mb-2">
                    {/* <span>
                      {JSON.stringify(values.product_details)}
                    </span> */}
                    <span className="flex">
                      <Lable lable={"Variants Details"} />
                      <Tooltip text="add more details of Variant" />
                    </span>
                    <div className="flex gap-1">
                      <IoMdAddCircleOutline
                        onClick={() => {
                          if (
                            values.product_details[
                              values.product_details.length - 1
                            ]["product_size"] !== null
                          ) {
                            const variantDetails = values.product_details;
                            variantDetails.push({
                              product_size: null,
                              mrp_price: null,
                              sales_price: null,
                              stock: null,
                              product_width: null,
                              product_height: null,
                              product_depth: 0,
                              product_weight: null,
                            });
                            setFieldValue("product_details", variantDetails);
                          } else {
                            toast.warn(
                              "provide variant deatil in previous field!"
                            );
                          }
                        }}
                        className="text-[1.5rem] cursor-pointer"
                      />
                      <MdDeleteOutline
                        onClick={() => {
                          if (values.product_details.length - 1 > 0) {
                            const variantDetails = values.product_details;
                            variantDetails.pop();
                            setFieldValue("product_details", variantDetails);
                          } else {
                            toast.warn(
                              "Atleast one Product Size, MRP Price, Sales Price and Stock must be required!"
                            );
                          }
                        }}
                        className="text-[1.5rem] cursor-pointer"
                      />
                    </div>
                  </div>

                  <div className=" w-full">
                    {/* {JSON.stringify(values.product_details)} */}
                    {values.product_details.map((item, i) => (
                      <>
                        {Object.keys(item).map((label, index) => (
                          <>
                          <div
                            key={`${i}${index}`}
                            className={`border ${
                              label === "product_size" ||
                              label === "mrp_price" ||
                              label === "sales_price" ||
                              label === "stock" ||
                              label === "product_width" ||
                              label === "product_width" ||
                              label === "product_height" ||
                              label === "product_weight" ||
                              label === "product_depth"
                                ? "flex"
                                : "hidden"
                            }  justify-between mb-1 items-center text-[12px]`}
                          >
                            <span className="w-5 border-r px-2 py-2  gap-2 capitalize  flex justify-between items-center">
                              <label className="text-[.85rem]">
                                { label === "mrp_price" ?'MRP Price':label.replace("_", " ")}
                              </label>{" "}
                              <Tooltip text={product_details_info[label]} />
                            </span>
                            <TextInput
                              name={`${label}`}
                              type={
                                label === "product_size" ? "text" : "number"
                              }
                              minimumValue={0}
                              placeholder={`${label === "mrp_price" ?'MRP Price':label.replace("_", " ")}`}
                              onChange={(e) => {
                                if (e.target.value <0 && label !== "product_size") {   
                                  e.target.value = null;
                                  alert("Value can't be negative!" )
                                  return
                                }                                
                                console.log('evalue===>',e.target.value);                                                                     
                                let variant = [...values.product_details];
                                variant[i][label] =e.target.value 
                                TaxCalculation(variant,setFieldValue)
                                setFieldValue("product_details", variant);                           
                              }}
                              
                              value={
                                label === "mrp_price" || label === "sales_price"
                                  ? values.product_details[i][`${label}`]
                                      ?.$numberDecimal ||
                                    values.product_details[i][`${label}`]
                                  : values.product_details[i][`${label}`]
                              }
                              required={true}
                              className={`capitalize`}
                            />
                          </div>
                           </>
                        ))}
                        <Divider />
                             <div>
                             <h1>Tax Details</h1>
                             {/* {JSON.stringify(productDetailsWithTax)} */}
                            <TaxData key={i} item={productDetailsWithTax[i]} />
                           </div>
                        <ErrorMessage
                          //  name={`${label}`}
                          name="product_details"
                          component="div"
                          className="blink-shadow error px-4 py-1"
                          children={(item) =>
                            item.map(
                              (err) =>
                                err && (
                                  <div className="blink-shadow error px-4 py-1 my-2">
                                    {Object.keys(err).map((e) => err[e] + ", ")}{" "}
                                    must be required
                                  </div>
                                )
                            )
                          }
                        />
                      </>
                    ))}
                  </div>

                  <div className="flex flex-col gap-2">
                    <FormField
                      label="Description"
                      tooltip="write a brief description of variant"
                    >
                      <ReactQuill
                        theme="snow"
                        value={values.description}
                        onChange={(v) => setFieldValue("description", v)}
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="blink-shadow error px-4 py-1"
                      />
                    </FormField>

                    <FormField
                      label="Additional Information"
                      tooltip="write a brief additional information of variant"
                    >
                      <ReactQuill
                        theme="snow"
                        value={values.additional_info}
                        onChange={(v) => setFieldValue("additional_info", v)}
                      />
                      <ErrorMessage
                        name="additional_info"
                        component="div"
                        className="blink-shadow error px-4 py-1"
                      />
                    </FormField>

                    <FormField
                      label="Specifications"
                      tooltip="write a brief Specifications of variant"
                    >
                      <ReactQuill
                        theme="snow"
                        value={values.specification}
                        onChange={(v) => setFieldValue("specification", v)}
                      />
                      <ErrorMessage
                        name="specification"
                        component="div"
                        className="blink-shadow error px-4 py-1"
                      />
                    </FormField>
                  </div>
                </div>
              </Form>
            </SidebarField>
          )}
        </Formik>
        )
        {/* <VarientSpecifications open={isVariant} close={closeVariantSpecifications} editVarientData={variantSpecifications} /> */}
      </div>
    );
  }
}

export default Variant;

// const VarientSpecifications = ({ open, close, editVarientData }) => {
//   const handleSubmit = async () => {};
//   if (open) {
//     return (
//       <Formik
//         initialValues={{
//           name: editVarientData?.name || "",
//           price: editVarientData?.price?.$numberDecimal || "",
//           sequence: editVarientData?.sequence || "",
//           is_visible: editVarientData?.is_visible || false,
//         }}
//         onSubmit={handleSubmit}
//       >
//         {({ handleChange, values, handleSubmit }) => (
//           <SidebarField
//             button1={<Button text="Back" type="button" onClick={close} />}
//             button2={<Button text="Save" onClick={handleSubmit} />}
//             handleClose={close}
//             title="Add New Variant Details"
//           >
//             <Form className="w-full gap-4 flex flex-col bg-transparent py-5 px-3">
//               <div>
//                 <Lable lable="Variant Specification" />
//                 <TextInput
//                   name="name"
//                   type="text"
//                   placeholder="Variant Specification"
//                   onChange={handleChange}
//                   value={values.name}
//                 />
//                 <ErrorMessage name="name" component="div" className="blink-shadow error px-4 py-1" />
//               </div>
//               <div>
//                 <Lable lable="Price" />
//                 <TextInput
//                   name="price"
//                   type="text"
//                   placeholder="Price"
//                   onChange={handleChange}
//                   value={values.price}
//                 />
//                 <ErrorMessage name="price" component="div" className="blink-shadow error px-4 py-1" />
//               </div>
//               <div>
//                 <Lable lable="Sequence Number" />
//                 <TextInput
//                   name="sequence"
//                   type="text"
//                   placeholder="Sequence Number"
//                   onChange={handleChange}
//                   value={values.sequence}
//                 />
//                 <ErrorMessage
//                   name="sequence"
//                   component="div"
//                   className="blink-shadow error px-4 py-1"
//                 />
//               </div>
//               <div>
//                 <ToggleField
//                   text="Is modifier visible to user ?"
//                   name="is_visible"
//                   onChange={handleChange}
//                   value={values.is_visible}
//                 />
//               </div>
//             </Form>
//           </SidebarField>
//         )}
//       </Formik>
//     );
//   }
// };

const VariantImages = ({ imagesData, handleMultiImageUpload }) => {
  // console.log("imagesData", imagesData);

  const imagesList = imagesData
    ? typeof imagesData === "object"
      ? Object.values(imagesData)
      : imagesData
    : [];

  const handleAddMultipleImages = async (e, id = false) => {
    console.log("event", e);
    console.log("ID", id);
    console.log("imagesList", imagesList);

    const images = e.target.files;
    if (id !== false && id >= 0) {
      imagesList[id] = images[0];
      console.log("imagesList", imagesList);
      handleMultiImageUpload && handleMultiImageUpload(imagesList);
    } else {
      handleMultiImageUpload &&
        handleMultiImageUpload([...imagesList, ...images]);
      // handleMultiImageUpload && handleMultiImageUpload(images)
    }
  };
  return (
    <div className="flex justify-center  gap-5 flex-wrap my-2">
      {imagesList.length > 0 &&
        imagesList.map((imgUrl, index) => (
          <div
            key={index}
            className="w-52 h-52 overflow-hidden shadow-2xl border  text-center rounded-xl relative"
            // onMouseEnter={() =>
            //   (document.getElementById(
            //     `deleteProductIcon-${index}`
            //   ).style.display = "inline-block")
            // }
            // onMouseLeave={() =>
            //   (document.getElementById(
            //     `deleteProductIcon-${index}`
            //   ).style.display = "none")
            // }
          >
            {/* <i
              id={`deleteProductIcon-${index}`}
              style={{ display: "none" }}
              className="absolute text-red-700 top-0 right-0  bg-black rounded-full p-1 "
            >
              <MdDeleteOutline
                // onClick={() =>
                //   openDeleteModal("product_images", imgUrl)
                // }
                className="cursor-pointer  text-3xl"
              />
            </i> */}
            <label htmlFor={`variant-${index}`}>
              <input
                type="file"
                id={`variant-${index}`}
                accept="image/*"
                name="images"
                onChange={(e) => handleAddMultipleImages(e, index)}
                style={{ display: "none" }}
              />
              {/* <LazyLoadImage
                src={imgUrl.image}
                alt={`Item ${index + 1}`}
                className="object-cover rounded-xl cursor-pointer"
                loading="lazy"
                effect="blur"                
              /> */}

              {typeof imgUrl === "object" ? (
                <ImagePreview file={imgUrl} id="variant_image" />
              ) : (
                <LazyLoadImage
                  src={imgUrl}
                  alt="variant_image"
                  loading="lazy"
                  effect="blur"
                  className="w-[100%] h-[100%] object-contain rounded-xl cursor-pointer"
                />
              )}
            </label>
          </div>
        ))}
      <label
        htmlFor="image-upload"
        className="w-52 h-44 shadow-2xl text-center rounded-xl flex items-center justify-center gap-3 flex-col cursor-pointer"
      >
        <IoAddCircleOutline className="text-5xl text-slate-500" />

        <input
          type="file"
          id="image-upload"
          accept="image/*"
          multiple
          name="images"
          onChange={handleAddMultipleImages}
          style={{ display: "none" }}
        />
        <span>Add More Images</span>
      </label>
    </div>
  );
};
