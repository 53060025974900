// import React, {
//   createContext,
//   useCallback,
//   useContext,
//   useEffect,
//   useMemo,
//   useState,
// } from "react";

// import { io } from "socket.io-client";
// import { chatApi } from "../../apis/Chat.api";
// import { useSelector } from "react-redux";


// const SocketContext = createContext(null);

// export const useSocket = () => {
//   return useContext(SocketContext);
// };

// const SocketProvider = ({ children }) => {
//   const [storedSocketId, setStoredSocketId] = useState(() =>
//     typeof window !== "undefined" ? localStorage.getItem("socketId") : null
//   );
//   const [status, setStatus] = useState([]);
//   const [allMessages, setAllMessages] = useState([]);
//   const [message, setMessage] = useState("");
//   const [users, setUsers] = useState([]);
//   const user = useSelector((state) => state.store.currentUser);
//   console.log('users',user);
  
//   const userId = user?._id;

//   const fetchAllUsersStatus = useCallback(async () => {
//     try {
//       const res = await chatApi.getAllUserStatus();
//       setStatus(res.status);
//     } catch (error) {
//       console.error("Error fetching messages:", error);
//     }
//   }, []);

//   const handleMessage = async () => {
//     const senderSocketId = localStorage.getItem("socket_id");
//     const sender = user;
//     const senderId = userId;

//     const receiverId ='674070ab21403314d13d3a71' ;
//     const receiverSocketId =null ;

//     const newMessage = {
//       senderSocketId,
//       senderId,
//       receiverSocketId,
//       receiverId,
//       message,
//     };

//     try {
//       /*if (file?.length > 0 && !message) {
//         setIsLoading(true);
//         setFiles([]);
//         try {
//           const newFileData = await uploadFiles(file, senderId, receiverId);
//           newFileData.forEach((fileData) => {
//             socket.emit("file-upload", fileData);
//           });
//         } catch (err) {
//           console.log(err);
//         } finally {
//           setIsLoading(false);
//           setPreview([]);
//         }
//       }

//       if (message && file?.length <= 0) {
//         socket.emit("message", newMessage);
//         setMessage("");
//       }*/

//       if (message) {
//         socket.emit("message", newMessage);
//         setMessage("");
//       }

//       // if (file?.length > 0 && message) {
//       //   setIsLoading(true);
//       //   socket.emit("message", newMessage);
//       //   setMessage("");
//       //   setFiles([]);

//       //   try {
//       //     const newFileData = await uploadFiles(file, senderId, receiverId);
//       //     newFileData.forEach((fileData) => {
//       //       socket.emit("file-upload", fileData);
//       //     });
//       //   } catch (err) {
//       //     console.log(err);
//       //   } finally {
//       //     setPreview([]);
//       //     setIsLoading(false);
//       //   }
//       // }
//     } catch (error) {
//       console.error("Error handling message:", error);
//     }
//   };

//   const fetchAllUsers = useCallback(async () => {
//     try {
//       const res = await chatApi.getAllUsers();
//       setUsers(res.data);
//     } catch (error) {
//       console.error("Error fetching messages:", error);
//     }
//   }, [setUsers]);

//   const socket = useMemo(() => {
//     const options = storedSocketId
//       ? { query: { socketId: storedSocketId } }
//       : {};
//     return io("http://192.168.29.155:5000", options);
//   }, [storedSocketId]);

//   useEffect(() => {
//     if (!storedSocketId) {
//       socket.on("connect", () => {
//         console.log("socket connected", socket);
//         const newSocketId = socket.id;
//         localStorage.setItem("socketId", newSocketId);
//         setStoredSocketId(newSocketId);
//       });
//     }

//     socket.emit("joined", { user, storedSocketId, userId });

//     // return () => {
//     //   socket.off();
//     //   socket.disconnect();
//     // };
//   }, [socket, storedSocketId]);

//   useEffect(() => {
//     fetchAllUsers();
//     fetchAllUsersStatus();
//   }, [fetchAllUsers, fetchAllUsersStatus]);

//   useEffect(() => {
//     socket.on("user-connected", () => {
//       fetchAllUsersStatus();
//     });
//     socket.on("user-disconnected", () => {
//       fetchAllUsersStatus();
//     });

//     // return () => {
//     //   socket.off("user-connected");
//     //   socket.off("user-disconnected");
//     // };
//   }, [socket, fetchAllUsersStatus]);

//   // This useEffect actually receive the messages through the socket
//   useEffect(() => {
//     socket.on("receive-message", () => {
//       socket.on("get-all-messages", (msg) => {
//         setAllMessages(msg);
//       });
//     });
//   }, [setAllMessages, socket]);

//   return (
//     <SocketContext.Provider
//       value={{
//         socket,
//         message,
//         setMessage,
//         handleMessage,
//       }}
//     >
//       {children}
//     </SocketContext.Provider>
//   );
// };

// export default SocketProvider;


import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";

const SocketContext = createContext(null);

export const useSocket = () => {
  return useContext(SocketContext);
};

const SocketProvider = ({ children }) => {
  const [storedSocketId, setStoredSocketId] = useState(() =>
    typeof window !== "undefined" ? localStorage.getItem("socketId") : null
  );
  const [receiverUID, setReceiverUID] = useState();

  console.log("receiverUID>>", receiverUID);

  const [allMessages, setAllMessages] = useState([]);
  const [message, setMessage] = useState("");

  const user = useSelector((state) => state.store.currentUser);
  const userId = user?._id;
  const socket_id=user?.socket_id
  console.log('socket_id',socket_id)

  const socket = useMemo(() => {
    const options = storedSocketId
      ? { query: { socketId: storedSocketId } }
      : {};
    return io("http://192.168.29.155:5000", options);
  }, [storedSocketId]);

  useEffect(() => {
    if (!storedSocketId) {
      socket.on("connect", () => {
        console.log("socket connected", socket);
        const newSocketId = socket.id;
        setStoredSocketId(newSocketId);
      });
    }

    socket.emit("joined", { user, storedSocketId, userId });
  }, [socket, storedSocketId]);

  // Message sending function

  const handleMessage = async () => {
    const senderId = userId;

    const receiverId = receiverUID;
    // const receiverId = receiverUID='674070ab21403314d13d3a71';

    const newMessage = {
      senderId,
      receiverId,
      message,
    };

    try {
      /*if (file?.length > 0 && !message) {
        setIsLoading(true);
        setFiles([]);
        try {
          const newFileData = await uploadFiles(file, senderId, receiverId);
          newFileData.forEach((fileData) => {
            socket.emit("file-upload", fileData);
          });
        } catch (err) {
          console.log(err);
        } finally {
          setIsLoading(false);
          setPreview([]);
        }
      }

      if (message && file?.length <= 0) {
        socket.emit("message", newMessage);
        setMessage("");
      }*/

      if (message) {
        socket.emit("message", newMessage);
        setMessage("");
      }

      // if (file?.length > 0 && message) {
      //   setIsLoading(true);
      //   socket.emit("message", newMessage);
      //   setMessage("");
      //   setFiles([]);

      //   try {
      //     const newFileData = await uploadFiles(file, senderId, receiverId);
      //     newFileData.forEach((fileData) => {
      //       socket.emit("file-upload", fileData);
      //     });
      //   } catch (err) {
      //     console.log(err);
      //   } finally {
      //     setPreview([]);
      //     setIsLoading(false);
      //   }
      // }
    } catch (error) {
      console.error("Error handling message:", error);
    }
  };

  useEffect(() => {
    socket.emit("single-message", receiverUID, userId);
  }, [receiverUID, userId]);

  // This useEffect actually receive the messages through the socket
  useEffect(() => {
    socket.on("get-all-messages", (msg) => {
      console.log("messages are",msg)
      setAllMessages(msg);
    });
  }, [setAllMessages, socket]);

  useEffect(() => {
    socket.on("receive-message", () => {
      socket.on("get-all-messages", (msg) => {
        setAllMessages(msg);
      });
    });
  }, [socket]);

  return (
    <SocketContext.Provider
      value={{
        socket,
        setMessage,
        message,
        handleMessage,
        setReceiverUID,
        receiverUID,
        allMessages
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
