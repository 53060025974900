import React, { useState } from "react";
import { useSelector } from "react-redux";
import { MdDeleteForever, MdDeleteOutline } from "react-icons/md";
import { FaStar, FaRegStar } from "react-icons/fa";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { HiOutlineShoppingBag } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import { formattedAmount } from "../utils/helpers/Functions.helper";

const DeltaCard = ({
  item,
  isShowDescriptionid,
  handleProductClick,
  openProductDeletModal,
}) => {
  const navigate = useNavigate();
  const { theme } = useSelector((state) => state.theme);
  const [mouseEnter, setMouseEnter] = useState(false);
  const [stars, setStars] = useState(4);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [showMagnifier, setShowMagnifier] = useState(false);
  const [cartClicked, setCartClicked] = useState(false);

  const handleMouseHover = (e) => {
    const { left, top, width, height } =
      e.currentTarget.getBoundingClientRect();

    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setPosition({ x, y });

    setCursorPosition({ x: e.pageX - left, y: e.pageY - top });
  };

  const handleCartClick = () => {
    setCartClicked(!cartClicked);
  };

  // console.log("item",item);

  return (
    <main
      className={`${
        theme === "light" ? "bg-white" : "bg-darkThirsary"
      } px-2 relative group h-[400px] w-70 text-center rounded-xl overflow-hidden shadow-md border border-gray-200`}
    >
      <i
        className="absolute right-3 top-2 p-2 rounded-full cursor-pointer bg-red-600 bg-opacity-70 hover:bg-opacity-100 transition-all duration-300 group-hover:z-10 border border-gray-200 "
        onClick={() => openProductDeletModal("product", item)}
      >
        <MdDeleteOutline className="text-2xl text-white" />
      </i>

      <div
        className={` h-[50%] relative  overflow-hidden cursor-pointer ${
          theme === "light"
            ? "bg-secondaryColor text-textColorLight"
            : "bg-darkThirsary text-darkSecondary"
        }`}
        onMouseEnter={() => setShowMagnifier(true)}
        onMouseLeave={() => setShowMagnifier(false)}
        onMouseMove={handleMouseHover}
      >
        <span
          className={`p-3 font-bold ${
            item.status === "Rejected"
              ? "text-red-500"
              : item.status === "Approved"
              ? "text-green-500"
              : item.status === "Pending"
              ? "text-yellow-500"
              : "text-gray-200"
          }  `}
        >
          {" "}
          {item?.status}{" "}
        </span>
        <LazyLoadImage
          onClick={() => handleProductClick(item)}
          src={item.primary_image}
          effect="blur"
          wrapperProps={{
            style: {
              transitionDelay: "1s",
            },
          }}
          alt="img"
          className={`w-full h-60 object-cover object-center cursor-pointer transform transition-transform duration-300}`}
        />
      </div>
      {/* {showMagnifier && (
        <div
          className="absolute border border-gray-400  pointer-events-none"
          style={{
            left: `${cursorPosition.x - 100}px`,
            top: `${cursorPosition.y - 100}px`,
            width: "150px",
            height: "150px",
            backgroundImage: `url(${item.primary_image})`,
            backgroundSize: "550%",
            backgroundPosition: `${position.x}%  ${position.y}%`,
          }}
        ></div>
      )} */}

      {/* <div
        className={`absolute top-50 left-1/2 transform -translate-x-1/2 p-2 border border-gray-300 rounded-full
        ${cartClicked ? "bg-green-500" : "bg-white"}  `}
        style={{
          width: "40px",
          height: "40px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0px 2px 10px rgba(0,0,0,0.2)",
        }}
        onClick={handleCartClick}
      >
        <HiOutlineShoppingBag
          className={`text-2xl cursor-pointer ${
            cartClicked ? "text-white" : "text-gray-500"
          }`}
        />
      </div> */}

      <div className="h-[50%] p-1 mt-2 ">
        {/* <p className="text-sm text-gray-500 mt-2">{item?.sub_category?.name}</p> */}

        <div className="flex justify-around items-center">
          <div className="flex justify-center items-center mt-1">
            {[...Array(5)].map((_, index) =>
              index < stars ? (
                <FaStar key={index} className="text-orange-500 text-xs" />
              ) : (
                <FaRegStar key={index} className="text-orange-500 text-xs" />
              )
            )}
            <span className="text-sm text-gray-500 ml-2">(4.1)</span>
          </div>
          <span className="text-sm">
            <span
              className={
                item?.product_details && item?.product_details[0]?.stock >= 0
                  ? `text-green-500`
                  : `text-red-500`
              }
            >
              Stock:{" "}
            </span>
            {item?.product_details && item?.product_details[0]?.stock}
          </span>
        </div>

        <p
          onClick={() => handleProductClick(item)}
          className="font-bold text-black text-base mt-0 mb-0 whitespace-nowrap overflow-hidden text-ellipsis cursor-pointer"
        >
          {item.name}
        </p>
        <p
          onClick={() => handleProductClick(item)}
          className="font-bold text-gray-500 text-base mt-0 mb-0 line-clamp-2 cursor-pointer"
          dangerouslySetInnerHTML={{ __html: item.description.trim() }}
        />

        {/* {item?.product_details?.length?item?.product_details.map((d,i)=>(
        <div key={i} className="flex gap-1 justify-center items-center mt-2 mb-3">
          
          <p className="text-gray-400 line-through text-base">
            ${item?.mrp_price?.$numberDecimal}
          </p>
          <p className="text-green-600 font-bold text-lg mr-2">
            ${item?.sales_price?.$numberDecimal}
          </p>
          <p className="text-green-600 font-bold text-lg mr-2">
            {item?.stock}
          </p>
          
        </div>)):""} */}
        <div className="flex gap-1 justify-center items-center mt-2 mb-1">
          <p className="text-gray-400 line-through text-base">
            {item?.product_details
              ? formattedAmount(
                  item?.product_details[0]?.mrp_price?.$numberDecimal ||
                    item?.product_details[0]?.mrp_price
                )
              : ""}
          </p>
          <p className="text-green-600 font-bold text-lg mr-2">
            {item?.product_details
              ? formattedAmount(
                  item?.product_details[0]?.taxData?.grandTotal
                    ?.$numberDecimal ||
                    item?.product_details[0]?.taxData?.grandTotal
                )
              : ""}
          </p>
          {/* <p className="text-green-600 font-bold text-lg mr-2">
            
            {item?.product_details
              ? formattedAmount(item?.product_details[0]?.sales_price?.$numberDecimal||item?.product_details[0]?.sales_price)
              : ""}
          </p> */}
          {/* <p className="text-green-600 font-bold text-lg mr-2">
            {item?.stock}
          </p> */}
        </div>
        {/* <button onClick={()=>  navigate(`/store/product-details/${item._id}`)}>View Product</button> */}
        <div className="mb-2">
          <Button
            text="View Product"
            type="button"
            className="float-end "
            onClick={() => navigate(`/store/product-details/${item._id}`)}
          />
        </div>
      </div>
    </main>
  );
};

export default DeltaCard;
