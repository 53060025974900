// import React, { useState } from "react";
// import {
//   IoClose,
//   IoSend,
//   IoImage,
//   IoMic,
//   IoLocationSharp,
//   IoHappyOutline,
// } from "react-icons/io5";
// import { useSocket } from "./SocketProvider";


// const ChatComponent = ({ onClose }) => {
//   const { socket,message,
//     setMessage,
//     handleMessage, } = useSocket();

// //   const handleTyping = () => {
// //     const senderId = users.filter((item) =>
// //       item.full_name === user ? item : null
// //     );

// //     const receiverId = "123";
// //     socket.emit("typing", { sender: senderId, receiver: receiverId });
// //   };
//   return (
//     <div className="flex flex-col justify-between h-[95%] relative shadow-md  rounded  w-full mx-auto">
//       {/* <div className="fixed top-0 right-0 w-full md:w-1/3 h-full bg-white shadow-lg z-50 flex flex-col"> */}
//         {/* Header */}
//         {/* <div className="flex justify-between items-center p-4 border-b">
//           <h2 className="text-lg font-semibold">Balaji Studio</h2>
//           <button
//             onClick={onClose}
//             className="text-gray-600 hover:text-red-600"
//           >
//             <IoClose size={24} />
//           </button>
//         </div> */}

//         {/* Chat Messages */}
//         <div className="flex-1 p-4 space-y-4 overflow-y-auto">
//           {/* Messages Example */}
//           <div className="flex flex-col space-y-2">
//             <div className="bg-gray-200 p-3 rounded-lg text-gray-800 self-start">
//               <strong>Mas Happy:</strong> Guyss tahun depan liburan ke Jepang!
//               🇯🇵
//             </div>
//             <div className="bg-blue-500 text-white p-3 rounded-lg self-end">
//               <strong>You:</strong> Gokil!!!
//             </div>
//             <div className="bg-gray-200 p-3 rounded-lg text-gray-800 self-start">
//               <strong>Mas Rohmad:</strong> Tenan ki???
//             </div>
//           </div>
//         </div>

//         {/* Input Area */}
//         <div className="abosolute bottom-0 left-0 right-0 pb-10 pt-6 pl-2  border-t bg-gray-100 flex items-center space-x-3">
//           <button className="text-gray-500 hover:text-blue-500">
//             <IoMic size={24} />
//           </button>
//           <div className="flex-1 relative">
//             <input
//               type="text"
//               placeholder="Type a message"
//               className="w-full rounded-full border border-gray-300 bg-white px-4 py-2 pl-10 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
//               value={message}
//               onChange={(e) => {
//                 setMessage(e.target.value);
//                 // handleTyping();
//               }}
//             />
//             <IoHappyOutline
//               size={20}
//               className="absolute top-1/2 transform -translate-y-1/2 left-3 text-gray-400"
//             />
//           </div>
//           <button className="text-gray-500 hover:text-blue-500">
//             <IoImage size={24} />
//           </button>
//           <button className="text-gray-500 hover:text-blue-500">
//             <IoLocationSharp size={24} />
//           </button>
//           <button
//             className="text-red-500 hover:text-blue-700"
//             onClick={handleMessage}
//           >
//             <IoSend size={24} />
//           </button>
//         </div>
//       {/* </div> */}
//     </div>
//   );
// };

// export default ChatComponent;



"use client";
import React, { useEffect, useState } from "react";
import {
  IoClose,
  IoSend,
  IoImage,
  IoMic,
  IoLocationSharp,
  IoHappyOutline,
} from "react-icons/io5";
import { useSocket } from "./SocketProvider";
import { useSelector } from "react-redux";
import moment from "moment";
import Messages from "./Messages";
import ReactScrollToBottom from "react-scroll-to-bottom";

const ChatComponent = ({ data, onClose }) => {
  const {
    socket,
    setMessage,
    message,
    handleMessage,
    receiverUID,
    setReceiverUID,
    allMessages,
  } = useSocket();
  const activeUser = useSelector((state) => state.store.currentUser);

  console.log("OrderDAta",data);
  console.log("receiverUID",receiverUID);
  

  const groupMessagesByDate = (messages) => {
    return messages.reduce((groupedMessages, message) => {
      const date = new Date(message.created_at);
      const today = moment().startOf("day");
      const messageDate = moment(date).startOf("day");
      let dateKey;

      if (messageDate.isSame(today, "day")) {
        dateKey = "Today";
      } else if (messageDate.isSame(today.clone().subtract(1, "day"), "day")) {
        dateKey = "Yesterday";
      } else if (messageDate.isSame(today, "week")) {
        dateKey = messageDate.format("dddd");
      } else {
        dateKey = messageDate.format("DD MMMM YYYY");
      }

      if (!groupedMessages[dateKey]) {
        groupedMessages[dateKey] = [];
      }
      groupedMessages[dateKey].push(message);

      return groupedMessages;
    }, {});
  };

  // This groupedMessages function filter the messages according to the receiver and sender
  const groupedMessages = groupMessagesByDate(
    allMessages.filter(
      (item) =>
        (item.receiver_id?._id === receiverUID &&
          item.sender_id?._id === activeUser?._id) ||
        (item.sender_id?._id === receiverUID &&
          item.receiver_id?._id === activeUser?._id)
    )
  );

  const messagesWithDates = [];

  // Here groupedMessages are object like date : [messages] to convert in the one array we did this
  Object.entries(groupedMessages).forEach(([date, messages]) => {
    messagesWithDates.push({ type: "date", date });
    messages.forEach((message) => {
      messagesWithDates.push({ type: "message", ...message });
    });
  });

  // const handleTyping = () => {
  //   const senderId = users.filter((item) =>
  //     item.full_name === user ? item : null
  //   );

  //   const receiverId = "123";
  //   socket.emit("typing", { sender: senderId, receiver: receiverId });
  // };
  
  useEffect(()=>{
    setReceiverUID(data?.created_by)
  },[data])
  
  return (
    <div className="flex flex-col justify-between h-[95%] relative shadow-md  rounded  w-full mx-auto">
      {/* Header */}
      <div className="flex justify-between items-center p-4 border-b">
        <h2 className="text-lg font-semibold capitalize">{data?.delivery_address?.name}</h2>
        <button onClick={onClose} className="text-gray-600 hover:text-red-600">
          {onClose && <IoClose size={24} />}
        </button>
      </div>

      {messagesWithDates.length > 0 ? (
        <ReactScrollToBottom className="h-[90vh] p-4 overflow-auto w-full relative ">
          {messagesWithDates.map((item, ind) =>
            item.type === "date" ? (
              <Messages
                key={ind}
                user={"date"}
                message={item.date}
                classs={"message_message-date"}
              />
            ) : item.file ? (
              <Messages
                key={ind}
                message={item.file}
                time_date={item.created_at}
                classs={
                  item.sender_id?._id === receiverUID
                    ? "message_left"
                    : "message_right"
                }
                file={true}
              />
            ) : (
              <Messages
                key={ind}
                user={
                  item.sender_id?._id === receiverUID
                    ? ""
                    : activeUser?.full_name
                }
                message={item.message}
                time_date={item.created_at}
                classs={
                  item.sender_id?._id === receiverUID
                    ? "message_left"
                    : "message_right"
                }
              />
            )
          )}

          {/* {isLoading && progress
          ? preview.map((src, index) => (
              <Messages
                key={index}
                isLoading={isLoading}
                progress={progress}
                message={src}
                classs={"right"}
              />
            ))
          : null} */}
        </ReactScrollToBottom>
      ) : (
        <div className="h-[90vh] flex flex-col items-center justify-center bg-gray-50 rounded-md shadow-inner">
          <p className="text-gray-500 text-lg font-medium mb-2">
            No conversations yet
          </p>
          <p className="text-sm text-gray-400 italic">
            Start a new conversation and your messages will appear here!
          </p>
        </div>
      )}

      {/* Input Area */}
      <div className="pb-10 pt-6 pl-2 border-t bg-gray-100 flex items-center space-x-3">
        <button className="text-gray-500 hover:text-blue-500">
          <IoMic size={24} />
        </button>
        <div className="flex-1 relative">
          <input
            type="text"
            placeholder="Type a message"
            className="w-full rounded-full border border-gray-300 bg-white px-4 py-2 pl-10 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />
          <IoHappyOutline
            size={20}
            className="absolute top-1/2 transform -translate-y-1/2 left-3 text-gray-400"
          />
        </div>
        <button className="text-gray-500 hover:text-blue-500">
          <IoImage size={24} />
        </button>
        <button className="text-gray-500 hover:text-blue-500">
          <IoLocationSharp size={24} />
        </button>
        <button
          className="text-red-500 hover:text-blue-700"
          onClick={handleMessage}
        >
          <IoSend size={24} />
        </button>
      </div>
    </div>
  );
};

export default ChatComponent;
