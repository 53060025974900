import React, { useCallback, useEffect, useRef, useState } from "react";
import { columns, searchBy } from "../../../service/AUItemService";

import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import MenuApi from "../../../apis/menu.apis";
import { ApproveUnapproveApi } from "../../../apis/a&uApi";
import BasicTable from "../../../components/Table/BasicTable";

const AUItemRejectedComponent = ({ pagination, handlePagination }) => {
  const [search, setSearch] = useState("");
  const debounceTimeout = useRef(null);

  const [isOrderDetail, setIsOrderDetail] = useState(false);
  const [selectedOrder, setSelectedProduct] = useState(null);
  const [vendorData, setVendorData] = useState(null);
  const [productData, setProductData] = useState(null);

  const navigate = useNavigate();

  const getAllProduct = async () => {
    try {
      const res = await MenuApi.item.getAllItemProduct(pagination, search);
      setProductData(res?.data?.data);
    } catch (error) {
      toast.error(error?.message);
    }
  };
  const handleCloseVenderModal = () => {
    setIsOrderDetail(false);
    setSelectedProduct(null);
  };
  const handleRowClick = (row) => {
    setSelectedProduct(row);
    setIsOrderDetail(true);
  };
  const handleCellClick = async (row, action) => {
    if (action === "name") {
      navigate(`/store/product-details/${row._id}`);
    }

    if (action === "Approved" || action === "Unapproved") {
      try {
        await ApproveUnapproveApi.updateItemApprovalStatus(row?._id, {
          status: action,
        });
        getAllProduct();
      } catch (error) {
        toast.error(error?.message);
      }
    }
    if (action === "order_id") {
      setSelectedProduct(row);
      setIsOrderDetail(true);
    }
    if (action === "vendor") {
      setVendorData(row);
    }
  };

  const onPaginatorClick = (e) => {
    if (e) {
      const data = {
        limit: e.rows,
        page: +e.page + 1,
      };
      handlePagination && handlePagination(data);
    }
  };

  const handleSearch = useCallback((s) => {
    setSearch(s);
  }, []);

  const debouncedSearch = useCallback(
    (s) => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        handleSearch(s);
      }, 1500);
    },
    [handleSearch]
  );

  const handleClearFilter = () => {
    const clearPagination = {
      page: 1,
      limit: 5,
      sortOrder: "desc",
      filter: {},
    };
    handlePagination && handlePagination({ clearPagination });
  };
  const handleFilter = (name, value) => {
    let data = {};
    if (name === "search_by") {
      data.sortOrder = value.code;
    } else {
      const filter = {};
      filter[name] = value.code ? value.code : value;
      data.filter = filter;
    }

    handlePagination && handlePagination(data);
  };

  useEffect(() => {
    getAllProduct();
  }, [pagination, search]);

  return (
    <div className="p-5">
      <BasicTable
        columns={columns}
        data={productData?.products}
        onPaginatorClick={onPaginatorClick}
        paginatorData={{
          filters: null,
          first: 0,
          multiSortMeta: [],
          page: productData?.limit || 5,
          sortField: null,
          sortOrder: null,
          totalPages: productData?.totalPages || 1,
          totalCount: productData?.totalCount || 1,
        }}
        onCellClick={(e) => {
          console.log("Cell Data", e);
        }}
        SearchBy={searchBy}
        handleSearch={debouncedSearch}
        handleFilter={(name, value) => handleFilter(name, value)}
        handleClearFilter={handleClearFilter}
        // filters={filters}
        selectedData={selectedOrder}
        handleCellClick={handleCellClick}
      />
      {/* <ReferralModal
        handleRowClick={handleRowClick}
        isOrderDetail={isOrderDetail}
        handleCloseVenderModal={handleCloseVenderModal}
        referralData={selectedOrder}
      />
      <StoreModal
        itemData={vendorData}
        setToggleUsersDetails={(v) => {
          setVendorData(null);
        }}
        toggleUsersDetails={Boolean(vendorData)}
      /> */}
    </div>
  );
};

export default AUItemRejectedComponent;
