import { LazyLoadImage } from "react-lazy-load-image-component";
import { FcShop } from "react-icons/fc";
import { HiMiniXMark } from "react-icons/hi2";
import { IoMdCheckmark } from "react-icons/io";
import { Badge } from "primereact/badge";
import DropdownMenubar from "../components/Menubar/DropdownMenubar.jsx";

const DD = (MenuItems) => {
  return (
    <div className="p-3">
      <DropdownMenubar
        menuItem={[
          {
            label: "Action",
            items: MenuItems,
          },
        ]}
      />
    </div>
  );
};
const getVendorInfo = (row, handleCellClick) => {
  return DD([
    {
      label: "APPROVE",
      icon: (
        <div className="  text-white  rounded-full bg-green-600">
          {" "}
          <IoMdCheckmark className="" />
        </div>
      ),

      command: () => {
        handleCellClick(row, "Approved");
      },
    },
    {
      label: "UNAPPROVE",
      icon: (
        <div className="  text-white  rounded-full bg-red-600">
          {" "}
          <HiMiniXMark className="" />
        </div>
      ),
      command: () => {
        handleCellClick(row, "Rejected");
      },
    },
  ]);
};
export const columns = [
  {
    field: "name",
    header: "Product Name",
    accessor: (row, handleCellClick) => (
      <>
        <div onClick={() => handleCellClick(row, "name")} className="">
          <p className="text-blue-600">{row?.name}</p>
        </div>
      </>
    ),
  },
  {
    field: "image",
    header: "Image",
    accessor: (row, handleCellClick) => (
      <div className="w-fit">
        <div className="overflow-hidden">
          {row?.primary_image ? (
            <img
              src={row?.primary_image}
              alt="img"
              loading="lazy"
              effect="blur"
              className="rounded-full w-20 h-20"
            />
          ) : (
            <div className="rounded-full w-20 h-20 bg-[#d2d2d2] flex justify-center items-center">
              <FcShop className="text-[3rem]  text-[#02aabd93]" />
            </div>
          )}
        </div>
      </div>
    ),
  },
  {
    field: "status",
    header: "Status",
    accessor: (row) => (
      <>
        {row?.status && (
          <Badge
            className="w-[6rem]"
            severity={row?.status === "Rejected" ? "danger" : "success"}
            value={row?.status}
          />
        )}
      </>
    ),
  },
  // {
  //   field: "action",
  //   header: "Action",
  //   accessor: (row, handleCellClick) => getVendorInfo(row, handleCellClick),
  // },
];

export const searchBy = {
  filterType: "Sort By",
  inputName: "search_by",
  options: [
    {
      name: "Descending ",
      code: "desc",
    },
    {
      name: "Ascending",
      code: "asc",
    },
  ],
};

// export const filters = [
//   {
//     filterType: "Filter by A/U",
//     inputName: "status",
//     options: [
//       {
//         name: "Approved",
//         code: "Approved",
//       },
//       {
//         name: "Rejected",
//         code: "Rejected",
//       },
//     ],
//   },
// ];
