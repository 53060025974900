import Api from "../utils/axios";

export const promoCodeApi = {
  createPromoCode: (payload) =>
    Api.post("vendor/create-promo-code", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  updatePromoCode: (payload, id) =>
    Api.put(`vendor/update-promo-code/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  getPromoCodeById: (id) => Api.get(`vendor/get-promo-code-by-id/${id}`),
  getAllPromoCodes: (payload) =>
    Api.post("vendor/get-all-promo-codes", payload),
  deletePromoCode: (id) => Api.delete(`vendor/delete-promo-code/${id}`),
};

export const saleApi = {
  createSale: (payload) =>
    Api.post("vendor/create-sale", payload, {
      headers: { "Content-Type": "multipart/form-data" },
    }),
  updateSale: (payload, id) =>
    Api.put(`vendor/update-sale/${id}`, payload, {
      headers: { "Content-Type": "multipart/form-data" },
    }),
  getSaleById: (id) => Api.get(`vendor/get-sale-by-id/${id}`),
  getAllSales: (payload) => Api.post("vendor/get-all-sales", payload),
  deleteSale: (id) => Api.delete(`vendor/delete-sale/${id}`),
};
