import React, { useState } from 'react'
import { formattedAmount } from '../../../utils/helpers/Functions.helper';
import moment from 'moment/moment';
import { Badge } from 'primereact/badge';
import AssignOrderModal from './AssignOrderModal';

const DispatchOrderCard=({item})=> {
  const [isAssignModal,setIsAssignModal]=useState(false);
  const [isAssignItem,setIsAssignItem]=useState('');
    // console.log("item order:",item);
    
  return (
    <div className='shadow p-2 rounded-md'>
      <p><strong>Number of Items:</strong> {item.orderDetails.length}</p>
      <p><small>Order Cost:</small> {formattedAmount(item.net_total.$numberDecimal)}</p>
      <p><small>Order Date:</small>{moment(item.order_date).format("MMM Do, YYYY")}</p>
      <p><small>Order Type:</small>{item.order_type}</p>
      <p><small>Del Address:</small>{item.delivery_address.city.name},{item.delivery_address.country.name}</p>
      <div className="flex justify-between items-center">
      <Badge
          className="text-[10px] px-2"
          severity={"info"}
          value={item.delivery_status==="ASSIGNED"?"Assigning":""}
        />
        {/* <SecondaryButton
        padding='px-2 py-1'
        borderColor='border-blue-500'
          onClick={() => {
            setIsAssignModal(true);
            setIsAssignItem(item);
          }}
          text="Assign for Delivery"
        /> */}
      <button 
      className="px-2 py-1 border border-blue-500 text-blue-600 rounded-xl hover:bg-blue-100" 
      onClick={() => {
        setIsAssignModal(true);
        setIsAssignItem(item);
      }}
      >Assign for Delivery
      </button>
         
      </div>
      {isAssignModal && (
        <AssignOrderModal isAssignModal={isAssignModal} itemData={item} setIsAssignModal={setIsAssignModal}/>
      )}
    </div>
  )
}

export default DispatchOrderCard