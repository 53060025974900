// import TextWrap from "../custom/TextWrap.js";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FcShop } from "react-icons/fc";
import { HiMiniXMark } from "react-icons/hi2";
import { Badge } from "primereact/badge";
import { IoMdCheckmark } from "react-icons/io";
import DropdownMenubar from "../components/Menubar/DropdownMenubar";

const DD = (MenuItems) => {
  return (
    <div className="p-3">
      <DropdownMenubar
        menuItem={[
          {
            label: "Action",
            items: MenuItems,
          },
        ]}
      />
    </div>
  );
};

const getVendorInfo = (row, handleCellClick) => {
  return DD([
    {
      label: "APPROVE",
      icon: (
        <div className="  text-white  rounded-full bg-green-600">
          {" "}
          <IoMdCheckmark className="" />
        </div>
      ),

      command: () => {
        handleCellClick(row, "Approved");
      },
    },
    {
      label: "UNAPPROVE",
      icon: (
        <div className="  text-white  rounded-full bg-red-600">
          {" "}
          <HiMiniXMark className="" />
        </div>
      ),
      command: () => {
        handleCellClick(row, "Rejected");
      },
    },
  ]);
};
export const columns = [
  {
    field: "vendor",
    header: "Vendor",
    accessor: (row, handleCellClick) => (
      <div
        onClick={() => handleCellClick(row?.vendor, "vendor")}
        className="w-fit"
      >
        <div className="overflow-hidden">
          {row?.vendor.profile_image ? (
            <LazyLoadImage
              src={row?.vendor.profile_image}
              alt="img"
              loading="lazy"
              effect="blur"
              wrapperProps={{
                style: { transitionDelay: "1s" },
              }}
              className="rounded-full w-20 h-20"
            />
          ) : (
            <div className="rounded-full w-20 h-20 bg-[#d2d2d2] flex justify-center items-center">
              <FcShop className="text-[3rem]  text-[#02aabd93]" />
            </div>
          )}
        </div>
        <p className="w-full text-nowrap text-center">
          {row?.vendor?.first_name || ""}
        </p>
      </div>
    ),
  },
  {
    field: "advertisement_for",
    header: "Advetisement For",
    accessor: (row, handleCellClick) => (
      <>
        <div
          onClick={() => handleCellClick(row, "advertisement_for")}
          className=""
        >
          <p>{row?.advertisement_for}</p>
          {/* <p className="w-[6rem]">
            {moment(row?.order_date).utc().format("DD MMM YYYY hh:mma")}
          </p> */}
        </div>
      </>
    ),
  },
  {
    field: "audience_type",
    header: "Audience Type",
    accessor: (row, handleCellClick) => (
      <>
        <div onClick={() => handleCellClick(row, "audience_type")} className="">
          <p>{row?.audience_type}</p>
          {/* <p className="w-[6rem]">
            {moment(row?.order_date).utc().format("DD MMM YYYY hh:mma")}
          </p> */}
        </div>
      </>
    ),
  },
  {
    field: "gender",
    header: "Audience Gender",
    accessor: (row, handleCellClick) => (
      <>
        <div onClick={() => handleCellClick(row, "gender")} className="">
          <p>{row?.gender}</p>
          {/* <p className="w-[6rem]">
            {moment(row?.order_date).utc().format("DD MMM YYYY hh:mma")}
          </p> */}
        </div>
      </>
    ),
  },
  {
    field: "gendtarget_audience_ageer",
    header: "Audience Age",
    accessor: (row, handleCellClick) => (
      <>
        <div
          onClick={() => handleCellClick(row, "target_audience_age")}
          className=""
        >
          <p>{row?.target_audience_age}</p>
          {/* <p className="w-[6rem]">
            {moment(row?.order_date).utc().format("DD MMM YYYY hh:mma")}
          </p> */}
        </div>
      </>
    ),
  },
  {
    field: "addToCart_count",
    header: "Cart Count",
    accessor: (row, handleCellClick) => (
      <>
        <div
          onClick={() => handleCellClick(row, "addToCart_count")}
          className=""
        >
          <p>{row?.addToCart_count}</p>
          {/* <p className="w-[6rem]">
            {moment(row?.order_date).utc().format("DD MMM YYYY hh:mma")}
          </p> */}
        </div>
      </>
    ),
  },
  {
    field: "advertise_views",
    header: "Advertise views",
    accessor: (row, handleCellClick) => (
      <>
        <div
          onClick={() => handleCellClick(row, "advertise_views")}
          className=""
        >
          <p>{row?.advertise_views}</p>
          {/* <p className="w-[6rem]">
            {moment(row?.order_date).utc().format("DD MMM YYYY hh:mma")}
          </p> */}
        </div>
      </>
    ),
  },
  {
    field: "share",
    header: "Advertise shares",
    accessor: (row, handleCellClick) => (
      <>
        <div onClick={() => handleCellClick(row, "share")} className="">
          <p>{row?.share}</p>
          {/* <p className="w-[6rem]">
            {moment(row?.order_date).utc().format("DD MMM YYYY hh:mma")}
          </p> */}
        </div>
      </>
    ),
  },
  {
    field: "advertisement_budget",
    header: "Advertise Budget",
    accessor: (row, handleCellClick) => (
      <>
        <div
          onClick={() => handleCellClick(row, "advertisement_budget")}
          className=""
        >
          <p>{row?.advertisement_budget}</p>
          {/* <p className="w-[6rem]">
            {moment(row?.order_date).utc().format("DD MMM YYYY hh:mma")}
          </p> */}
        </div>
      </>
    ),
  },
  {
    field: "location_radius",
    header: "Location radius",
    accessor: (row, handleCellClick) => (
      <>
        <div
          onClick={() => handleCellClick(row, "location_radius")}
          className=""
        >
          <p>{row?.location_radius}</p>
          {/* <p className="w-[6rem]">
            {moment(row?.order_date).utc().format("DD MMM YYYY hh:mma")}
          </p> */}
        </div>
      </>
    ),
  },
  {
    field: "ordered_by_advertise",
    header: "Orders via Advertisement",
    accessor: (row, handleCellClick) => (
      <>
        <div
          onClick={() => handleCellClick(row, "ordered_by_advertise")}
          className=""
        >
          <p>{row?.ordered_by_advertise}</p>
          {/* <p className="w-[6rem]">
            {moment(row?.order_date).utc().format("DD MMM YYYY hh:mma")}
          </p> */}
        </div>
      </>
    ),
  },
  {
    field: "start_date",
    header: "Start date",
    accessor: (row, handleCellClick) => (
      <>
        <div onClick={() => handleCellClick(row, "start_date")} className="">
          {row?.start_date
            ? new Date(row.start_date).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            : "N/A"}
          {/* <p className="w-[6rem]">
            {moment(row?.order_date).utc().format("DD MMM YYYY hh:mma")}
          </p> */}
        </div>
      </>
    ),
  },
  {
    field: "end_date",
    header: "End date",
    accessor: (row, handleCellClick) => (
      <>
        <div onClick={() => handleCellClick(row, "end_date")} className="">
          <p>
            {row?.end_date
              ? new Date(row.end_date).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })
              : "N/A"}
          </p>
        </div>
      </>
    ),
  },

  //   {
  //     field: "name",
  //     header: "Status",
  //     accessor: (row, handleCellClick) => (
  //       <>
  //        {row?.status && <Badge className="w-[6rem]" severity={row?.status ==="Rejected" ?"danger" : "success"} value={row?.status} />}
  //        </>
  //     ),
  //   },
  //   {
  //     field: "action",
  //     header: "Action",
  //     accessor: (row, handleCellClick) => getVendorInfo(row, handleCellClick),
  //   },
  {
    field: "image",
    header: "Image",
    accessor: (row, handleCellClick) => (
      <div
        // onClick={() => handleCellClick(row?.image, "image")}
        className="w-20"
      >
        <div className="overflow-hidden ">
          {row?.image ? (
            <LazyLoadImage
              src={row?.image}
              alt="img"
              loading="lazy"
              effect="blur"
              wrapperProps={{
                style: { transitionDelay: "1s" },
              }}
              className="rounded-full w-20 h-20"
            />
          ) : (
            <div className="rounded-full w-20 h-20 bg-[#d2d2d2] flex justify-center items-center">
              <FcShop className="text-[3rem]  text-[#02aabd93]" />
            </div>
          )}
        </div>
      </div>
    ),
  },
  {
    field: "status",
    header: "Status",
    accessor: (row, handleCellClick) => (
      <>
        {row?.status && (
          <Badge
            className="w-[6rem]"
            severity={row?.status === "Rejected" ? "danger" : "success"}
            value={row?.status}
          />
        )}
      </>
    ),
  },
  // {
  //   field: "action",
  //   header: "Action",
  //   accessor: (row, handleCellClick) => getVendorInfo(row, handleCellClick),
  // },
];

export const searchBy = {
  filterType: "Sort By",
  inputName: "search_by",
  options: [
    {
      name: "Descending ",
      code: "desc",
    },
    {
      name: "Ascending",
      code: "asc",
    },
  ],
};

// export const filters = [
//   // enum: ['Store', 'Product', 'Category', 'Sub Category'],
//   {
//     filterType: "Filter by A/U",
//     inputName: "status",
//     options: [
//       {
//         name: "Approved",
//         code: "Approved",
//       },
//       {
//         name: "Rejected",
//         code: "Rejected",
//       },
//     ],
//   },
// ];
