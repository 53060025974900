import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const SidebarField = ({
  children,
  button0,
  button1,
  button2,
  title,
  title_style,
  handleClose,
  footer=true
}) => {
  const { theme } = useSelector((state) => state.theme);
  const [isFullWidth, setIsFullWidth] = useState(false);

  return (
    <div
      className={`${
        theme === "light"
          ? "bg-secondaryColor text-textColorLight"
          : "bg-darkThirsary text-darkSecondary"
      }  ${
        isFullWidth && isFullWidth ? "" : "lg:w-[35rem] "
      }w-[100%] text-sm h-screen fixed right-0 top-0 shadow-2xl z-20 flex flex-col justify-between`}
    >
      <header
        className={`border-b p-5 flex justify-between items-center ${
          title_style ? title_style : "text-lg"
        }`}
      >
        <span
          onClick={() => setIsFullWidth((p) => !p)}
          className="cursor-pointer"
        >
          {isFullWidth ? <FaArrowRight /> : <FaArrowLeft />}
        </span>
        <div>{title}</div>
        <div className="cursor-pointer text-black" onClick={handleClose}>
          <IoClose />
        </div>
      </header>
      <main className="overflow-y-auto  p-3 w-full h-full">{children}</main>
      {footer?<footer className="border-t p-5 flex justify-center items-center gap-1">
        {button1 ? button1 : ""}
        {button0 ? button0 : ""}
        {button2 ? button2 : ""}
      </footer>:""}
    </div>
  );
};

export default SidebarField;
