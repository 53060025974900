import moment from 'moment';
import React, { useState } from 'react';
import { formattedAmount } from '../../../utils/helpers/Functions.helper';


const AssignOrderModal = ({ isAssignModal, setIsAssignModal, itemData }) => {
  const [showManualAssign, setShowManualAssign] = useState(false);
  const [isDeliveryMan, setDeliveryMan] = useState('');

  console.log('itemData:', itemData);

  const deliveryPersonnel = [
    { id: 1, name: 'John Doe' },
    { id: 2, name: 'Jane Smith' },
    { id: 3, name: 'Mike Johnson' },
  ];

  return (
    <div className="w-full fixed inset-0 flex items-center justify-center bg-[#111827d9] bg-opacity-80 z-[9999]">
      <div className="bg-white rounded-lg shadow-lg p-4 w-full max-w-md z-40">
        {/* Header */}
        <div className="flex justify-between items-center cursor-pointer">
          <h1 className="text-xl font-bold">Assign Order</h1>
          <div onClick={() => setIsAssignModal(false)} className="text-xl font-bold hover:text-black">
            X
          </div>
        </div>
        <hr className="my-4" />

        {showManualAssign ? (
          <div>
            {/* Manual Assign Section */}
            <h2 className="text-lg font-semibold">Select Delivery Personnel</h2>
            <div className="space-y-2 mt-2">
              {deliveryPersonnel.map((person) => (
                <label key={person.id} className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="deliveryPerson"
                    value={person.id}
                    className="form-radio"
                    onClick={() => setDeliveryMan(person.name)}
                  />
                  <span>{person.name}</span>
                </label>
              ))}
            </div>
          </div>
        ) : (
          <div>
            {/* Order Details Section */}
            <div className="space-y-2">
              <p><strong>Order ID:</strong> {itemData?.order_id}</p>
              <p><strong>Order Type:</strong> {itemData?.order_type}</p>
              <p><strong>No of Orders:</strong> {itemData?.orderDetails?.length}</p>
              <p><strong>Order Date:</strong> {moment(itemData.order_date).format('MMM Do, YYYY')}</p>
              <p>
                <strong>Delivery Address:</strong> {itemData.delivery_address.street},
                {itemData.delivery_address.city.name}, {itemData.delivery_address.country.name}
              </p>
              <p><strong>Order Cost:</strong> {formattedAmount(itemData?.net_total?.$numberDecimal)}</p>
              <p><strong>Payment Status:</strong> {itemData?.payment_status}</p>
              <p className="pt-4 font-bold">Vendor Contacts</p>
              <hr className="p-0 m-0" />
              <p><strong>Shop Name:</strong> {itemData.vendor.first_name}</p>
              <p><strong>Contact No:</strong> {itemData.vendor.phone_number}</p>
              <p><strong>Contact Email:</strong> {itemData.vendor.email}</p>
              <p className="pt-4 font-bold">Customer Contacts</p>
              <hr />
              <p><strong>Ordered By:</strong> {itemData.delivery_address.name}</p>
              <p><strong>Contact No:</strong> {itemData.delivery_address.phone_number}</p>
            </div>
          </div>
        )}

        {/* Button Section */}
        <div className="mt-6 flex justify-center gap-2">
          {showManualAssign ? (
            <>
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                onClick={() => setShowManualAssign(false)}
              >
                Back
              </button>
              <button
                className="bg-indigo-800 text-white px-4 py-2 rounded hover:bg-indigo-900"
                onClick={() =>
                  console.log('Order Assigned Manually to', isDeliveryMan)
                }
              >
                Assign Selected
              </button>
            </>
          ) : (
            <>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                onClick={() => alert('Auto Assign Activated')}
              >
                Auto Assign
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                onClick={() => setShowManualAssign(true)}
              >
                Manual Assign
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssignOrderModal;
