import { useState } from "react";
import { currencyFormat } from "./CurrencyFormat";
import { formattedAmount } from "../../utils/helpers/Functions.helper";

const PriceTooltip = ({ mrp, discount, specialPrice }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="relative inline-block text-left">
      {/* Info Icon or Button to Trigger Tooltip */}
      <button
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        className="p-2 text-gray-500 hover:text-gray-700"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M13 16h-1v-4h-1m1-4h.01M12 2a10 10 0 100 20 10 10 0 000-20z"
          />
        </svg>
      </button>

      {/* Tooltip Content */}
      {isVisible && (
        <div
          className="absolute z-10 left-[1.5rem] top-0 w-64 p-4 mt-2 bg-white rounded-lg shadow-lg border border-gray-200"
          style={{ transform: "translateY(-100%)" }} // Adjust the position as needed
        >
          <div className="font-semibold text-gray-800 mb-2">Price details</div>
          <div className="text-sm text-gray-600 mb-1 flex justify-between">
            <span>Maximum Retail Price</span>
            <span className="line-through">{formattedAmount(mrp)} </span>
          </div>
          <div className="text-sm text-gray-600 mb-1 flex justify-between">
            <span>Discount </span>
            <span className="line-through">
              {formattedAmount(discount)}
            </span>
          </div>
          <div className="text-sm text-gray-800 font-semibold flex justify-between">
            <span>Special Price</span>
            <span>{formattedAmount(specialPrice)}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default PriceTooltip;
