import { createBrowserRouter } from "react-router-dom";
import Layout from "../Layout";
import Dashboard from "../pages/Dashboard/Dashboard";
import Substore from "../pages/Users/Substore";
import Offers from "../pages/Offers/Offers";
import Profile from "../pages/Account/Profile";
import History from "../pages/History/History";
import Auth from "../components/Auth";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import ForgotPassword from "../pages/auth/ForgotPassword";
import NotFound from "../components/NotFound";
import CreateOrder from "../pages/Order/CreateOrder";
import { AuthProtected, ProtectedRoute } from "./protectedRoute";
import Earning from "../pages/Earning/Earning";
import CurrentMonth from "../pages/Earning/CurrentMonth";
import CurrentWeek from "../pages/Earning/CurrentWeek";
import Storesetting from "../pages/Settings/Storesetting";
import Deliverysettings from "../pages/Settings/Deliverysettings";
import Storetimesettings from "../pages/Settings/Storetimesettings";
// import TableBooking from "../pages/Order/TableBooking";
import StoreReviews from "../pages/History/StoreReviews.jsx";
import ProductReview from "../pages/History/ProductReview.jsx";
// import HistoryCurrentWeek from "../pages/History/CurrentWeek";
// import HistoryCurrentMonth from "../pages/History/CurrentMonth";
import ItemList from "../pages/Menu/ItemList";
import Category from "../pages/Menu/Category";
import ImportExportData from "../pages/Menu/ImportExportData";
import ModifierCategory from "../pages/Menu/ModifierCategory";
import CancellationPolicy from "../pages/Settings/CancellationPolicy";
import BasicSettings from "../pages/Settings/BasicSettings";
import AllOrder from "../pages/Order/AllOrder";
import PendingOrder from "../pages/Order/PendingOrder";
import AcceptedOrder from "../pages/Order/AcceptedOrder";
import DeliveredOrder from "../pages/Order/DeliveredOrder";
import ReturnOrder from "../pages/Order/ReturnOrder";
import CancelOrder from "../pages/Order/CancelOrder";
import ReplaceOrder from "../pages/Order/ReplaceOrder";
import Sale from "../pages/Offers/Sale";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "../custom/ErrorPage";
import VerifyOtp from "../pages/auth/VerifyOtp";
import ChangePassword from "../pages/auth/ChangePassword";
import PromoManagement from "../pages/PromoManagement/PromoManagement";
import ProductDetails from "../pages/ProductDetails/ProductDetails.jsx";
import ItemFoodList from "../pages/Menu/ItemFoodList.jsx";
import ApprovalCheck from "../pages/auth/ApprovalCheck.jsx";
import AUCategory from "../pages/ApproveUnapprove/Category/AUCategory.jsx";
import { useSelector } from "react-redux";
import AUSubcategory from "../pages/ApproveUnapprove/Subcategory/AUSubcategory.jsx";
import AUItem from "../pages/ApproveUnapprove/Item/AUItem.jsx";
import AUPromocode from "../pages/ApproveUnapprove/AUPromocode/AUPromocode.jsx";
import AUAdvertisement from "../pages/ApproveUnapprove/Advertisement/AUAdvertisement.jsx";
import AURejected from "../pages/ApproveUnapprove/Rejected/AURejected.jsx";
import AUPromosale from "../pages/ApproveUnapprove/AUPromocode/AUPromosale.jsx";
import AUSubCategoryRejected from "../pages/ApproveUnapprove/Rejected/AUSubCategoryRejected.jsx";
import AUItemRejected from "../pages/ApproveUnapprove/Rejected/AUItemRejected.jsx";
import AUPromocodeRejected from "../pages/ApproveUnapprove/Rejected/AUPromoCodeRejected.jsx";
import AUSaleRejected from "../pages/ApproveUnapprove/Rejected/AUSaleRejected.jsx";
import AUAdvertiseRejected from "../pages/ApproveUnapprove/Rejected/AUAdvertiseRejected.jsx";
import Dispatch from "../pages/Order/Dispatch/Dispatch.jsx";

const userData = localStorage.getItem("persist:user");
let deliveryBusiness;

if (userData) {
  const user = userData && JSON.parse(userData);
  // console.log('----->',JSON.parse(user.store));
  const currentUser = JSON.parse(user.store);
  console.log("----->", currentUser?.currentUser?.delivery_business);
  deliveryBusiness = currentUser?.currentUser?.delivery_business;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthProtected>
        <ErrorBoundary fallback={<ErrorPage />}>
          <Auth />
        </ErrorBoundary>
      </AuthProtected>
    ),
    children: [
      {
        path: "/",
        element: <Login />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "verify-otp",
        element: <VerifyOtp />,
      },
      {
        path: "change-password",
        element: <ChangePassword />,
      },
    ],
  },
  {
    path: "store",
    element: (
      <ProtectedRoute>
        <ErrorBoundary fallback={<ErrorPage />}>
          <Layout />
        </ErrorBoundary>
      </ProtectedRoute>
    ),
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "order/create-order",
        element: <CreateOrder />,
      },
      {
        path: "order/all-orders",
        element: <AllOrder />,
      },
      {
        path: "order/pending-orders",
        element: <PendingOrder />,
      },
      {
        path: "order/accepted-orders",
        element: <AcceptedOrder />,
      },
      {
        path: "order/delivered-orders",
        element: <DeliveredOrder />,
      },
      {
        path: "order/return-order",
        element: <ReturnOrder />,
      },
      {
        path: "order/cancel-order",
        element: <CancelOrder />,
      },
      {
        path: "order/replacement",
        element: <ReplaceOrder />,
      },
      {
        path: "order/dispatch",
        element: <Dispatch/>,
      },
      {
        path: "order/store-reviews",
        element: <StoreReviews />,
      },
      {
        path: "order/product-reviews",
        element: <ProductReview />,
      },
      {
        path: "product-details/:productId",
        element: <ProductDetails />,
      },

      // {
      //   path: "order/table-booking",
      //   element: <TableBooking />,
      // },

      // {
      //   path: "history/current-week",
      //   element: <HistoryCurrentWeek />,
      // },
      // {
      //   path: "history/current-month",
      //   element: <HistoryCurrentMonth />,
      // },
      // {
      //   path: "history/history-list",
      //   element: <History />,
      // },

      {
        path: "earning/earnings",
        element: <Earning />,
      },
      {
        path: "earning/current-week",
        element: <CurrentWeek />,
      },
      {
        path: "earning/current-month",
        element: <CurrentMonth />,
      },
      deliveryBusiness?.name === "Ecommerce"
        ? {
            path: "menu/item-list",
            element: <ItemList />,
          }
        : {
            path: "menu/item-food-list",
            element: <ItemFoodList />,
          },
      {
        path: "menu/category",
        element: <Category />,
      },
        deliveryBusiness?.name === "Ecommerce"?{}
        :{
        path: "menu/modifier-group",
        element: <ModifierCategory />,
      },
      {
        path: "menu/import-data",
        element: <ImportExportData />,
      },
      {
        path: "users/sub-stores",
        element: <Substore />,
      },
      {
        path: "offers/promocode",
        element: <Offers />,
      },
      {
        path: "offers/sale",
        element: <Sale />,
      },
      {
        path: "Promotion/advertisement-management",
        element: <PromoManagement />,
      },

      // approval/reject routes start
      {
        path: "approval-reject/category",
        element: <AUCategory />,
      },
      {
        path: "approval-reject/sub-category",
        element: <AUSubcategory />,
      },
      {
        path: "approval-reject/item",
        element: <AUItem />,
      },
      {
        path: "approval-reject/promo-code",
        element: <AUPromocode />,
      },
      {
        path: "approval-reject/sale",
        element: <AUPromosale />,
      },
      {
        path: "approval-reject/advertise",
        element: <AUAdvertisement />,
      },
      {
        path: "approval-reject/category-reject",
        element: <AURejected />,
      },
      {
        path: "approval-reject/subcategory-reject",
        element: <AUSubCategoryRejected />,
      },
      {
        path: "approval-reject/item-reject",
        element: <AUItemRejected />,
      },
      {
        path: "approval-reject/promo-code-reject",
        element: <AUPromocodeRejected />,
      },
      {
        path: "approval-reject/sale-reject",
        element: <AUSaleRejected />,
      },
      {
        path: "approval-reject/advertise-reject",
        element: <AUAdvertiseRejected />,
      },
      // approval/reject routes end

      {
        path: "settings/store-settings",
        element: <Storesetting />,
      },
      {
        path: "settings/delivery-settings",
        element: <Deliverysettings />,
      },
      {
        path: "settings/store-time-settings",
        element: <Storetimesettings />,
      },
      {
        path: "settings/table-booking-basic-settings",
        element: <BasicSettings />,
      },
      {
        path: "settings/table-booking-cancellation-policy",
        element: <CancellationPolicy />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
    ],
  },
  {
    path: "store/approval-check",
    element: <ApprovalCheck />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export default router;
