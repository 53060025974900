import React, { useContext, useEffect, useRef, useState } from "react";
import AdvertisementCard from "./AdvertisementCard";
import { ErrorMessage, Form, Formik } from "formik";
import SidebarField from "../../components/Sidebar/SidebarField";
import style from "./AdvertisementCard.module.css";
import { LuEye } from "react-icons/lu";
import { PiShareFat, PiInfoLight } from "react-icons/pi";
import { MdDownloadDone, MdOutlineShoppingCart, MdEdit } from "react-icons/md";
import { Chart, Line, line } from "react-chartjs-2";
import Tooltip from "../../components/Tooltip/Tooltip";
import BreadCrumb from "../../components/BreadCrumb";
import Button from "../../components/Button";
import ImagePreview from "../../components/ImagePreview";
import promoimg from "../../assest/offer_promo.png";
import Lable from "../../components/Lable";
import TextInput from "../../components/TextInput";
import Dropdown, { SelectMultipleFields } from "../../components/Dropdown";
import LoadingContext from "../../components/loader/index";
import {
  CategoryApi,
  productApi,
  SubCategoryApi,
} from "../../apis/ProductListing.apis";
import { toast } from "react-toastify";
import CalendarComp from "../../components/CalendarComp/CalendarComp";
import moment from "moment/moment";
import { AdvertisementApi } from "../../apis/Advertisement.apis";
import DeleteFieldModal from "../../components/DeleteFieldModal";

function PromoManagement() {
  const [adData,setAdData]= useState([]);
 
  const [audienceType, setAudienceType] = useState("");
  const { handleLoading } = useContext(LoadingContext);
  const [promocodeData, setPromocodeData] = useState([]);
  const chartRef = useRef(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isAdvertisementModel, setIsAdvertisementModel] = useState(false);
  const [singleAdd, setSingleAdd] = useState({
  _id:1,
  image: promoimg,
  advertisement_for:"product",
  product:"",
  duration:0,
  audience_type:"",
  target_audience_age:0,
  location_radius:0,
  gender:"",
  advertisement_budget:0,
  advertisement_tax:0,
  advertise_views:0,
  ordered_by_advertise:0,
  addToCart_count:0,
  share:0,
  });

  const [adPagination,setAdPagination]=useState({
    "page": 1,
    "limit": 5,
    "sortOrder": "desc",
    "filter": {}
  })
  const handlePagination=(data)=>{
    console.log(data);
    if (data.clearPagination) {
      setAdPagination(data.clearPagination)
      return
    }
    setAdPagination((prev)=>{
        let copyfilter={...prev.filter}
        // copyfilter[]
       
        if (data.filter) {
          // console.log("Object.keys[copyfilter]",Object.entries(copyfilter))
          // console.log("Object.keys[copyfilter]",Object.keys(data.filter))
          
          if (copyfilter[Object.keys(data.filter)[0]]) {
            copyfilter[Object.keys(data.filter)[0]]=Object.values(data.filter)[0]
            
          }else{

            copyfilter={...data?.filter,...copyfilter}
          }
        
        }

        
  
        return{
          page:data.page?data.page:prev.page,
          limit:data.limit?data.limit:prev.limit,
          sortOrder:data.sortOrder?data.sortOrder:prev.sortOrder,
          filter:copyfilter
        }
  
      })
    }

 

  const beforeMount = async () => {
    try {
      //get all advertisement details
      const resAddData = await AdvertisementApi.getAllAdvertisement(adPagination);
      console.log("all Advertisement", resAddData.data.data?.advertisements);
      setAdData(resAddData.data.data?.advertisements);
      handlePromoForOption()
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    handleLoading(true);
    beforeMount();
    handleLoading(false);
  }, []);

  const handleEditOpen = (value) => {
    setIsAdvertisementModel(true);
    setSingleAdd(value);
  };

  //delete advertisement
  const handleDeleteOffer = (value) => {
    console.log(value);
    setSingleAdd(value);
    setDeleteModal(true);
  };

  const handleDeleteClose = () => {
    setDeleteModal(false);
  };
  const handleDelete = async () => {
    handleLoading(true);

    try {
      let res;

      if (singleAdd._id) {
        res = await AdvertisementApi.deleteAdvertisement(singleAdd._id);
        toast.success(`${res.data.data}`);
        beforeMount();
      }

      console.log(res);
    } catch (err) {
      console.log(err);
      // toast.error(`${err.message}`)
    } finally {
      setAdData({});
      handleLoading(false);
      handleDeleteClose();
    }
  };

  const handleClose = () => {
    setIsAdvertisementModel(false);
  };
  const handleSubmit =async (values) => {
    console.log("Advertisement Submit:",values);
    setIsAdvertisementModel(false);
    try {
      const res =singleAdd?.product
        ? await AdvertisementApi.updateAdvertisement(values, singleAdd._id)
        : await AdvertisementApi.createAdvertisement(values);
      console.log("res", res);
      beforeMount();
      toast.success(
        singleAdd.product ? "Your Advertisement updated" : "New Advertisement Created"
      );
    } catch (error) {
       console.log(error);
       toast.error(error.message);
    }
    // handleClose();
    // handleLoading(false);
  };

  const data = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Views",
        data: [35000, 22000, 80000, 15000, 56000, 11000, 40000],
        fill: false,
        backgroundColor: "rgba(10,255,10,0.4)",
        borderColor: "rgba(10,255,10,1)",
      },
      {
        label: "Orders",
        data: [32000, 32000, 10000, 95000, 19000, 11800, 10000],
        fill: false,
        backgroundColor: "rgba(255, 0, 0, 0.4)",
        borderColor: "rgba(255, 0, 0,1)",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: true,
      },
      y: {
        display: true,
      },
    },
  };

  // const getSingleSelectValue = (value) => {
  //   console.log("value", value);

  //   if (value && value.length) {
  //     // values.promo_for==="Category"?"category":values.promo_for==="Sub Category"?"sub_category":"product"
  //     const selectedId = value?.map((item) => {
  //       if (item.id) {
  //         return item.id;
  //       } else {
  //         return item;
  //       }
  //     });
  //     return selectedId;
  //   } else {
  //     return null;
  //   }
  // };

  const fetchingPromoforOptions = async () => {
    console.log("2nd stage of product fetch is");
    
    handleLoading(true);

    try {
      // if (promoFor === "Category") {
      //   const resCategory = await CategoryApi.getCategory();
      //   return resCategory.data?.data;
      //   // setCategoryData(resCategory.data?.data);
      // }
      // if (promoFor === "Sub Category") {
      //   const resSubCategory = await SubCategoryApi.getSubCategory();
      //   return resSubCategory.data?.data;
      //   // setSubCategoryData(resSubCategory.data?.data)
      // }

      // if (promoFor === "product") {
        const resProduct = await productApi.getAllProducts();
        console.log("get all product result:",resProduct.data?.data);
        
        return resProduct.data?.data;
        // setPromocodeData(resProduct.data?.data)
      // }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      handleLoading(false);
    }
  };
  const [optionPromoFor, setOptionPromoFor] = useState([]);

  const handlePromoForOption = async () => {
    console.log("handlePromoForOption is Executing");
    
   
      const data = await fetchingPromoforOptions();
      console.log("Product data",data?.products);
      const option =
        data &&
        data?.products?.length &&
        data?.products?.map((d) => {
          d.label = d.name;
          d.value = d._id;
          return d;
        });
      console.log(option);

      setOptionPromoFor(option ? option : []);

  };

  const [error, setError] = useState("");

  const handleFileUpload = (e,setFieldValue) => {
    const file = e.target.files[0];

    if (file) {
      // Check if file size exceeds 1MB
      const fileSizeInMB = file.size / 1024 / 1024;
      if (fileSizeInMB > 1) {
        setError("Please select an image smaller than 1MB.");
        setFieldValue("image", null);
        return;
      }

      // Clear error and set the file if size is valid
      setError("");
      setFieldValue("image", file);
    }
  };


  return (
    <>
      <div className="flex justify-between items-center flex-wrap m-4 border-b">
        <BreadCrumb title="Advertisement" />

        <Button
          text="Add New"
          type="button"
          className="float-end"
          onClick={() => handleEditOpen(null)}
        />
      </div>
      <div className="flex flex-wrap p-4">
        {adData.length ?
          adData?.map((data, i) => (
            <AdvertisementCard
              adData={data}
              key={i}
              handleEditOpen={handleEditOpen}
              handleClose={handleClose}
              handleDelete={handleDeleteOffer}
            />
          )):"No advertise is Created yet"}

        {isAdvertisementModel && (
          <Formik
            initialValues={{
              image:
                singleAdd?.image ||"",
              // title: singleAdd?.title || "",
              advertisement_for: singleAdd?.advertisement_for || "product",
              product: singleAdd?.product || null,
              duration: singleAdd?.duration || 0,
              audience_type: singleAdd?.audience_type || "",
              target_audience_age: singleAdd?.target_audience_age || 0,
              location_radius: singleAdd?.location_radius || 0,
              gender: singleAdd?.gender || "All",
              // audience_count: singleAdd?.audience_count || 9,
              advertisement_budget: singleAdd?.advertisement_budget || 0,
              advertisement_tax: singleAdd?.advertisement_tax || 50,
              advertise_views: singleAdd?.advertise_views || 0,
              ordered_by_advertise: singleAdd?.ordered_by_advertise || 0,
              addToCart_count: singleAdd?.addToCart_count || 0,
              share: singleAdd?.share || 0,
            }}
            onSubmit={handleSubmit}
          >
            {({ handleChange, setFieldValue, values, handleSubmit }) => (
              <Form>
                <SidebarField
                  handleClose={handleClose}
                  title_style="text-red-500 text-2xl"
                  title={
                    singleAdd?.product
                      ? "Update Advertisement"
                      : "Create Advertisement"
                  }
                  button1={
                    <Button text="Cancel" type="button" onClick={handleClose} />
                  }
                  button2={
                    <Button text={singleAdd?.product?"Update":"submit"} type="submit" onClick={handleSubmit} />
                  }
                >
                  <div className="p-2 text-lg">
                    <div className="relative h-fit w-[95%] shadow-xl rounded-xl m-auto">
                      <label
                        htmlFor="image-upload"
                        className="absolute right-1 top-1 p-2 bg-buttonColor rounded-full cursor-pointer text-secondaryColor"
                      >
                       <input
                        type="file"
                        id="image-upload"
                        accept="image/*"
                        onChange={(e) =>handleFileUpload(e,setFieldValue)}
                        // value={values.image}
                        name="image"
                        style={{ display: "none" }}
                      />
                        <MdEdit />
                      </label>
                      {values.image ? (
                        <img src={values.image} alt="Promo"  style={{borderRadius: "1rem",objectFit: "cover"}}/>
                      ) : (
                        <img src={promoimg} alt="Promo Not Found" style={{borderRadius: "1rem",objectFit: "cover"}} />
                      )}
                    </div>
                    {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
                    <div className="">
                      {/* <div className="my-2">
                        <div className="flex ">
                          <Lable lable="Advertisement Name" />
                        </div>
                        <TextInput
                          name="title"
                          type="text"
                          placeholder="Advertisement Name"
                          onChange={handleChange}
                          value={values.title}
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="error"
                        />
                      </div> */}
                      {/* <div className="my-2">
                        <div>
                          <div className="flex">
                            <Lable lable="Advertisement for" />
                            <Tooltip text="select area of Advertisement" />
                          </div>
                          <Dropdown
                            options={[
                              // { label: "Store", value: "Store" },
                              { label: "Product", value: "product" },
                            ]}
                            name="advertisement_for"
                            value={values.advertisement_for}
                            handleOnchange={(value) => {
                              // console.log(value);
                              // setFieldValue("category", null);
                              // setFieldValue("sub_category", null);
                              setFieldValue("product", null);
                              setFieldValue("advertisement_for", value.value);
                              handlePromoForOption(value.value);
                              // handleChange
                            }}
                          />
                          <ErrorMessage
                            name="advertisement_for"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>  */}
                      {/* {values.product && <div className="my-2 border">
                      <Lable lable={"Selected Product"}/>
                     <div className="flex">
                     <img src={singleProduct?.primary_image} style={{width: "10rem",objectFit: "cover"}} alt="" />
                      <div>
                      <h2>{singleProduct?.name}</h2>
                      <h2>Price: {singleProduct?.cost_price?.$numberDecimal}</h2>
                      </div>
                     </div>
                      </div>} */}
                      {values.advertisement_for &&
                        values.advertisement_for == "product" && (
                          <div className="my-2">
                            <Lable lable={`${values.advertisement_for}`} />
                            <Dropdown
                            value={values.product}
                              options={
                                optionPromoFor.length ? optionPromoFor : []
                              }
                              name={
                                values.advertisement_for === "Category"
                                  ? "category"
                                  : values.advertisement_for === "Sub Category"
                                  ? "sub_category"
                                  : "product"
                              }
                              handleOnchange={(v) => {
                                console.log("value", v);
                                setFieldValue(`${values.advertisement_for}`, v._id);
                                // setFieldValue("image", v.primary_image);
                              }}
                              className={["w-full"]}
                              sx={{ width: "100%" }}
                            />
                            <ErrorMessage
                              name="advertisement_for"
                              component="div"
                              className="error"
                            />
                          </div>
                        )}
                      {/* <div>
                        <div className="flex mt-4">
                          <Lable lable="Product Name" />
                        </div>
                        <TextInput
                          name="product"
                          type="text"
                          placeholder="Product Name"
                          onChange={handleChange}
                          value={values.product}
                        />
                        <ErrorMessage
                          name="product"
                          component="div"
                          className="error"
                        />
                      </div> */}
                      <div className="my-2">
                        <div className="flex">
                          <Lable lable="Select Advertisement Duration" />
                          {/* <Tooltip text="set the date range for your offer" /> */}
                        </div>
                        {/* <TextInput
                      name="dateRange"
                      type="text"
                      placeholder="Date Range"
                      onChange={handleChange}
                      value={values.dateRange}
                    /> */}
                        <CalendarComp
                          // numberOfMonths={2}
                          className="w-full  border-[#e5e7eb]"
                          showTime
                          hourFormat="12"
                          sx={{
                            width: "100%",
                            // borderRadius: "5px",
                            height: "2.5rem",
                          }}
                          handleFilter={(type, v) => {
                            console.log("Type", type);
                            console.log("value", v);
                            const formattedDates = v.map((date) =>
                              moment(date)
                                .utc()
                                .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                            );
                            console.log(formattedDates);
                            setFieldValue("start_date", formattedDates[0]);
                            setFieldValue("end_date", formattedDates[1]);
                          }}
                        />
                        <ErrorMessage
                          name="dateRange"
                          component="div"
                          className="error"
                        />
                      </div>
                      <div className="flex justify-between gap-2 my-2">
                        <div className="w-[100%]">
                          <div className="flex">
                            <Lable lable="Select Audience Type" />
                          </div>
                          <Dropdown
                            options={[
                              { label: "Suggested", value: "Suggested" },
                              { label: "Own Audience", value: "Own Audience" },
                            ]}
                            name="audience_type"
                            value={values.audience_type}
                            onChange={setAudienceType(values.audience_type)}
                          />
                          <ErrorMessage
                            name="discount_type"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                      {audienceType == "Own Audience" && (
                        <div className="border p-1 mb-2">
                          <Lable lable="Create Own Audience" />
                          <div className="flex justify-between items-center pb-1 ">
                            {/* Age */}
                            <div className="flex flex-col mx-2">
                              <Lable lable="Age" />
                              <TextInput
                                name="target_audience_age"
                                type="number"
                                placeholder="Age"
                                onChange={handleChange}
                                value={values.target_audience_age}
                                className={"w-5"}
                              />
                              <ErrorMessage
                                name="title"
                                component="div"
                                className="error"
                              />
                            </div>
                            {/* location Radius */}
                            <div className="flex flex-col items-center mx-2 ">
                              <Lable lable="Local Radius" />

                              <TextInput
                                name="location_radius"
                                type="number"
                                placeholder="radius"
                                onChange={handleChange}
                                value={values.location_radius}
                                className={"w-5"}
                              />
                              <ErrorMessage
                                name="title"
                                component="div"
                                className="error"
                              />
                            </div>
                            {/* Gender */}
                            <div className="flex flex-col items-end mx-2 w-5">
                              <Lable lable="Gender" />

                              <Dropdown
                                options={[
                                  { label: "All", value: "All" },
                                  { label: "Male", value: "Male" },
                                  { label: "Female", value: "Female" },
                                  { label: "Others", value: "Others" },
                                ]}
                                name="gender"
                                value={values.gender}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                name="gender"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div>
                        <Lable lable="Daily Budget"/>
                        <p>${values.advertisement_budget}</p>
                        <input 
                        name="advertisement_budget"
                        type="range"
                        min="0"
                        max="100000"
                        value={values.advertisement_budget}
                        onChange={handleChange}
                        className="w-full"
                         />
                      </div>
                        {/* <Lable lable="Advertisement Duration" />
                      <div className="flex mx-2">

                        <TextInput
                          name="duration"
                          type="number"
                          placeholder="duration"
                          onChange={handleChange}
                          value={values.duration}
                          className={"w-[80%]"}
                        />
                        <TextInput
                          name="Days"
                          type="text"
                          placeholder="Days"
                          // onChange={handleChange}
                          disabled
                          value={"Days"}
                          className={"w-[20%] content-center"}
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="error"
                        />
                      </div> */}
                     {values?.advertisement_budget && <p className="flex flex-col items-end justify-end text-lg my-2">
                        <span>Tax: ${values?.advertisement_tax}</span>
                        <span>Total Cost: ${values?.advertisement_budget + values?.advertisement_tax}</span>
                      </p>}

                      {singleAdd?._id ? 
                        <div className="flex justify-between px-4 py-2">
                        <span className="flex flex-col items-center justify-center">
                          <LuEye fontSize={"1.5rem"} />
                          <span>{singleAdd?.advertise_views}</span>
                        </span>
                        <span className="flex flex-col items-center justify-center">
                          <MdDownloadDone fontSize={"1.5rem"} />
                          <span>{singleAdd?.ordered_by_advertise}</span>
                        </span>
                        <span className="flex flex-col items-center justify-center">
                          <MdOutlineShoppingCart fontSize={"1.5rem"} />
                          <span>{singleAdd?.addToCart_count}</span>
                        </span>
                        <span className="flex flex-col items-center justify-center">
                          <PiShareFat fontSize={"1.5rem"} />
                          <span>{singleAdd?.share}</span>
                        </span>
                      </div>:null
                      }
                      {/* More icons here */}
                    </div>
                   { singleAdd?.product ?
                    <>
                    <hr className="py-1" />
                    <div style={{ height: "10rem" }}>
                      <Line
                        ref={chartRef}
                        data={data}
                        options={options}
                        className="p-2"
                      />
                    </div>
                    </>: null}
                  </div>
                </SidebarField>
              </Form>
            )}
          </Formik>
        )}
        <DeleteFieldModal
        isOpen={deleteModal}
        onClose={handleDeleteClose}
        onDelete={() => handleDelete()}
      />
      </div>
    </>
  );
}

export default PromoManagement;
