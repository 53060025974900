import Api from "../utils/axios";

const MenuApi = {
  getAllCountry: () => Api.get(`master/get-all-country`),
  getAllCity: (id) => Api.get(`master/get-all-city/${id}`),

  // New method to get city coordinates based on city name
  getCityCoordinates: (cityName) => {
    const API_KEY = "e2d2bf279ed24d45badca10c6ec96ddb"; // Replace with your OpenCage API Key
    const BASE_URL = `https://api.opencagedata.com/geocode/v1/json`;
    return Api.get(BASE_URL, {
      params: {
        q: cityName,
        key: API_KEY,
        limit: 1, // Optional: limits results to the most relevant one
      },
    });
  },
  // Update country method
  updateCountry: (countryId, payload) =>
    Api.put(`master/update-country/${countryId}`, payload),

  // Update city method
  updateCity: (cityId, payload) =>
    Api.put(`master/update-city/${cityId}`, payload),

  getAllBusinesgetsType: () => Api.get(`master/get-all-business-type`),
  getVenderCategory: (filter, search) => {
    console.log("search", search);
    return Api.post(`product/get-all-category/?search=${search}`, filter);
  },
  // getAllSubCategory: (payload,search) => Api.post(`product/get-all-subcategory?search=${search}`,payload),

  item: {
    getAllItemProduct: (payload, search = "") =>
      Api.post(`product/get-all-products?search=${search}`, payload),
    getProductById: (id) => Api.get(`product/get-product-by-id/${id}`),
  },
  modifier: {
    getAllModifierCategory: (fitler) =>
      Api.post(`admin/get-all-modifier-category`, fitler),
  },
};

export default MenuApi;

export const CategoryApi = {
  getCategory: (payload, search = "") =>
    Api.post(`product/get-all-category?search=${search}`, payload),
  getApproveCategory: () => Api.get(`product/all-approved-category`),
  getSingleCategory: (id) => Api.get(`product/get-all-category/${id}`),
  createCategory: (payload) =>
    Api.post(`product/create-category`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  updateCategory: (payload, id) =>
    Api.put(`product/update-category/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  deleteCategory: (id) => Api.delete(`product/delete-category/${id}`),
};

export const SubCategoryApi = {
  getSubCategory: (payload, search = "") =>
    Api.post(`product/get-all-subcategory?search=${search}`, payload),
  getSubSingleCategory: (id) => Api.get(`product/get-subcategory-by-id/${id}`),
  createSubCategory: (payload) =>
    Api.post("product/create-subcategory", payload),
  updateSubCategory: (payload, id) =>
    Api.put(`product/update-subcategory/${id}`, payload),
  deleteSubCategory: (id) => Api.delete(`product/delete-subcategory/${id}`),
};
